import { idtype, linkTo } from "querypieces";

export default `
  ...on pageBuilder_videoWaved_BlockType {
    ${idtype}
    heading
    plainText
    youtubeLink
    ${linkTo}
  }
`;
