import React from "react";
import "./not-found.scss";

const NotFound = () => (
  <div className="Page">
    <div className="NotFound">
      <div className="NotFound-inner">404 Page not found</div>
    </div>
  </div>
);

export default NotFound;
