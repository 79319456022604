import React, { useEffect } from "react";
import Blocks from "../../blocks";
import "./data-privacy-center-page.scss";

export const DataPrivacyCenterPageBuilder = ({ entry }) => {
  const {clubdataprivacycenterpagebuilder, ...rest } = entry;

  return (
    <div className="DataPrivacyCenterPage">
      <Blocks blocks={clubdataprivacycenterpagebuilder} entry={rest} />
    </div>
  );
};
