import { idtype, linkTo, svgIcon } from "querypieces";

export default `
  ...on pageBuilder_pageAlert_BlockType {
    ${idtype}
    ${svgIcon}
    heading
    plainText
    ${linkTo}
  }
`;
