import React from "react";
import useNewsLanding from "./useNewsLanding";
import NewsList from "components/news-list";
import Loading from "components/loading";
import NewsStreamHeader from "components/news-stream-header";
import "./news-landing.scss";
import ArrowButton from "components/arrow-button";

const NewsLanding = ({ heading, label }) => {
  const { loading, data, loadMore, done } = useNewsLanding();

  return (
    <div className="NewsLanding">
      <NewsStreamHeader label={label} heading={heading} />
      {data && <NewsList items={data.items} />}
      <div className="NewsLanding-load">
        {loading ? (
          <Loading />
        ) : done ? (
          <div className="NewsLanding-load-message">No More</div>
        ) : (
          <ArrowButton onClick={() => loadMore()}>Load More</ArrowButton>
        )}
      </div>
    </div>
  );
};

export default NewsLanding;
