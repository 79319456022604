import {
  idtype,
  image,
  linkTo,
  buttonLink,
  programAttributeList,
  headingTextItems,
  svgIcon,
} from "querypieces";

export default `
  ...on pageBuilder_programApplication_BlockType {
    ${idtype}
    photoOrVideo {
      ...on photoOrVideo_photo_BlockType {
        ${idtype}
        ${image}
      }
      ...on photoOrVideo_video_BlockType {
        ${idtype}
        youtubeLink
      }
    }
    ${linkTo}
    ${buttonLink}
    children {
      ...on pageBuilder_programApplicationClub_BlockType {
        ${idtype}
        heading
        plainText
        subheading
        subtext
        ${programAttributeList}
        subscribeSource
        subscribeButtonText: label
      }
      ...on pageBuilder_programApplicationCollegeLoop_BlockType {
        ${idtype}
        heading
        plainText
        programQuestions: ${headingTextItems}
      }
      ...on pageBuilder_programApplicationSip_BlockType {
        ${idtype}
        heading
        plainText
        image: ${svgIcon}
        ${linkTo}
      }
    }
  }
`;
