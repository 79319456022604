import React from "react";
import Image from "components/image";
import ArrowLink from "components/arrow-link";
import WaveBackground from "images/wavebg.svg";
import WaveBackgroundMobile from "images/wavebg_mobile.svg";
import Attribution from "components/attribution";
import SVG from "react-inlinesvg";
import "./profile-cta-with-image.scss";

const ProfileCtaWithImage = ({ image, quote, attribution, linkTo }) => {
  return (
    <div className="ProfileCtaWithImage">
      <div className="ProfileCtaWithImage-inner">
        <div className="ProfileCtaWithImage-wave-desktop">
          <SVG src={WaveBackground} alt="" />
        </div>
        <div className="ProfileCtaWithImage-inner-inner">
          <div className="ProfileCtaWithImage-image">
            <div className="ProfileCtaWithImage-wave-mobile">
              <SVG src={WaveBackgroundMobile} alt="" />
            </div>
            <Image {...image[0]} />
          </div>
          <div className="ProfileCtaWithImage-text">
            <div className="ProfileCtaWithImage-text-bg" />
            <div className="ProfileCtaWithImage-heading">{quote}</div>
            <div className="ProfileCtaWithImage-attribution">
              <Attribution>{attribution}</Attribution>
            </div>
            {linkTo.url && (
              <ArrowLink className="ProfileCtaWithImage-link" linkTo={linkTo} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCtaWithImage;
