import { idtype } from "querypieces"

export default `
  ...on pageBuilder_pullquoteSimple_BlockType {
    ${idtype}
    heading
    statement
    attribution
    colorCombo {
      label
      class
    }
  }
`
