import React from "react";
import Image from "components/image";
import ArrowLink from "components/arrow-link";
import TextWave from "components/text-wave";
import colors from "colors";
import ThemeContext from "blocks/theme/theme-context";
import "./campaign-cta.scss";

const headingPH = "This is a placeholder";
const plainTextPH =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

const CampaignCta = ({ image, heading, plainText, linkTo }) => {
  const hasImage = image.length > 0;

  return (
    <div className={`CampaignCtaFeatured`}>
      <div className="CampaignCta-bg" />
      <div className="CampaignCta-image">
        {hasImage ? <Image {...image[0]} /> : "Missing Image"}
      </div>

      <div className="CampaignCta-heading">
        <TextWave text={heading || headingPH} />
      </div>
      <div className="CampaignCta-text">{plainText || plainTextPH}</div>
      <ThemeContext.Provider value={{ accent: colors.blue3 }}>
        <ArrowLink className="CampaignCta-link" linkTo={linkTo} />
      </ThemeContext.Provider>
    </div>
  );
};

export default CampaignCta;
