import React, { useState, useEffect } from "react";
import { useStorageState } from "react-storage-hooks";
import supercluster from "points-cluster";
import mapStyle from "mapStyle.json";
import { format } from "d3-format";
import GOOGLE_API_KEY from "./google-api-key";
import query from "./query";
import { useQuery } from "@apollo/react-hooks";

const PROGRAM_NAMES_TO_SHOW_IN_TOOLTIP = 1;

const earth = {
  defaultCenter: {
    lat: 35,
    lng: 0,
  },
  defaultZoom: 2,
};
// const brooklyn = {
//   defaultCenter: {
//     lat: 40.655,
//     lng: -74.008,
//   },
//   defaultZoom: 14,
// };

export const defaultMapProps = {
  bootstrapURLKeys: {
    key: GOOGLE_API_KEY, //hkt staging key
  },
  options: { minZoom: 0, styles: mapStyle },
  ...earth,
  //...brooklyn,
};

const getMarkerTypes = (data) =>
  data
    .reduce((r, d) => {
      d.programs.forEach(({ type }) => {
        if (r.indexOf(type) === -1) {
          r.push(type);
        }
      });
      return r;
    }, [])
    .sort();

const getMarkerFill = (points) => {
  return getMarkerTypes(points);
};

function areEqualShallow(a, b) {
  for (var key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}
function objectExistsInArray(array, object) {
  let exists = false;
  array.forEach((a) => {
    if (areEqualShallow(a, object)) {
      exists = true;
    }
  });
  return exists;
}
const groupProgramsByLocation = (data) => {
  const byCoord = data.reduce((r, d) => {
    if (!d.lat && !d.lng) {
      return r;
    }

    const key = `${d.lat} + ${d.lng}`;
    const {
      id,
      lat,
      lng,
      address,
      emailAddress,
      showEmailAddress,
      type,
      title,
      gradeLevel,
      needsFacilitator,
    } = d;

    const program = {
      type,
      title,
      emailAddress,
      showEmailAddress,
      gradeLevel,
      needsFacilitator,
    };

    if (r[key]) {
      if (!objectExistsInArray(r[key].programs, program)) {
        r[key].programs.push(program);
      }
    } else {
      r[key] = {
        id,
        lat,
        lng,
        address,
        emailAddress,
        showEmailAddress,
        programs: [program],
      };
    }
    return r;
  }, {});
  return Object.values(byCoord);
};

const determineProgramsInMarker = (points) => {
  return points.reduce((r, d) => {
    r += d.programs.length;
    return r;
  }, 0);
};

const formatSi = (value) => (value >= 10 ? format(".2s")(value) : value);

const useClubMap = (mapApi) => {
  const [mapProps, setMapProps] = useStorageState(
    typeof window === "undefined"
      ? {
          getItem: () => null,
          setItem: () => {},
          removeItem: () => {},
        }
      : localStorage,
    "club-map-mapprops",
    defaultMapProps
  );
  const [markers, setMarkers] = useState();
  const [markerTypes, setMarkerTypes] = useState([]);
  const [clust, setClust] = useState();
  const [currentMarkerType, setCurrentMarkerType] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeMarker, setActiveMarker] = useState();
  const [allMarkerData, setAllMarkerData] = useState();
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const toggleMarkerType = (type) => {
    if (!currentMarkerType.includes(type)) {
      setCurrentMarkerType([...currentMarkerType, type]);
    } else {
      setCurrentMarkerType(currentMarkerType.filter((d) => d !== type));
    }
  };
  const resetMarkerType = () => {
    setCurrentMarkerType([]);
  };
  const [fetchError, setFetchError] = useState(false);

  useEffect(() => {
    let mounted = true;
    fetch(`https://s3.amazonaws.com/go.girlswhocode.com/prod-data/clubs.json`)
      .then((res) => res.json())
      .then((res) => {
        if (mounted) {
          const selectedMarkerData = groupProgramsByLocation(res);
          setAllMarkerData(selectedMarkerData);
          setMarkerTypes(() => getMarkerTypes(selectedMarkerData));
        }
      })
      .catch((err) => {
        setFetchError(true);
      });
    return () => (mounted = false);
  }, []);

  useEffect(() => {
    if (allMarkerData) {
      const markerData =
        currentMarkerType.length === 0
          ? allMarkerData
          : allMarkerData.reduce((r, d) => {
              const filtered = {
                ...d,
                programs: d.programs.filter((d) =>
                  currentMarkerType.includes(d.type)
                ),
              };
              if (filtered.programs.length) {
                //any programs left?
                r.push(filtered);
              }
              return r;
            }, []);
      const newClust = supercluster(markerData, {
        minZoom: 0,
        maxZoom: 12,
        radius: 120,
      });
      setClust(() => newClust);
    }
  }, [allMarkerData, currentMarkerType]);

  useEffect(() => {
    if (mapProps.bounds && clust) {
      const clustProps = {
        center: mapProps.center,
        bounds: mapProps.bounds,
        zoom: mapProps.zoom,
      };
      setMarkers(
        clust(clustProps).map(({ wx, wy, points, zoom }) => {
          const uniquePoints = points.reduce((set, { lat, lng }) => {
            set.add(`${lat}_${lng}`);
            return set;
          }, new Set()).size;

          const totalPoints = determineProgramsInMarker(points);

          const allPrograms = points.reduce(
            (r, { programs }) => [...r, ...programs],
            []
          );
          const balance = allPrograms.length - PROGRAM_NAMES_TO_SHOW_IN_TOOLTIP;

          return {
            lat: wy,
            lng: wx,
            LatLng: new window.google.maps.LatLng(wy, wx),
            zoom,
            text: uniquePoints > 1 ? formatSi(totalPoints) : null,
            uniquePoints,
            isLocation: uniquePoints === 1,
            tooltip: (
              <div className="MarkerTooltip">
                {allPrograms
                  .slice(0, PROGRAM_NAMES_TO_SHOW_IN_TOOLTIP)
                  .map(({ title }, i) => (
                    <div key={`${i}${title}`} className="MarkerTooltip-title">
                      {title}
                    </div>
                  ))}
                {balance > 0 && <div>+ {balance} more</div>}
              </div>
            ),
            id: `${totalPoints}_${points[0].id}`,
            markerId: points[0].id,
            markerIds: points.map(({ id }) => id),
            address: points.length === 1 ? points[0].address : null,
            points,
            types: getMarkerFill(points),
            // showEmailAddress:
            //   points.length === 1 ? points[0].showEmailAddress : null,
            // emailAddress: points.length === 1 ? points[0].emailAddress : null,
          };
        })
      );
    }
  }, [clust, mapProps.bounds, mapProps.center, mapProps.zoom]);

  // const zoomWorld = () => {
  //   if (mapApi.current) {
  //     mapApi.current.setZoom(defaultMapProps.defaultZoom);
  //     mapApi.current.panTo(defaultMapProps.defaultCenter);
  //   }
  // };

  const [currentTab, setCurrentTab] = useState(0);
  const switchToLocations = () => {
    setCurrentTab(1);
  };
  const switchToLocales = () => {
    setCurrentTab(0);
  };

  useEffect(() => {
    if (activeMarker) {
      switchToLocations();
    }
  }, [activeMarker]);

  const { data: content, error: contentError } = useQuery(query);

  return {
    content: content ? content.globalSet.programMap[0] : undefined,
    error: fetchError || contentError,
    allMarkers: allMarkerData,
    activeMarker,
    setActiveMarker,
    mapProps,
    setMapProps,
    markers,
    markerTypes,
    currentMarkerType,
    toggleMarkerType,
    resetMarkerType,
    searchQuery,
    setSearchQuery,
    currentTab,
    setCurrentTab,
    switchToLocales,
    switchToLocations,
    hoveredMarker,
    setHoveredMarker,
  };
};

export default useClubMap;
