import React from "react";
import Image from "components/image";
import TextWave from "components/text-wave";
import DynamicWaveLine from "components/dynamic-wave-line";
import colors from "colors";
import useSubscribe from "components/subscribe/useSubscribe";
import SubscribeView from "components/subscribe/subscribe-view";
import ButtonLink from "components/button-link";
import useMeasure from "react-use-measure";
import nl2br from "react-nl2br";
import "./subscribe-download.scss";

const themes = {
  none: {
    backgroundColor: null,
    waveColor: colors.teal2,
    labelColor: colors.orange3,
    color: null,
  },
  lightOrange: {
    backgroundColor: colors.yellow1,
    waveColor: colors.orange2,
    labelColor: colors.orange3,
    color: null,
  },
  lightTeal: {
    backgroundColor: colors.teal1,
    waveColor: colors.teal2,
    labelColor: colors.orange3,
    color: null,
  },
  lightBlue: {
    backgroundColor: colors.blue1,
    waveColor: colors.teal2,
    labelColor: colors.orange3,
    color: null,
  },
  blue: {
    backgroundColor: colors.blue2,
    waveColor: colors.teal2,
    labelColor: colors.white,
    color: colors.white,
    blockLabelBg: colors.white,
    blockLabelColor: colors.blue4,
  },
};

const SubscribeDownload = ({
  label,
  heading,
  plainText,
  image,
  subscribeSource,
  subscribeTargetAudience,
  subscribeTheme,
  download,
  subscribeSuccessHeading,
  subscribeSuccessText,
  ctaText,
}) => {
  const theme = themes[subscribeTheme || "none"];

  const hasImage = image.length > 0;
  const hasDownload = !!download.url;
  const showStudentLabels = subscribeTargetAudience === "student";
  const adultLabel = label && (
    <div className="SubscribeDownload-label">
      <div
        className="SubscribeDownload-label-inner"
        style={{
          backgroundColor: theme.blockLabelBg,
          color: theme.blockLabelColor,
        }}
      >
        {label}
      </div>
    </div>
  );
  const buttonText = ctaText
    ? ctaText
    : hasDownload
    ? "downloadNowText"
    : "signUpText";
  const { state, states, ...subscribeProps } = useSubscribe({
    source: subscribeSource,
    initialAgeGroup: subscribeTargetAudience,
  });
  const success = state === states.SUCCESS;
  const [ref, bounds] = useMeasure();

  return (
    <div
      className={`SubscribeDownload 
        SubscribeDownload--${image[0] ? "withimage" : "noimage"} 
        SubscribeDownload--${subscribeTargetAudience} 
        SubscribeDownload--${hasDownload ? "hasdownload" : "nodownload"} 
        SubscribeDownload--${label ? "haslabel" : "nolabel"}
      SubscribeDownload--${states[state]}`}
      style={{
        backgroundColor: theme.backgroundColor,
        color: theme.color,
      }}
      ref={ref}
    >
      <div className="SubscribeDownload-inner">
        <div className="SubscribeDownload-heading">
          <TextWave
            text={
              success && subscribeSuccessHeading
                ? subscribeSuccessHeading
                : heading
            }
          />
        </div>
        <div className="SubscribeDownload-text">
          {success && subscribeSuccessText
            ? nl2br(subscribeSuccessText)
            : nl2br(plainText)}
        </div>
        {hasImage ? (
          <div className="SubscribeDownload-image">
            <Image {...image[0]} />
            {!showStudentLabels && adultLabel}
          </div>
        ) : (
          !showStudentLabels && adultLabel
        )}
        {success && download.url ? (
          <ButtonLink
            className="SubscribeDownload-download"
            linkTo={download}
          />
        ) : null}
        <div className="SubscribeDownload-form">
          <SubscribeView
            state={state}
            states={states}
            {...subscribeProps}
            buttonText={buttonText}
          />
        </div>
      </div>
      {showStudentLabels && (
        <LabelFrame
          label={label}
          height={bounds.height}
          labelColor={theme.labelColor}
          waveColor={theme.waveColor}
        />
      )}
    </div>
  );
};

export default SubscribeDownload;

const LabelFrame = ({ label, height, labelColor, waveColor }) => {
  return (
    <div className="LabelFrame">
      {["top", "bottom"].map((pos) => (
        <Label
          key={pos}
          pos={pos}
          text={label}
          labelColor={labelColor}
          waveColor={waveColor}
        />
      ))}
      {["left", "right"].map((pos) => (
        <Label
          key={pos}
          pos={pos}
          text={label}
          labelColor={labelColor}
          waveColor={waveColor}
          vertical={true}
          style={{ height: height - 48 }}
        />
      ))}
    </div>
  );
};

const Label = ({
  pos,
  text,
  labelColor,
  waveColor,
  style,
  vertical = false,
}) => {
  if (text) {
    return (
      <div className={`SubscribeDownload-label ${pos}`} style={style}>
        <DynamicWaveLine stroke={waveColor} vertical={vertical} />
        {text && (
          <div
            className="SubscribeDownload-label-text"
            style={{ color: labelColor }}
          >
            {text}
          </div>
        )}
        <DynamicWaveLine stroke={waveColor} vertical={vertical} />
      </div>
    );
  }
  return (
    <div className={`SubscribeDownload-label ${pos}`} style={style}>
      <DynamicWaveLine stroke={waveColor} vertical={vertical} />
    </div>
  );
};
