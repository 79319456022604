import React from "react";
import "./multiple-links.scss";
import ArrowLink from "components/arrow-link";
import ButtonLink from "components/button-link";

const MultipleLinks = ({ buttonLink, secondaryLinks }) => {
  return (
    <div className="MultipleLinks">
      <ButtonLink linkTo={buttonLink} className="MultipleLinks-primary" />
      {secondaryLinks.length > 0 && (
        <ul className="MultipleLinks-secondary">
          {secondaryLinks.map(({ id, linkTo }) => (
            <ArrowLink key={id} linkTo={linkTo} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultipleLinks;
