import { idtype, linkTo } from "querypieces";
import { impactItems } from "blocks/impact-items/query";
export default `
  ...on pageBuilder_impact_BlockType {
    ${idtype}
    heading
    plainText
    ${impactItems}
    ${linkTo}
  }
`;
