import React from "react";
import "./checkbox.scss";

const Checkbox = ({ label, value, checked, onChange, fill }) => {
  return (
    <label className="Checkbox">
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        style={{
          borderColor: fill,
          backgroundColor: checked ? fill : null
        }}
      />
      {label}
    </label>
  );
};

export default Checkbox;
