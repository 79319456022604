import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./copy-button.scss";

const CopyButton = ({ value }) => {
  const [copied, setCopied] = useState(false);

  return (
    <CopyToClipboard
      text={value}
      onCopy={() => {
        setCopied(true);
      }}
    >
      <button className="CopyButton">{copied ? "copied" : "copy"}</button>
    </CopyToClipboard>
  );
};

export default CopyButton;
