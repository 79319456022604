import { gql } from "apollo-boost";

export default gql`
query {
  globalSet (handle: "subscribeForm") {
    ...on subscribeForm_GlobalSet {
      subscribeForm {
        ...on subscribeForm_BlockType {
          beforeSelectorText
          adultText
          studentText
          fieldText
          disclaimerText
          loadingText
          successText
          signUpNowText
          downloadNowText
          signUpText
        }
      }
    }
  }
}
`;
