import { idtype } from "querypieces";

export default `
  ... on pageBuilder_imageWaveText_BlockType {
    ${idtype}
    waveHeaderImages {
      ...on waveHeaderImages_BlockType {
        mainImage {
          ...on images_Asset {
            optimized: optimized54 {
              placeholder: placeholderImage
              srcset
              srcsetWebp
            }
          }
        }
        image2 {
          ...on images_Asset {
            optimized: optimized32 {
              placeholder: placeholderImage
              srcset
              srcsetWebp
            }
          }
        }
        image3 {
          ...on images_Asset {
            optimized: optimized56 {
              placeholder: placeholderImage
              srcset
              srcsetWebp
            }
          }
        }
      }
    }
    plainText
  }
`;
