import React, { useEffect } from "react";
import "./helpfulResourcesContentBlock.scss";
import Grid from "../../components/grid";
import ArrowWave from "../../components/arrow-wave";
import colors from "../../colors";
import Link from "../../components/link";
import {a} from "react-spring";

const helpfulResourcesContentBlock = (block) => {
  return (
    <div className={"helpfulResourcesContentBlock"} id={block.anchorId} name={block.anchorId}>
      <div className={"helpfulResourcesContentBlockHeader"}>
        <h2>{block.heading}</h2>
      </div>
      <div className={"helpfulResourcesContentBlockList"}>
        <Grid itemClass="third">
          {block.children.map((item) => {
            return <HelpfulResourcesContentBlockItem key={item.id} block={item} />
          })}
        </Grid>
      </div>
    </div>
  )
}

const HelpfulResourcesContentBlockItem = ({block}) => {
  return (
    <div className="HelpfulResourcesContentBlockItem">
      <Link {...block.linkTo} >
        <div className="HelpfulResourcesContentBlockItemLink">
          <span> {block.linkTo.text} </span>
          <ArrowWave fill={colors.teal3} />
        </div>
      </Link>
    </div>
  );
}

export default helpfulResourcesContentBlock
