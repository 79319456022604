import React from "react"
import Image from "blocks/image"
import Link from "components/link"
import "./annual-reports.scss"

const AnnualReport = ({year, image}) => {
  return <Link
      url={`https://girlswhocode.com/${year}report`}
      className="AnnualReport"
    >
    <Image image={image} />
    <div className="AnnualReport-year">{year}</div>
    <div className="AnnualReport-wave" />
  </Link>
}

export default AnnualReport
