import React from "react";

const RadioSet = ({
  className = "",
  name,
  options,
  legend,
  value,
  setValue,
  required,
  color,
}) => {
  return (
    <fieldset className={`RadioSet ${className}`}>
      <div className="RadioSet-inner">
        <legend>{legend}</legend>
        <div>
          {options.map((option) => (
            <Radio
              key={option.value}
              name={name}
              label={option.label}
              required={required}
              checked={value === option.value}
              onChange={() => setValue(option.value)}
              color={color}
            />
          ))}
        </div>
      </div>
    </fieldset>
  );
};

export default RadioSet;

const Radio = ({ name, label, checked, onChange, required, color }) => {
  return (
    <label className="Radio">
      <input
        type="radio"
        name={name}
        checked={checked}
        onChange={onChange}
        required={required}
      />
      <div
        className="Radio-indicator"
        style={{
          borderColor: color,
          backgroundColor: checked ? color : "transparent",
        }}
      ></div>
      <div className="Radio-label">{label}</div>
    </label>
  );
};
