import React from "react";
import Blocks from "blocks";
import MoreNews from "components/more-news";
import "./news-article.scss";

const NewsArticle = ({ entry: { title, articleBuilder } }) => {
  return (
    <div className="Page">
      <div className="space-for-nav">
        <header className="NewsArticle-header">
          <h1 className="NewsArticle-header-heading">{title}</h1>
        </header>
        <div className="NewsArticle-body">
          <Blocks blocks={articleBuilder} />
        </div>
        <div className="NewsArticle-more">
          <MoreNews />
        </div>
      </div>
    </div>
  );
};

export default NewsArticle;
