import React, {useState} from "react";
import Grid from "../../components/grid";
import Image from "../image/image";
import "./logoBadgeContentBlock.scss";

const logoBadgeContentBlock = (block) => {
  return (
    <div className="logoBadgeContentBlock">
      <Grid gridClass={"Grid centered"} itemClass="">
        {block.images.map((item) => (
          <Image key={item.id} image={[item]} />
        ))}
      </Grid>
    </div>
  );
};

export default logoBadgeContentBlock
