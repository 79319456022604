import React, { useRef } from "react";
import Image from "components/image";
import "./program-header.scss";
import ProgramAttributeList from "components/program-attribute-list";
import ArrowLink from "components/arrow-link";
import ButtonLink from "components/button-link";
import WaveUnderline from "components/wave-underline";
import ProgramValues from "components/program-values";
import ProgramDifferences from "components/program-differences";
import colors from "colors";
import { useSpring, useChain, a } from "react-spring";

const blueWhite = {
  backgroundColor: colors.blue2,
  color: colors.white,
};

const themes = {
  blue: {
    label: blueWhite,
    values: blueWhite,
  },
  yellow: {
    label: {
      backgroundColor: colors.yellow3,
      color: colors.blue4,
    },
    values: {
      backgroundColor: colors.yellow2,
      color: colors.blue4,
    },
  },
  orange: {
    label: {
      backgroundColor: colors.orange3,
      color: colors.blue4,
    },
    values: {
      backgroundColor: colors.orange2,
      color: colors.blue4,
    },
  },
};

const ProgramHeader = ({
  programHeaderTheme,
  label,
  heading,
  statement,
  image1,
  image2,
  svgIcon,
  subheading,
  subtext,
  programAttributeList,
  buttonLink,
  linkTo,
  programValues,
  programDifferences,
  secondaryLink,
}) => {
  const theme = themes[programHeaderTheme];

  const link = (
    <div className="ProgramHeader-link">
      {buttonLink.url && <ButtonLink linkTo={buttonLink} />}
      {linkTo.url && <ArrowLink linkTo={linkTo} />}
    </div>
  );

  const bottomRef = useRef();
  const bottomProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: bottomRef,
  });

  const imageRef = useRef();
  const imageProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: imageRef,
  });

  const textRef = useRef();
  const textProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: textRef,
  });

  const detailsRef = useRef();
  const detailsProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: detailsRef,
  });

  useChain([imageRef, textRef, bottomRef, detailsRef], [0.5, 1, 1.5, 2]);

  return (
    <div className="ProgramHeader space-for-nav">
      <div className="ProgramHeader-top">
        <a.div className="ProgramHeader-image1" style={imageProps}>
          <Image {...image1[0]} />
        </a.div>
        <a.div className="ProgramHeader-copy" style={textProps}>
          <div className="ProgramHeader-label" style={theme.label}>
            {label}
          </div>
          <div className="ProgramHeader-heading">{heading}</div>
          <div className="ProgramHeader-text">{statement}</div>
        </a.div>
        <a.div className="ProgramHeader-top-bottom" style={bottomProps}>
          <div className="ProgramHeader-image2">
            <Image {...image2[0]} />
            <div className="ProgramHeader-badge">
              <img
                className="lazyload"
                src={svgIcon[0].optimized.placeholder}
                data-src={svgIcon[0].url}
                alt=""
              />
            </div>
          </div>
          {link}
        </a.div>
      </div>

      <a.div className="ProgramHeader-details" style={detailsProps}>
        <WaveUnderline className="ProgramHeader-subheading">
          {subheading}
        </WaveUnderline>
        <div className="ProgramHeader-subtext">{subtext}</div>
        <div className="ProgramHeader-attributelist">
          <ProgramAttributeList items={programAttributeList} />
        </div>
        {link}
      </a.div>
      {programValues.length > 0 && (
        <ProgramValues programValues={programValues} style={theme.values} />
      )}
      {programDifferences.length > 0 && (
        <ProgramDifferences
          programDifferences={programDifferences}
          linkTo={secondaryLink}
        />
      )}
    </div>
  );
};

export default ProgramHeader;
