import React, { useEffect } from "react";
import Blocks from "blocks";
import ReactGA from "react-ga";
import usePage from "./usePage";
import PageLoading from "components/loading/page-loading";
import Error from "components/error";
import NotFound from "components/notfound";
import IframePage from "components/iframepage";
import NewsArticle from "components/news-article";
import SimpleTextPage from "components/simple-text-page";
import { AlertContext } from "components/alert-bar/useAlertContext";
import { useContext } from "react";
import "./page.scss";

import SeomaticHelmet from "components/seomatic-helmet";
import {DataPrivacyCenterPageBuilder} from "../data-privacy-center-page/data-privacy-center-page";

const PageBuilder = ({ entry }) => {
  const { pageBuilder, ...rest } = entry;

  return (
    <div className="Page">
      <Blocks blocks={pageBuilder} entry={rest} />
    </div>
  );
};

const entryTypes = {
  page: PageBuilder,
  iframePage: IframePage,
  news: NewsArticle,
  simpleTextPage: SimpleTextPage,
  clubdataprivacycenterpage: DataPrivacyCenterPageBuilder,
};

const Page = ({ match }) => {
  let url = match.url.replace("/", "");

  if (url.slice(-1) === '/') {
    url = url.substring(0, url.length - 1);
  }

  const { loading, error, data } = usePage(url);
  const { show, hide } = useContext(AlertContext);

  useEffect(() => {
    if (data && data.entry) {
      if (data.entry.showAlertBar !== undefined) {
        if (data.entry.showAlertBar) {
          show();
        } else {
          hide();
        }
      }
    }
  }, [data, hide, show]);

  useEffect(() => {
    if (data && data.entry && data.entry.abTest && data.entry.abTest[0] && data.entry.abTest[0]['__typename'] === 'ab_result') {
      let experimentID = data.entry.abTest[0].id;

      ReactGA.event({
        category: 'ABTest',
        action: 'Page Variant',
        value: data.entry.abTest[0].id.toString(),
        label: data.entry.abTest[0].id.toString()
      });
    }
  }, [data ? data.entry : false]);

  if (loading) {
    return <PageLoading />;
  }
  if (error) {
    return <Error />;
  }

  if (data && !data.entry) {
    return <NotFound />;
  }

  if (loading === false && data === undefined && error === undefined) {
    return <Error />;
  }

  const Comp = entryTypes[data.entry.typeHandle];

  return (
    <>
      <SeomaticHelmet seomatic={data.seomatic} />
      <Comp entry={data.entry} />
    </>
  );
};

export default Page;
