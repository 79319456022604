import React from "react";
import Blocks from "blocks";
import ReactPlayer from "react-player"
import "./campaign-header.scss";
import { themes } from "blocks/theme";
import ThemeContext from "blocks/theme/theme-context";

const CampaignHeader = ({ heading, plainText, video, image, year, children }) => {
  const videoSrc = video && video[0] ? video[0].url : null;
  const imgClass = 'CampaignHeader-image' + (videoSrc ? ' hidden-sm' : '');

  return (
    <ThemeContext.Provider value={themes.whiteblue}>
      <div className="CampaignHeader space-for-nav">
        <div className={imgClass}>
          <picture>
            <source
              media="(min-width: 768px)"
              data-srcset={image[0].widescreen.srcset}
            />
            <source data-srcset={image[0].square.srcset} />
            <img
              className="lazyload"
              src={image[0].widescreen.placeholder}
              data-src={image[0].widescreen.src}
              alt=""
            />
          </picture>
        </div>

        {videoSrc &&
          <div className="CampaignHeader-video">
            <ReactPlayer url={videoSrc} muted={true} loop={true} playing={true} controls={false} width="100%" height="auto" />
          </div>
        }

        <div className="CampaignHeader-year">
          <div className="CampaignHeader-year-sticker">{year}</div>
        </div>
        <div className="CampaignHeader-inner">
          <div className="CampaignHeader-heading">{heading}</div>
          <div className="CampaignHeader-text">{plainText}</div>
        </div>
        <Blocks blocks={children} />
      </div>
    </ThemeContext.Provider>
  );
};

export default CampaignHeader;
