import { idtype } from "querypieces";

export const impactItems = `impactItems {
  ...on impactItems_item_BlockType {
    ${idtype}
    number
    label
    explainer
  }
}`;

export default `
  ...on pageBuilder_impactItems_BlockType {
    ${idtype}
    ${impactItems}
  }
`;
