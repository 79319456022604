import { useReducer, useState, useEffect } from "react";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import useMeasure from "react-use-measure";
import { useSpring } from "react-spring";

import query from "./query";
import {useHistory, useLocation} from "react-router-dom";

const reducer = (state, action) => {
  switch (action.type) {
    case "hover":
      return {
        ...state,
        active: action.value,
      };
    default:
      throw new Error(`unknown action type: ${action.type}`);
  }
};
const initialState = {
  active: null,
};

const hover = (id) => ({
  type: "hover",
  value: id,
});

const useScrolled = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      const isScrolled = window.scrollY > 1;
      setScrolled(isScrolled);
    };
    onScroll();
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return scrolled;
};

const useNav = () => {
  const location = useLocation();
  let international = false;
  let uri = location.pathname.replace("/", "");

  if (uri.slice(-1) === '/') {
    uri = uri.substring(0, uri.length - 1);
  }

  if (uri === "fr-ca" || uri === "hi-in") {
      international = true;
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const [subscribeActive, setSubscribeActive] = useState(false);
  const [isInternationalPage, setIsInternationalPage] = useState(international);

  const [ref, bounds] = useMeasure();

  const [isMobile, setIsMobile] = useState();
  const scrolled = useScrolled();
  const [height, setHeight] = useState();

  const [bgProps, setBgProps] = useSpring(() => ({
    height: "0px",
  }));

  const history = useHistory();
  const client = useApolloClient();

  let params = {
      client: client,
      notifyOnNetworkStatusChange: true
  };

  if ((uri === "fr-ca" || uri === "hi-in")) {
      params.context = {
          headers: { "X-GWC-Lang-Header": uri }
      }
  }

  if (!isInternationalPage) {
      params.context = {
          headers: { }
      }
  }

  useEffect(() => {
      history.listen((location, action) => {
          if (isInternationalPage) {
            setIsInternationalPage(false);
            window.location = location.pathname;
          }
      });
  }, [history, uri, isInternationalPage]);

  setBgProps({
    height: `${height ? height + bounds.height * 1.2 : 0 || 0}px`,
  });

  useEffect(() => {
    if (!state.active) {
      setHeight(0);
    }
  }, [state.active, setBgProps]);

  useEffect(() => {
    if (state.active) {
      setSubscribeActive(false);
    }
  }, [state.active]);

  useEffect(() => {
    if (!state.active && !subscribeActive) {
      setHeight(0);
    }
  }, [state.active, subscribeActive]);

  useEffect(() => {
    if (bounds.width) {
      if (bounds.width < 1100) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [setIsMobile, bounds.width]);

  return {
    navRef: ref,
    res: useQuery(query, params),
    active: state.active,
    subscribeActive,
    setSubscribeActive,
    isMobile,
    scrolled,
    height,
    setHeight,
    bgProps,
    hoverHandlers: (id, hasSubnav) =>
      hasSubnav && {
        onMouseEnter: () => dispatch(hover(id)),
        onMouseLeave: () => dispatch(hover(null)),
        onFocus: () => dispatch(hover(id)),
        onBlur: () => dispatch(hover(null)),
      },
  };
};

export default useNav;
