import React from "react"
import "./grid-block.scss"
import colors from "colors";

const blueWhite = {
  backgroundColor: colors.blue2,
  color: colors.white
};

const themes = {
  blue: {
    label: blueWhite,
    values: blueWhite
  },
  yellow1: {
    label: {
      backgroundColor: colors.yellow3,
      color: colors.blue4
    },
    values: {
      backgroundColor: colors.yellow2,
      color: colors.blue4
    }
  },
  orange: {
    label: {
      backgroundColor: colors.orange1,
      color: colors.blue4
    },
    values: {
      backgroundColor: colors.orange1,
      color: colors.blue4
    }
  }
};

const GridBlock = ({
  backgroundColor,
  note,
  children
}) => {
  const theme = themes[backgroundColor];
  return <div className="GridBlock" style={theme ? theme.values : {}}>
    {note && <h3 className="GridBlock-heading">{note}</h3>}
    <ul className={`GridBlock-list GridBlock-list--${children.length}`}>
      {children.length > 0 && children.map(item =>
        <li key={item.id} className="GridBlockItem">
          <div className="GridBlockItem-inner">
            {item.subheading && (<h5 className="GridBlockItem-subheading">{item.subheading}</h5>)}
            {item.heading && (<h4 className="GridBlockItem-heading">{item.heading}</h4>)}
            {item.plainText && (<p className="GridBlockItem-text">{item.plainText}</p>)}
          </div>
        </li>
      )}
    </ul>
  </div>
}

export default GridBlock
