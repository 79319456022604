import { idtype } from "querypieces";

export default (blocks) => `
  ...on pageBuilder_gradientWorm_BlockType {
    ${idtype}
    worm {
      url
    }
    wormColor
    children {
      ${blocks}
    }
  }
`;
