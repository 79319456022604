import React from "react";
import Blocks from "blocks";
import "./cta-items.scss";

const CtaItems = ({ children }) => {
  return (
    <div className="CtaItems">
      <Blocks blocks={children} />
    </div>
  );
};

export default CtaItems;
