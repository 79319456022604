import React from "react";
import "./sidebarlist.scss";
import fills from "./markerfills.js";
import { useEffect, useRef } from "react";
import CopyButton from "components/copy-button";
import ArrowBackButton from "components/arrow-back-button";
import ArrowLink from "components/arrow-link";
import ButtonLink from "components/button-link";
import { popByRegion, sortDistancesArray } from "helpers";

const SidebarList = ({
  items,
  activeMarker,
  setActiveMarker,
  zoomMarker,
  switchToLocales,
  setHoveredMarker,
  noLocationsInView,
  mapAddress,
  mapProps
}) => {
  const ref = useRef();
  useEffect(() => {
      if (ref.current && typeof ref.current.scrollTo !== "undefined") {
          ref.current.scrollTo(0, 0);
      }
  }, []);
  let sidebarItems = sortDistancesArray(items.slice(0, 100), mapProps);
  let mapAddressLower = mapAddress ? mapAddress.toLowerCase() : '';

  if (mapAddressLower.includes("québec") || mapAddressLower.includes("quebec")) {
    sidebarItems = popByRegion(sidebarItems, 'quebec');
  }

  return (
    <div className="SidebarList" ref={ref}>
      <ArrowBackButton
        className="SidebarList-back"
        onClick={() => switchToLocales()}
      >
        Browse by country
      </ArrowBackButton>

      <div className="SidebarList-count">{`${items.length} locations found`}</div>
      {items.length === 0 && <NoLocationsInView items={noLocationsInView} />}
      <ul className="SidebarList-list">
        {sidebarItems.length === 1 ? (
          <ClubMetaDetails {...sidebarItems[0]} />
        ) : (
          sidebarItems.map((props) => {
            return (
              <SidebarListItem
                key={props.id}
                {...props}
                setHoveredMarker={setHoveredMarker}
                activeMarker={activeMarker}
                zoomMarker={zoomMarker}
                setActiveMarker={setActiveMarker}
              />
            );
          })
        )}
      </ul>
      {items.length > 100 && <div>+ {items.length - 100} more...</div>}
    </div>
  );
};

export default SidebarList;

const SidebarListItem = ({
  id,
  programs,
  address,
  activeMarker,
  zoomMarker,
  lat,
  lng,
  setActiveMarker,
  setHoveredMarker,
}) => {
  const isActive = activeMarker === id;

  const hoverHandlers = {
    onMouseEnter: () => setHoveredMarker(id),
    onMouseLeave: () => setHoveredMarker(null),
  };

  return (
    <li>
      <div className={`SidebarList-item ${isActive ? "active" : ""}`}>
        <button
          className={`SidebarList-item-toggle`}
          onClick={() => {
            setActiveMarker(id);
            zoomMarker({ lat, lng });
          }}
          {...hoverHandlers}
        >
          <ClubMeta programs={programs} address={address} />
        </button>
      </div>
    </li>
  );
};

const getProgramsByType = (programs) =>
  programs.reduce((r, d) => {
    if (!r[d.type]) {
      r[d.type] = [];
    }
    r[d.type].push(d);
    return r;
  }, {});

const CLUB_META_CLUBS_TO_SHOW = 1;
export const ClubMeta = ({ address, programs }) => {
  const programsByType = getProgramsByType(programs);
  return (
    <div className="ClubMeta">
      {Object.keys(programsByType).map((type) => {
        const typePrograms = programsByType[type];
        return (
          <React.Fragment key={type}>
            <div
              className="ClubMeta-type"
              style={{
                backgroundColor: fills[type].fill,
                color: fills[type].textColor,
              }}
            >
              {type}
            </div>
            {typePrograms
              .slice(0, CLUB_META_CLUBS_TO_SHOW)
              .map(({ title }, i) => {
                return (
                  <React.Fragment key={`${title}_${i}`}>
                    <div className="ClubMeta-title">{title} </div>
                    {typePrograms.length > CLUB_META_CLUBS_TO_SHOW ? (
                      <div className="ClubMeta-count">
                        +{typePrograms.length - CLUB_META_CLUBS_TO_SHOW} more
                      </div>
                    ) : null}
                  </React.Fragment>
                );
              })}
          </React.Fragment>
        );
      })}
      <ClubMetaAddress address={address} />
    </div>
  );
};

const ClubMetaAddress = ({ address }) =>
  address && typeof address === "string" ? (
    <div className="ClubMeta-address">{address}</div>
  ) : (
    <div className="ClubMeta-address">
      <div>{address.street}</div>
      <div>
        {address.city}, {address.state} {address.zip}
      </div>
    </div>
  );

export const ClubMetaDetails = ({ address, programs, noContactMessage }) => {
  const programsByType = getProgramsByType(programs);
  return (
    <div className="ClubMeta ClubMetaDetails">
      {Object.keys(programsByType).map((type) => {
        const typePrograms = programsByType[type];
        return (
          <React.Fragment key={type}>
            <div
              className="ClubMeta-type"
              style={{
                backgroundColor: fills[type].fill,
                color: fills[type].textColor,
              }}
            >
              {type}
            </div>
            {typePrograms.map(
              (
                {
                  title,
                  emailAddress,
                  showEmailAddress,
                  needsFacilitator,
                  gradeLevel,
                },
                i
              ) => {
                return (
                  <div key={title + emailAddress + i}>
                    <div className="ClubMeta-title" key={title}>
                      {title}
                    </div>
                    {gradeLevel && <div>Grades {gradeLevel}</div>}
                    {needsFacilitator && <div>Needs Facilitator</div>}
                    {(showEmailAddress || type === 'College Loop') && emailAddress ? (
                      <div className="ClubMeta-email">
                        <div className="ClubMeta-email-address">
                          {emailAddress}
                        </div>
                        <CopyButton value={emailAddress} />
                      </div>
                    ) : type === "Club" && address.country === "United States" && (
                      <div>
                        {noContactMessage || "No contact info available"}
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </React.Fragment>
        );
      })}
      <ClubMetaAddress address={address} />
    </div>
  );
};

const linkStyles = {
  arrow: ArrowLink,
  button: ButtonLink,
};
const NoLocationsInView = ({ items }) => {
  return (
    <div className="NoLocationsInView">
      {items.map(({ id, linkStyle, message, linkTo }) => {
        const Link = linkStyles[linkStyle];
        return (
          <div className="NoLocationsInView-item" key={id}>
            <div className="NoLocationsInView-item-message">{message}</div>
            <Link className="NoLocationsInView-item-link" linkTo={linkTo} />
          </div>
        );
      })}
    </div>
  );
};
