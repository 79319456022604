import React, { useState, useEffect } from "react";
import { a } from "react-spring";
import { Link as RouterLink } from "react-router-dom";
import logo from "images/girls_who_code_white.svg";
import SVG from "react-inlinesvg";
import useGlobals from "hooks/useGlobals";
import Link from "components/link";
import Accordion, { Carrot } from "components/accordionitem";
import colors from "colors";
import x from "./x.svg";
import wave from "images/mobile_nav_waves.svg";
import Subscribe from "components/subscribe";
import {PAGE_TYPE} from "../../pageTypes";
import dataPrivacyCenterLogo from "images/girls_who_code.svg";

const NavMobile = ({ items, navType }) => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    document.body.style.overflow = active ? "hidden" : "";

    return () => {
      document.body.style.overflow = "";
    };
  }, [active]);
  const closeMenu = () => setActive(false);
  return (
    <>
      <button className="MobileNavToggle" onClick={() => setActive(!active)}>
        Menu
      </button>
      {active && <MobileNav items={items} navType={navType} closeMenu={closeMenu} />}
    </>
  );
};

export default NavMobile;

const MobileNav = ({ items, hoverHandlers, active, closeMenu, navType }) => {
  return (
    <div className="MobileNav">
      <div className="MobileNav-top">
        <RouterLink
          to="/"
          className="Nav-logo"
          onClick={closeMenu}
          aria-label="Link to home"
        >
          <SVG src={navType === PAGE_TYPE.PRIVACY_CENTER ? dataPrivacyCenterLogo :logo} />
        </RouterLink>
        <button
          className="MobileNav-close"
          onClick={closeMenu}
          aria-label="Close Menu"
        >
          <SVG src={x} alt="" />
        </button>
      </div>
      <ul className="MobileNav-items">
        {items.map(({ id, ...rest }) => {
          return (
            <li className="MobileNav-items-item" key={id}>
              <MobileNavItem
                {...rest}
                {...hoverHandlers}
                isActive={active === id}
                closeMenu={closeMenu}
              />
            </li>
          );
        })}
        <li className="MobileNav-items-item" key="subscribe">
          <SubscribeButton />
        </li>
      </ul>
      <SVG className="MobileNav-waves" src={wave} alt="" />
    </div>
  );
};

export const MobileNavItem = ({
  typeHandle,
  entry,
  isActive,
  hoverHandlers,
  setHeight,
  closeMenu,
}) => {
  const { data, loading, error } = useGlobals();
  switch (typeHandle) {
    case "entry":
      if (entry[0].children.length > 0) {
        return (
          <Accordion heading={entry[0].title} carrotColor={colors.white}>
            <MobileSubnav
              entries={[entry[0], ...entry[0].children]}
              setHeight={setHeight}
              isActive={isActive}
              closeMenu={closeMenu}
            />
          </Accordion>
        );
      } else {
        return (
          <RouterLink
            className="MobileNavItem-link"
            to={`/${entry[0].uri}`}
            onClick={closeMenu}
          >
            {entry[0].title} <Carrot color={colors.white} />
          </RouterLink>
        );
      }

    case "globalDonate":
      if (loading || error) {
        return null;
      }
      return (
        <Link
          className="MobileNavItem-link"
          url={data.links.donateUrl}
          onClick={closeMenu}
        >
          {data.links.donateText} <Carrot color={colors.white} />
        </Link>
      );

    default:
      return null;
  }
};

const MobileSubnav = ({ entries, closeMenu }) => {
  return (
    <a.ul className="">
      {entries.map(({ id, uri, title }) => {
        return (
          <li key={id} className="">
            <RouterLink
              className="MobileNavItem-sublink"
              to={`/${uri}`}
              onClick={closeMenu}
            >
              {title}
            </RouterLink>
          </li>
        );
      })}
    </a.ul>
  );
};

const SubscribeButton = () => {
  const [active, setActive] = useState(false);
  const { data } = useGlobals();
  
  return (
    <>
      <button
        className={`MobileNavItem-button ${
          active ? "MobileNavItem-button--open" : ""
        }`}
        onClick={() => setActive(!active)}
      >
        {data ? data.links.ctaText : ''}{" "}
        <Carrot className="MobileNavItem-button-carrot" color={colors.white} />
      </button>
      {active && <Subscribe />}
    </>
  );
};
