import { idtype } from "querypieces";

export default `
    ${idtype}
          anchorId
          downloadItem {
            ... on downloads_Asset {
              id
              alt
              img
              srcset(sizes: "")
              title
              url
              uri
            }
          }
          richText
          typeHandle
          secondaryrichtext
          thirdRichText
          heading
          elementsList {
            ... on elementsList_BlockType {
              id
              code
              itemTitle
              message
              options
            }
          }
`;