import React from "react";
import Blocks from "blocks";
import ThemeContext from "./theme-context";
import colors from "colors";

export const themes = {
  teal: {
    accent: colors.teal2,
    background: colors.teal1,
    foreground: colors.blue4,
  },
  blue: {
    foreground: colors.blue3,
    foregroundInverse: colors.white,
    background: colors.blue1,
    accent: colors.teal4,
  },
  blue2: {
    foreground: colors.white,
    background: colors.blue2,
    accent: colors.teal2,
  },
  whiteblue: {
    foreground: colors.blue2,
    accent: colors.blue2,
    background: colors.white,
  },
  yellow: {
    foreground: colors.blue4,
    background: colors.yellow3,
    accent: colors.blue3,
  },
  yellow1: {
    foreground: colors.blue4,
    background: colors.yellow1,
    accent: colors.blue3,
  },
  red: {
    foreground: colors.red3,
    background: colors.red1,
  },
  orange: {
    foreground: colors.blue4,
    background: colors.red2,
    accent: colors.blue3,
  },
};

const Theme = ({ children, theme }) => {
  return (
    <ThemeContext.Provider value={themes[theme]}>
      <Blocks blocks={children} />
    </ThemeContext.Provider>
  );
};

export default Theme;
