import React from "react";
import ArrowLink from "components/arrow-link";
import TextWave from "components/text-wave";
import Attribution from "components/attribution";
import "./profile-cta-no-image.scss";

const plainTextPH =
  "This is placeholder text for this quote here.  It could be this long";

const ProfileCta = ({ quote, attribution, linkTo }) => {
  return (
    <>
      <div className="ProfileCtaNoImage">
        <div>
          <div className="ProfileCtaNoImage-inner">
            <div className="ProfileCtaNoImage-text">
              <div className="ProfileCtaNoImage-heading">
                <TextWave text={quote || plainTextPH} align="left" />
              </div>
              {attribution && (
                <div className="ProfileCtaNoImage-attribution">
                  <Attribution>{attribution}</Attribution>
                </div>
              )}
              {linkTo.url && (
                <ArrowLink className="ProfileCtaNoImage-link" linkTo={linkTo} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCta;
