import { idtype, linkTo, programAttributeList, svgIcon } from "querypieces";

export default `
  ...on pageBuilder_programComparison_BlockType {
    ${idtype}
    children {
      ...on pageBuilder_programComparisonItem_BlockType {
        ${idtype}
        heading
        plainText
        ${programAttributeList}
        ${linkTo}
        ${svgIcon}
      }
    }
  }
`;
