import { idtype, image } from "querypieces";

export default `
  ...on pageBuilder_campaignPullquote_BlockType {
    ${idtype}
    plainText
    attribution
    ${image}
  }
`;
