import text from "blocks/text/query";
import pullquote from "blocks/pullquote/query";
import imageCaption from "blocks/image-caption/query";

export default `
  ...on news_news_Entry {
    typeHandle
    title
    articleBuilder {
      ${text}
      ${pullquote}
      ${imageCaption}
    }
  }
`;
