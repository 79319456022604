import React, { useState } from "react";
import TextWave from "components/text-wave";
import pathmakers from "components/text-wave/pathmakers";
import Input from "components/form/input";
import Radio from "components/form/radio";
import "./text-wave-sandbox.scss";

const messages = [
  "The quick brown fox jumped over the lazy dog",
  "WE'RE BUILDING THE LARGEST PIPELINE OF FUTURE FEMALE ENGINEERS IN THE UNITED STATES",
  "“EXCITING QUOTE FROM A GIRL ABOUT A PROJECT SHE MADE IN A GIRLS WHO CODE CLUB THIS YEAR!”",
  "THE WORLD NEEDS GIRLS LIKE YOU",
  "HELP US CLOSE THE GENDER GAP– FOR GOOD",
  "BRAVE NOT PERFECT"
];

const pathTypes = Object.keys(pathmakers);
const aligns = ["left", "right"];

const TextWaveSandbox = () => {
  const [text, setText] = useState(messages[2]);
  const [align, setAlign] = useState("left");
  const [size, setSize] = useState(62);
  const [width, setWidth] = useState(597);
  const [algo, setAlgo] = useState(pathTypes[0]);

  return (
    <div className="TextWaveSandbox">
      <div className="TextWaveSandbox-controls">
        <fieldset>
          <legend>align</legend>
          {aligns.map(option => (
            <Radio
              key={option}
              onChange={() => setAlign(option)}
              label={option}
              value={option}
              checked={option === align}
            />
          ))}
        </fieldset>

        <fieldset>
          <legend>size</legend>
          <input
            type="range"
            min="10"
            max="72"
            value={size}
            onChange={e => setSize(e.target.value)}
          />
        </fieldset>

        <fieldset>
          <legend>max width</legend>
          <input
            type="range"
            min="24"
            max="1440"
            value={width}
            onChange={e => setWidth(e.target.value)}
          />
        </fieldset>

        <fieldset>
          <legend>Algo</legend>
          {pathTypes.map(type => (
            <Radio
              key={type}
              value={type}
              label={type}
              onChange={() => setAlgo(type)}
              checked={algo === type}
            />
          ))}
        </fieldset>
        <fieldset className="TextWaveSandbox-message">
          <legend>text</legend>
          <Input
            className="message-input"
            value={text}
            onChange={e => setText(e.target.value)}
          />

          {messages.map(message => (
            <button
              key={message}
              className="message-button"
              onClick={() => setText(message)}
            >
              {message}
            </button>
          ))}
        </fieldset>
      </div>
      <div
        style={{
          fontSize: `${size}px`,
          maxWidth: `${width}px`
        }}
      >
        <TextWave text={text} align={align} algo={algo} />
      </div>
    </div>
  );
};

export default TextWaveSandbox;
