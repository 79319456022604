import privacyCenterHeaderSection from "blocks/headerSection/query";
import logoBadgeContentBlock from "blocks/logoBadgeContentBlock/query";
import privacyCenterQuoteContentBlock from "blocks/quoteContentBlock/query";
import {privacyCenterOurPrivacyApproachContentBlock, privacyCenterOurPrivacyApproachContentBlockItem} from "blocks/ourPrivacyApproachContentBlock/query";
import privacyCenterSecurityHighlightContentBlock from "blocks/securityHighlightContentBlock/query";

const clubDataPrivacyCenterPageQuery = `
    ... on clubDataPrivacyCenterPage_clubdataprivacycenterpage_Entry {
      id
      typeHandle
      title
      slug
      clubdataprivacycenterpagebuilder {
        ... on clubdataprivacycenterpagebuilder_richText_BlockType {
          id
          richText
          typeHandle
        }
        ... on clubdataprivacycenterpagebuilder_headerSection_BlockType {
${privacyCenterHeaderSection}
        }
        ... on clubdataprivacycenterpagebuilder_logoBadgeContentBlock_BlockType {
${logoBadgeContentBlock}
        }
        ... on clubdataprivacycenterpagebuilder_quoteContentBlock_BlockType {
${privacyCenterQuoteContentBlock}
        }
        ... on clubdataprivacycenterpagebuilder_ourPrivacyApproachContentBlock_BlockType {
${privacyCenterOurPrivacyApproachContentBlock}
          children {
            ... on clubdataprivacycenterpagebuilder_ourPrivacyApproachContentBlockItem_BlockType {
${privacyCenterOurPrivacyApproachContentBlockItem}
            }
          }
        }
        ... on clubdataprivacycenterpagebuilder_anchorLinks_BlockType {
          id
          children {
            ... on clubdataprivacycenterpagebuilder_anchorLink_BlockType {
              id
              anchorId
              typeHandle
              heading
            }
          }
          typeHandle
        }
        ... on clubdataprivacycenterpagebuilder_securityHighlightContentBlock_BlockType {
${privacyCenterSecurityHighlightContentBlock}
        }
        ... on clubdataprivacycenterpagebuilder_helpfulResourcesContentBlock_BlockType {
          id
          typeHandle
          heading
          children {
            ... on clubdataprivacycenterpagebuilder_helpfulResourcesContentBlockItem_BlockType {
              id
              typeHandle
              linkTo {
                url
                text
                type
                element {
                  id
                  uri
                  title
                }
              }
            }
          }
          anchorId
        }
        ... on clubdataprivacycenterpagebuilder_yourDataContentBlock_BlockType {
          id
          typeHandle
          heading
          children {
            ... on clubdataprivacycenterpagebuilder_yourDataContentBlockItem_BlockType {
              id
              heading
              image {
                url
                alt
                title
                ... on images_Asset {
                  id
                  optimized: optimizedImage {
                    placeholder: placeholderImage
                    srcset
                    srcsetWebp
                    focalPoint
                  }
                  altText
                  title
                  url
                }
              }
              typeHandle
              richText
            }
          }
          anchorId
        }
        ... on clubdataprivacycenterpagebuilder_dataPrivacyAddendumSection_BlockType {
          id
          typeHandle
          anchorId
          download {
            ... on downloads_Asset {
              id
              alt
              img
              srcset(sizes: "")
              title
              url
              uri
            }
          }
          richText
          typeHandle
          secondaryrichtext
          thirdRichText
          heading
          elementsList {
            ... on elementsList_BlockType {
              id
              code
              itemTitle
              message
              options
            }
          }
        }
      }
    }
`;
export default clubDataPrivacyCenterPageQuery;