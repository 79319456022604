import { gql } from "apollo-boost";
import { useQuery, useApolloClient } from "@apollo/react-hooks";

const query = gql`
  query($uri: String) {
    entry(uri: [$uri]) {
      ...on pages_page_Entry {
          showCookieModal
          showFooter
          ismainnavbackgrounded
          isSmoothScrollDisabled
      }
        ... on clubDataPrivacyCenterPage_clubdataprivacycenterpage_Entry {
            id
            typeHandle
        }
    }
    seomatic (uri: $uri) {
      metaTitleContainer
      metaTagContainer
    }
  }
`;

const usePageOptions = (uri) => {
  const client = useApolloClient();

  let params = {
      client: client,
      notifyOnNetworkStatusChange: true
  };

  params.variables = { uri };

  return useQuery(query, params);
};

export default usePageOptions;
