import React from "react";
import Loading from "./loading";

const PageLoading = () => {
  return (
    <div
      className="PageLoading ConicGradientBackground"
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `conic-gradient(
          from 45deg,
          #d7f9f4,
          #fff9e2,
          #fff3e8,
          #e5f8ff,
          #d7f9f4
        )`,
        backgroundSize: "cover",
        padding: 0,
      }}
    >
      <Loading />
    </div>
  );
};

export default PageLoading;
