import { idtype, linkTo, image } from "querypieces";

export default `
  ...on pageBuilder_donateCta_BlockType {
    ${idtype}
    heading
    richText
    ${linkTo}
    ${image}
  }
`;
