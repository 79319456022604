import React from "react";
import Blocks from "blocks";
import "./conicgradientbackground.scss";

const ConicGradientBackground = ({ children, entry }) => {
  return (
    <div className="ConicGradientBackground space-for-nav">
      <Blocks blocks={children} entry={entry} />
    </div>
  );
};

export default ConicGradientBackground;
