import React from "react";
import Link from "components/link";
import "./button-link.scss";
import useTheme from "blocks/theme/useTheme";

const ButtonLink = ({ className = "", linkTo }) => {
  const theme = useTheme();
  return linkTo ? (
    <Link
      {...linkTo}
      className={`ButtonLink ${className}`}
      style={{ boxShadow: `inset 0 0 0 2px ${theme.accent}` }}
    />
  ) : null;
};

export default ButtonLink;
