import React from "react";
import useMoreNews from "./useMoreNews";
import NewsList from "components/news-list";
import WaveUnderline from "components/wave-underline";
import ArrowLink from "components/arrow-link";
import "./more-news.scss";

const MoreNews = ({ excludeIds }) => {
  const { loading, error, data } = useMoreNews({ excludeIds });

  if (loading || error) {
    return null;
  }

  return (
    <div className="MoreNews">
      <h2 className="MoreNews-heading">
        <WaveUnderline>More Articles</WaveUnderline>
      </h2>
      <NewsList items={data.entries} />
      <ArrowLink
        className="MoreNews-more"
        linkTo={{
          text: "See all news",
          element: {
            uri: "news"
          }
        }}
      />
    </div>
  );
};

export default MoreNews;
