import React, { useRef, useEffect, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";

import useClubMap from "./useClubMap";
import Checkbox from "components/form/checkbox";
import AutoCompleteQuery from "./autocompletequery";
import { geocodeByAddress } from "react-places-autocomplete";
import Marker from "./marker";
import Sidebar from "./sidebar";

import fills from "./markerfills.js";
import useIsMobile from "hooks/useIsMobile";
import ArrowLink from "components/arrow-link";

import "./clubmap.scss";
import { ClubMetaDetails } from "./sidebarlist";
import InternationalLinks from "./international-links";
import WaveSeparator from "blocks/waveseparator";
import colors from "colors";

const MAX_SET_ZOOM = 15;
const MAX_SET_ZOOM_NO_DATA = 12;

const ClubMap = ({ interactive = true }) => {
  const mapApi = useRef();
  const firstChange = useRef(false);
  const [mapAddress, setMapAddress] = useState(false);

  const isMobile = useIsMobile();

  const {
    markers = [],
    allMarkers,
    markerTypes,
    mapProps,
    setMapProps,
    currentMarkerType,
    toggleMarkerType,
    searchQuery,
    setSearchQuery,
    currentTab,
    setCurrentTab,
    switchToLocations,
    switchToLocales,
    activeMarker,
    setActiveMarker,
    hoveredMarker,
    setHoveredMarker,
    content,
  } = useClubMap(mapApi);

  const onChange = ({ center, zoom, bounds }) => {
    const updatedProps = { center, zoom, bounds };
    setMapProps({
      ...mapProps,
      ...updatedProps,
    });
    if (firstChange.current) {
      switchToLocations();
    }
    firstChange.current = true;
  };

  const setBounds = (bounds) => {
    if (mapApi.current) {
      mapApi.current.fitBounds(bounds);
    }
  };

  const setZoom = (value) => {
    if (mapApi.current) {
      mapApi.current.setZoom(value);
    }
  };

  const zoomMarker = (LatLng) => {
    if (mapApi.current) {
      mapApi.current.panTo(LatLng);
      mapApi.current.zoom < MAX_SET_ZOOM &&
        mapApi.current.setZoom(MAX_SET_ZOOM);
    }
  };

  const zoomCluster = (marker) => {
    if (mapApi.current) {
      mapApi.current.panTo(marker.LatLng);
      mapApi.current.setZoom(
        mapApi.current.zoom +
          (mapApi.current.zoom < 8 ? 3 : mapApi.current.zoom < 13 ? 2 : 1)
      );
    }
  };

  const geocodeAndZoom = (address) => {
    setMapAddress(address);

    return geocodeByAddress(address)
      .then((results) => {
        setBounds(results[0].geometry.viewport);

        if (mapApi.current.zoom > MAX_SET_ZOOM) {
          setZoom(MAX_SET_ZOOM);
        }
      })
      .catch((error) => console.error("Error", error));
  };
  const selectLocale = (value, extRadius = false) => {
    geocodeAndZoom(value, extRadius);
  };
  const onAddressChange = (address) => {
    geocodeAndZoom(address).then(() => {
      switchToLocations();
    });

    setSearchQuery(mapAddress ? mapAddress.trim() : '');
  };

  const unZoom = (noDataZoom = MAX_SET_ZOOM_NO_DATA) => {
    if (mapApi.current.zoom > noDataZoom) {
      setZoom(noDataZoom);
    }
  };

  const activeMarkerData = useMemo(
    () =>
      allMarkers &&
      activeMarker &&
      allMarkers.find((d) => d.id === activeMarker),
    [activeMarker, allMarkers]
  );
  useEffect(() => {
    if (isMobile && activeMarkerData) {
      const { lat, lng } = activeMarkerData;
      zoomMarker({ lat, lng });
    }
  }, [isMobile, activeMarkerData]);

  return (
    <div className="space-for-nav">
      <div
        className={`ClubMap ClubMap--${interactive ? "interactive" : "static"}`}
      >
        {interactive && (
          <div className="ClubMap-query">
            {content && (
              <label>
                <div className="fieldset-heading">
                  {content.searchLabel || "Search by address"}
                </div>
                <AutoCompleteQuery
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  onAddressChange={onAddressChange}
                  placeholder={content.searchPlaceholder}
                />
              </label>
            )}
          </div>
        )}
        {interactive && isMobile === false && (
          <div className="ClubMap-filter">
            <fieldset className="CheckboxSet">
              <legend className="fieldset-heading">Filter by program</legend>
              {/* <button onClick={() => resetMarkerType()}>all</button> */}
              {markerTypes.map((type) => (
                <Checkbox
                  key={type}
                  value={type}
                  label={type + "s"}
                  fill={fills[type].fill}
                  checked={
                    currentMarkerType.length === 0 ||
                    currentMarkerType.includes(type)
                  }
                  onChange={() => toggleMarkerType(type)}
                />
              ))}
            </fieldset>
          </div>
        )}
        {interactive && isMobile === false && (
          <div className="ClubMap-contact">
            {content && (
              <div>
                {content.ctaText} <br />
                <ArrowLink linkTo={content.linkTo} />
              </div>
            )}
          </div>
        )}
        {interactive && isMobile === false && (
          <Sidebar
            locales={content ? content.locales : null}
            selectLocale={selectLocale}
            unZoom={unZoom}
            setSearchQuery={setSearchQuery}
            allMarkers={allMarkers}
            mapProps={mapProps}
            markers={markers}
            zoomMarker={zoomMarker}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            mapAddress={mapAddress}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
            setHoveredMarker={setHoveredMarker}
            switchToLocations={switchToLocations}
            switchToLocales={switchToLocales}
            noContactMessage={content && content.clubWithNoContactMessage}
            noLocationsInView={content && content.noLocationsInView}
          />
        )}
        <div className="ClubMap-map">
          <GoogleMapReact
            {...mapProps}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              mapApi.current = map;
            }}
            onChange={onChange}
            onClick={() => setActiveMarker(null)}
          >
            {markers &&
              markers.map((props) => {
                const {
                  id,
                  markerId,
                  markerIds,
                  zoom,
                  LatLng,
                  isLocation,
                } = props;
                return (
                  <Marker
                    key={id}
                    isMobile={isMobile}
                    isActive={activeMarker === markerId}
                    isHovered={markerIds.includes(hoveredMarker)}
                    onClick={() => {
                      if (isLocation) {
                        setActiveMarker(markerId);
                      } else {
                        zoomCluster({ zoom, LatLng });
                        setActiveMarker(null);
                      }
                    }}
                    {...props}
                  />
                );
              })}
          </GoogleMapReact>
        </div>
        {isMobile && (
          <div className="ClubMap-mobileresults">
            {activeMarker && (
              <ClubMetaDetails
                {...activeMarkerData}
                noContactMessage={content && content.clubWithNoContactMessage}
              />
            )}
          </div>
        )}
      </div>
      {content && content.countryLinks && (
        <>
          <WaveSeparator color={colors.teal2} />
          <InternationalLinks items={content.countryLinks} />
        </>
      )}
    </div>
  );
};

export default ClubMap;
