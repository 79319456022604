import React, { useState } from "react";
import ReactPlayer from "react-player"
import ReactGA from "react-ga";
import Image from "components/image";
import ArrowLink from "components/arrow-link";
import WaveUnderline from "components/wave-underline";
import "./campaign-cta.scss";
import colors from "colors";

const themes = {
  blue2: {
    backgroundColor: colors.blue2,
    color: colors.white,
    accent: colors.white,
  },
  orange: {
    backgroundColor: colors.red2,
    color: colors.blue4,
    accent: colors.blue3,
  },
  yellow: {
    backgroundColor: colors.yellow3,
    color: colors.blue4,
    accent: colors.blue3,
  },
  blue: {
    backgroundColor: colors.blue3,
    color: colors.white,
    accent: colors.white,
  },
  teal: {
    backgroundColor: colors.teal1,
    color: colors.blue4,
    accent: colors.teal2,
  },
  yellow1: {
    color: colors.blue4,
    backgroundColor: colors.yellow1,
    accent: colors.blue3,
  },
};

const CampaignCta = ({
  image,
  heading,
  plainText,
  linkTo,
  alignImage,
  theme,
  video
}) => {
  const [playing, setPlaying] = useState(false);
  const videoSrc = video && video[0] ? video[0].url : null;
  const videoName = video && video[0] ? video[0].title : '';
  const imgClass = 'CampaignCta-image' + (videoSrc ? ' hidden-sm' : '');
  const hasImage = image.length > 0;
  const { backgroundColor, color, accent } = themes[theme];
  const copyTheme = { backgroundColor, color };

  return (
    <div
      className={`CampaignCta CampaignCta--default align-image--${alignImage}`}
    >
      <div className="CampaignCta-bg" style={copyTheme} />
      <div className={imgClass}>
        {hasImage ? <Image {...image[0]} /> : "Missing Image"}
      </div>

      {hasImage && videoSrc &&
        <div className="CampaignCta-video">
          <ReactPlayer
            url={videoSrc}
            muted={false}
            loop={false}
            controls={playing}
            playing={playing}
            width="100%"
            height="auto"
          />

          {!playing &&
            <div className="play" onClick={() => {
              ReactGA.event({
                category: "Video",
                action: "play",
                label: videoName
              });

              setPlaying(true);
            }}/>
          }
        </div>
      }

      <div className="CampaignCta-copy" style={copyTheme}>
        <div className="CampaignCta-copy-inner">
          <div className="CampaignCta-heading">
            <WaveUnderline color={accent}>{heading}</WaveUnderline>
          </div>
          {plainText && <div className="CampaignCta-text">{plainText}</div>}
          <ArrowLink
            className="CampaignCta-link"
            linkTo={linkTo}
            colorOverride={accent}
            activeColorOverride={accent}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignCta;
