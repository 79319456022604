import React from "react";
import Image from "components/image";
import TextWave from "components/text-wave";
import "./wave-text-on-background-image.scss";
import useIsMobile from "hooks/useIsMobile";

const WaveTextOnBackgroundImage = ({ plainText, image }) => {
  let bgImage = image[0] ? image[0].optimized : null;
  const isMobile = useIsMobile();

  return (
    <div className="space-for-nav">
      <div className="WaveTextOnBackgroundImage">
        <figure className="WaveTextOnBackgroundImage-image">
          {image[0] ? (
            <Image
              {...image[0]}
              style={
                bgImage.focalPoint && !isMobile
                  ? {
                      left: `${bgImage.focalPoint[0] * 100}%`,
                      top: `${bgImage.focalPoint[1] * 100}%`,
                      transform: `translate(-${
                        bgImage.focalPoint[0] * 100
                      }%, -${bgImage.focalPoint[1] * 100}%)`,
                      position: `absolute`,
                    }
                  : {}
              }
            />
          ) : null}
        </figure>
        <div className="WaveTextOnBackgroundImage-heading">
          <h2>
            <TextWave text={plainText} align="left" />
          </h2>
        </div>
      </div>
    </div>
  );
};

export default WaveTextOnBackgroundImage;
