import React from "react";
import "./program-comparison.scss";
import ArrowLink from "components/arrow-link";
import ProgramAttributeList from "components/program-attribute-list";

const ProgramComparisonItem = ({
  heading,
  plainText,
  programAttributeList,
  linkTo,
  svgIcon,
}) => {
  return (
    <div className="ProgramComparisonItem">
      <img
        className="ProgramComparisonItem-icon lazyload"
        src={svgIcon[0].optimized.placeholder}
        data-src={svgIcon[0].url}
        alt=""
      />
      <div className="ProgramComparisonItem-heading">{heading}</div>
      <div className="ProgramComparisonItem-text">{plainText}</div>
      <ProgramAttributeList
        className="ProgramComparisonItem-attributelist"
        items={programAttributeList}
      />
      <ArrowLink linkTo={linkTo} className="ProgramComparisonItem-link" />
    </div>
  );
};

export default ProgramComparisonItem;
