import React, { useEffect } from "react";
import "./ourPrivacyApproachContentBlock.scss";
import Image from "../image/image";

const ourPrivacyApproachContentBlock = (block) => {
  return (
    <div className={"ourPrivacyApproachContentBlock"} id={block.anchorId} name={block.anchorId}>
      <div>
        <h2 className={"ourPrivacyApproachContentBlockHeader"}>
          {block.heading}
        </h2>
      </div>

      <div className={"ourPrivacyApproachContentBlockList"}>
        {block.children.map((item) => {
          return <OurPrivacyApproachContentBlockItem key={item.id} block={item} />
        })}
      </div>
    </div>
  )
}

const OurPrivacyApproachContentBlockItem = ({block}) => {
  return (
    <div className={"ourPrivacyApproachContentBlockItem"}>
      <div className={"ourPrivacyApproachContentBlockItem-Icon"}>
        <Image image={block.image} />
      </div>
      <div className={"ourPrivacyApproachContentBlockItem-Content"}>
        <div className={"ourPrivacyApproachContentBlockItem-Header"}>
          {block.heading}
        </div>
        <div className={"ourPrivacyApproachContentBlockItem-Text"} dangerouslySetInnerHTML={{ __html: block.richText }}>
        </div>
      </div>
    </div>
  )
}

export default ourPrivacyApproachContentBlock
