import React, { useState } from "react";

export const useAlertContext = () => {
  const [isShowing, setIsShowing] = useState(false);
  return {
    show: () => setIsShowing(true),
    hide: () => setIsShowing(false),
    isShowing
  };
};

export const AlertContext = React.createContext({
  show: null,
  hide: null,
  isShowing: null
});
