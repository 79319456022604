import React from "react";
import Blocks from "blocks";

const ProgramComparison = ({ children }) => {
  return (
    <div className="ProgramComparison">
      <div className="ProgramComparison-inner">
        <Blocks blocks={children} />
      </div>
    </div>
  );
};

export default ProgramComparison;
