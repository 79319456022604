import React, { useState } from "react";
import Carrot from "./carrot";
import "./accordion-item.scss";

const AccordionItem = ({ heading, children, carrotColor }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <div className={`AccordionItem ${open ? "AccordionItem--open" : ""}`}>
      <button onClick={toggle} className="AccordionItem-heading">
        {heading}
        <Carrot className="AccordionItem-heading-carrot" color={carrotColor} />
      </button>
      {open && <div className="AccordionItem-content">{children}</div>}
    </div>
  );
};

export default AccordionItem;

export { Carrot };
