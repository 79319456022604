import React from "react";
import TextWave from "components/text-wave";
import "./clubmapcta.scss";
import ArrowLink from "components/arrow-link";
import Image from "components/image";

const ClubMapCta = ({ heading, richText, linkTo, image }) => {
  return (
    <div className="ClubMapCta">
      <Image className="ClubMapCta-image" {...image[0]} />
      <div className="ClubMapCta-heading">
        <TextWave text={heading} />
      </div>
      <div
        className="ClubMapCta-text"
        dangerouslySetInnerHTML={{ __html: richText }}
      />
      <ArrowLink className="ClubMapCta-link" linkTo={linkTo} />
    </div>
  );
};

export default ClubMapCta;
