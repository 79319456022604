import React from "react";
import DynamicWaveLine from "components/dynamic-wave-line";
import "./waveseparator.scss";

const WaveSeparator = ({ color }) => {
  return (
    <div className="WaveSeparator">
      <DynamicWaveLine stroke={color} />
    </div>
  );
};

export default WaveSeparator;
