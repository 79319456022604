import React from "react";
import Image from "components/image";
import "./image.scss";

const ImageBlock = ({ image }) => {
  return (
    <div className="ImageBlock">
      {image[0] ? (
        <Image {...image[0]} />
      ) : (
        <div
          style={{
            paddingBottom: "100%",
            width: "100%",
          }}
          className="ConicGradientBackground"
        />
      )}
    </div>
  );
};

export default ImageBlock;
