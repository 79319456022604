import React from "react";
import "./headerSection.scss"


const headerSection = ({heading, subheading}) => {
  return (
    <div className="headerSection">
      <h1>
        {heading}
      </h1>
      <h2>
        {subheading}
      </h2>
    </div>
  );
}

export default headerSection;