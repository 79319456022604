import { linkTo, idtype } from "querypieces";

export default `
...on pageBuilder_recentNews_BlockType {
  ${idtype}
  heading
  label
  ${linkTo}
}
`;
