import {idtype, linkTo} from "querypieces";

export const privacyCenterHelpfulResourcesContentBlock = `
    ${idtype}
    heading
    anchorId
`;

export const privacyCenterHelpfulResourcesContentBlockItem = `
    ${idtype}
    ${linkTo}
`;