import React, { useRef, useEffect, useState } from "react";
import useResizeObserver from "use-resize-observer/polyfilled";
import pathmakers from "./pathmakers";
import "./text-wave.scss";

const defaultText = "Lorem ipsum dolor sit amet";
const hidden = {
  visibility: "hidden",
  position: "absolute"
};
const waveWidth = 0.15;

export const TextWave = ({
  text = defaultText,
  align = "left",
  algo = "stairs"
}) => {
  text = text || defaultText;
  const containerRef = useRef();
  const textRef = useRef();
  const lineRef = useRef();
  const wordRef = useRef();
  const [path, setPath] = useState(pathmakers[algo](0, align));

  const { height: containerHeight, width: containerWidth } = useResizeObserver({
    ref: containerRef
  });
  const { height: lineHeight } = useResizeObserver({ ref: lineRef });
  const { width: maxWordWidth } = useResizeObserver({ ref: wordRef });

  useEffect(() => {
    const path = pathmakers[algo](0, align);
    setPath(path);
  }, [algo, align]);

  const comparisonProps = useRef();
  useEffect(() => {
    const lines =
      containerHeight && lineHeight
        ? Math.round(containerHeight / lineHeight)
        : null;

    const newComparisonProps = JSON.stringify({
      containerWidth,
      lineHeight,
      algo,
      align
    });

    if (
      containerRef.current &&
      lines &&
      comparisonProps.current !== newComparisonProps
    ) {
      const path = pathmakers[algo](lines, align);
      setPath(path);
      comparisonProps.current = newComparisonProps;
    }
  }, [containerWidth, containerHeight, lineHeight, algo, align]);

  return (
    <div
      className="ImageWaveText-text-inner"
      style={{
        position: "relative",
        textAlign: align,
        minWidth: `${maxWordWidth * (1 + waveWidth)}px`
      }}
    >
      <div ref={lineRef} style={{ position: "absolute", visibility: "hidden" }}>
        &nbsp;
      </div>

      {/* longest word */}
      <div ref={wordRef} style={hidden}>
        {text.split(/\s/).map((word, i) => {
          return <div key={`${word}${i}`}>{word}</div>;
        })}
      </div>

      {/* measure height */}
      <div style={hidden}>
        <div
          className={`TextWave TextWave--${align}`}
          style={{
            width: `${waveWidth * 100}%`,
            float: align,
            height: containerHeight,
            shapeOutside: path,
            paddingLeft: "15%"
          }}
        />
        <span ref={textRef}>{text}</span>
      </div>

      <div className="TextWave-content">
        <div
          className={`TextWave TextWave--${align}`}
          style={{
            width: `${waveWidth * 100}%`,
            float: align,
            height: containerHeight,
            shapeOutside: path
          }}
        />
        <div className="TextWave-measure" ref={containerRef}></div>
        <div>{text}</div>
      </div>
    </div>
  );
};

const Text = ({ text }) => <div>{text}</div>;

export default Text;
