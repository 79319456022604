import { idtype, programAttributeList, linkTo } from "querypieces";

export default `
  ...on pageBuilder_clubApplication_BlockType {
    ${idtype}
    heading
    statement
    ${programAttributeList}
    ${linkTo}
  }
`;
