import React from "react";
import ArrowLink from "components/arrow-link";
import "./icon-heading-set.scss";

const IconHeadingSet = ({ iconHeadingSet }) => {
  return (
    <ul className="IconHeadingSet">
      {iconHeadingSet.map(item => {
        return (
          <li key={item.id} className="IconHeadingSet-item">
            {item.icon[0] &&
            <div className="IconHeadingSet-item-icon">
                <img
                    src={item.icon[0].url || "//placehold.it/300x300"}
                    alt={item.icon[0].title}
                />
            </div>
            }
            <div className="IconHeadingSet-item-heading">
              {item.heading}
              {item.linkTo && item.linkTo.url &&
                <div className="IconHeadingSet-item-link">
                    <ArrowLink linkTo={item.linkTo}/>
                </div>
              }
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default IconHeadingSet;
