import React from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const SeomaticHelmet = ({ seomatic }) => {
  return (
    <Helmet>
      {ReactHtmlParser(seomatic.metaTitleContainer)}
      {ReactHtmlParser(seomatic.metaTagContainer)}
    </Helmet>
  );
};

export default SeomaticHelmet;
