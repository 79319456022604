import { idtype, linkTo } from "querypieces";
import iconHeadingSet from "components/icon-heading-set/query";

export default `
  ...on pageBuilder_waysToFundraise_BlockType {
    ${idtype}
    heading
    caption
    ${iconHeadingSet}
    subheading
    plainText
    links {
      ...on links_link_BlockType {
        id
        ${linkTo}
      }
    }
  }
`;
