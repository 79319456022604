import { idtype, linkTo } from "querypieces";

export default `
  ...on pageBuilder_arrowLink_BlockType {
    ${idtype}
    ${linkTo}
    linkType
    position
  }
`;
