import React from "react";
import "./error.scss";

const Error = () => {
  return (
    <div className="Error">
      <div className="Error-inner">
        {!navigator.onLine ? "It appears you are offline." : "Error"}
      </div>
    </div>
  );
};

export default Error;
