import { idtype, linkTo, image } from "querypieces";

export default `
...on pageBuilder_imageLink_BlockType {
  ${idtype}
  heading
  subheading
  links_children: children {
    ...on pageBuilder_imageLinks_BlockType {
      ${idtype}
      ${image}
      heading
      links {
        ...on links_link_BlockType {
          ${idtype}
          ${linkTo}
        }
      }
    }
  }
}`
