import React from "react";
import ToolTip from "react-portal-tooltip";
import fills from "./markerfills.js";
import { useState } from "react";
import MapMarkerIcon from "./map-marker-icon";

const supportsConicGradient =
  typeof window !== "undefined" && window.CSS
    ? window.CSS.supports("background", "conic-gradient(#eee, #bbb)")
    : true;

const gradientString = (types) => {
  const typeFills = types.map((type) => fills[type].fill);

  typeFills.push(fills[types[0]].fill);

  return typeFills.join(",");
};

const TT_STYLE = {
  arrowStyle: {
    borderColor: false,
  },
};

const Marker = ({
  id,
  tooltip,
  text,
  isLocation,
  types,
  onClick,
  isActive,
  isMobile,
  isHovered: hoveredInSidebar,
}) => {
  const [hovered, setHovered] = useState(false);

  const style =
    types.length === 1
      ? { backgroundColor: fills[types[0]].fill }
      : {
          backgroundImage: supportsConicGradient
            ? `conic-gradient(${gradientString(types)})`
            : `linear-gradient(${gradientString(types)})`,
        };

  const handlers = {
    onClick,
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  };

  return (
    <div>
      <div
        className={`ClubMap-map-marker ${isLocation ? "single" : "cluster"}`}
        style={{
          cursor: !isLocation ? "zoom-in" : "pointer",
        }}
        id={`tt_${id}`}
        onClick={onClick}
        {...handlers}
      >
        <div className="ClubMap-map-marker-dot" style={style}>
          {text}
        </div>
        {isActive && <MapMarkerIcon />}
      </div>
      {isLocation && !isMobile && (
        <ToolTip
          key={isActive}
          style={TT_STYLE}
          active={isActive || hovered || hoveredInSidebar}
          position={isActive ? "right" : "top"}
          parent={`#tt_${id}`}
          group={isActive ? "active" : "other"}
          useHover={false}
          arrow={"center"}
        >
          {tooltip}
        </ToolTip>
      )}
    </div>
  );
};

export default Marker;
