import React from "react";
import Blocks from "blocks";
import "./solid-background.scss";

const SolidBackground = ({ colorCombo, children }) => {
  return (
    <div
      className={`SolidBackground SolidBackground--${colorCombo.toLowerCase()}`}
    >
      <Blocks blocks={children} />
    </div>
  );
};

export default SolidBackground;
