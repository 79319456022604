import { idtype, linkTo } from "querypieces";

export default `
  ...on pageBuilder_partnerLogos_BlockType {
    ${idtype}
    backgroundColor
    iconColor
    heading
    plainText
    logos {
      id
      title
      url
      ...on images_Asset {
        optimized: optimizedImage {
          placeholder
        }
      }
    }
    ${linkTo}
  }
`;
