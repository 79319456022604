import React from "react";
import { themes } from "blocks/theme";
import colors from "colors";
import ArrowLink from "components/arrow-link";
import SVG from "react-inlinesvg";
import "./partner-logos.scss";

const PartnerLogos = ({ backgroundColor, iconColor, heading, plainText, logos, linkTo }) => {
  const bg = themes[backgroundColor] ? themes[backgroundColor].background : "#ffffff";
  let linkColor = colors.teal3;

  if (backgroundColor === 'orange') {
    linkColor = colors.blue3;
  }

  if (backgroundColor === 'yellow') {
    linkColor = colors.teal2;
  }

  return (
    <div className="PartnerLogos" style={{ backgroundColor: bg }}>
      <div className="PartnerLogos-inner">
        <div className="PartnerLogos-grid">
          <div className="PartnerLogos-leftside">
            <div className="inner-head">{heading}</div>
            <div className="inner-text">{plainText}</div>
          </div>
          <div className="PartnerLogos-partners">
            {logos.map(({ id, url, title }) => (
              <SVG
                className="lazyload"
                preProcessor={code => code.replace(/fill=".*?"/g, 'fill="' + (colors[iconColor] || colors.teal3) + '"').replace(/fill:#FFFFFF;/g, 'fill:white;').replace(/fill:#.*?;/g, 'fill:' + (colors[iconColor] || colors.teal3) + ';')}
                key={id}
                src={url}
                alt={title}
              />
            ))}

            <ArrowLink className="link" colorOverride={linkColor} linkTo={linkTo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerLogos;
