import React from "react";
import Image from "components/image";
import ArrowLink from "components/arrow-link";
import TextWave from "components/text-wave";
import waveSrc from "./bgwave.svg";
import waveRectSrc from "./bgwave_rect.svg";
import SVG from "react-inlinesvg";
import "./wave-cta.scss";

const WaveCta = ({ image, heading, plainText, linkTo }) => {
  return (
    <div className="WaveCta">
      <div className="WaveCta-image">
        <div className="WaveCta-image-wave">
          <SVG
            className="WaveCta-image-wave-image--desktop"
            src={waveSrc}
            alt=""
          />
          <SVG
            className="WaveCta-image-wave-image--mobile"
            src={waveRectSrc}
            alt=""
          />
        </div>
        {image[0] ? <Image {...image[0]} /> : "Missing Image"}
      </div>
      <div className="WaveCta-heading">
        <TextWave text={heading} />
      </div>
      <div className="WaveCta-text">{plainText}</div>
      <ArrowLink className="WaveCta-link" linkTo={linkTo} />
    </div>
  );
};

export default WaveCta;
