import React from "react";
import ArrowLink from "components/arrow-link";
import ButtonLink from "components/button-link";
import HeadingText from "components/heading-text";
import "./services-apply.scss";

const ServicesApply = ({
  heading,
  plainText,
  iconThings,
  linkTo,
  secondaryLink,
}) => {
  return (
    <div className="ServicesApply">
      <HeadingText heading={heading} text={plainText} />

      <ul className="ServicesApply-iconthings">
        {iconThings.map(({ id, icon, heading, text }) => {
          return (
            <li key={id} className="ServicesApply-iconthings-item">
              {icon[0] && (
                <div className="ServicesApply-iconthings-item-icon">
                  <img
                    className="lazyload"
                    src={icon[0].optimized.placeholder}
                    data-src={icon[0].url}
                    alt={heading}
                  />
                </div>
              )}
              <div className="ServicesApply-iconthings-item-heading">
                {heading}
              </div>
              <div className="ServicesApply-iconthings-item-text">{text}</div>
            </li>
          );
        })}
      </ul>
      <div className="ServicesApply-links">
        <ButtonLink linkTo={linkTo} />
        <ArrowLink linkTo={secondaryLink} />
      </div>
    </div>
  );
};

export default ServicesApply;
