import React from "react";
import Image from "components/image";
import { Link } from "react-router-dom";
import "./news-list.scss";

const NewsList = ({ items }) => {
  return (
    <ul className="NewsList">
      {items.map(({ id, uri, title, image }) => {
        return (
          <li key={id} className="NewsList-item">
            <Link to={`/${uri}`}>
              <div className="NewsList-item-title">{title}</div>
              {image[0] && (
                <div className="NewsList-item-image">
                  <Image {...image[0]} />
                </div>
              )}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default NewsList;
