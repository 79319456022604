import React, {useEffect, useState} from "react";
import "./topScrollButton.scss";
import useMeasure from "react-use-measure";
import SVG from "react-inlinesvg";
import topArrowSrc from "./arrowup.svg";

const TopScrollButton = ({navHeight = 250}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY >= navHeight);
    };
    window.addEventListener('scroll', handleScroll);
  }, []);

  const onClick = () => {
    window.scrollTo(0, 0);
  }

  return isVisible && (<div className={"topScrollButtonBlock"} onClick={onClick}>
    <SVG src={topArrowSrc} alt="" />
  </div>)
}

export default TopScrollButton;