import { linkTo } from "querypieces";
import { gql } from "apollo-boost";

export default gql`
query {
  globalSet (handle: "programMap") {
    ...on programMap_GlobalSet {
      programMap {
        ...on programMap_BlockType {
          searchLabel
          searchPlaceholder
          filterLabel
          ctaText
          ${linkTo}
          locales {
            ...on locales_country_BlockType {
              id
              countryName
              regions
              ${linkTo}
            }
          }
          clubWithNoContactMessage
          noLocationsInView {
            ...on noLocationsInView_cta_BlockType {
              id
              message
              ${linkTo}
              linkStyle
            }
          }
          countryLinks {
            ...on countryLinks_country_BlockType {
              id
              ${linkTo}
            }
          }
        }
      }
    }
  }
}
`;
