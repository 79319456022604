import { idtype } from "querypieces";

export default `
  programValues {
    ...on programValues_item_BlockType {
      ${idtype}
      heading
      plainText
    }
  }
`;
