import React, { useEffect } from "react";
import "./yourDataContentBlock.scss";
import Image from "../image/image";
import Grid from "../../components/grid";

const yourDataContentBlock = (block) => {
  return (
    <div className={"yourDataContentBlock"} id={block.anchorId} name={block.anchorId}>
      <div className={"yourDataContentBlockHeader"}>
        <h2>{block.heading}</h2>
      </div>
      <div className={"yourDataContentBlockList"}>
        <Grid gridClass={"Grid centered"} itemClass="third">
          {block.children.map((item) => {
            return <YourDataContentBlockItem key={item.id} block={item} />
          })}
        </Grid>
      </div>
    </div>
  )
}

const YourDataContentBlockItem = ({block}) => {
  return (
    <div className={"yourDataContentBlockItem"}>
      <div className={"yourDataContentBlockItem-Icon"}>
        <Image image={block.image} />
      </div>
      <div className={"yourDataContentBlockItem-Header"}>
        <h4>{block.heading}</h4>
      </div>
      <div className={"yourDataContentBlockItem-Text"} dangerouslySetInnerHTML={{ __html: block.richText }}></div>
    </div>
  )
}

export default yourDataContentBlock
