import { idtype, teamMember } from "querypieces";

export default `
  ...on pageBuilder_manualTeamSelection_BlockType {
    ${idtype}
    heading
    teamMember {
      ${teamMember}
    }
  }
  ...on pageBuilder_teamType_BlockType {
    ${idtype}
    teamType {
      id
    }
  }
`;
