import { gql } from "apollo-boost";

export default gql`
{
  cookiesModal: globalSet(handle: "cookiesModal") {
    ... on cookiesModal_GlobalSet {
      plainText
      richText
      linkTo {
        customText
        target
        url
      }
    }
  }
}
`;
