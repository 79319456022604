import React from "react";
import Image from "components/image";
import "./image-caption.scss";

const ImageCaption = ({ image, caption }) => {
  console.log(image[0]);
  return (
    <div className="ImageCaption">
      <Image className="ImageCaption-image" {...image[0]} />
      <div className="ImageCaption-caption">{caption}</div>
    </div>
  );
};

export default ImageCaption;
