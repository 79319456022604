import React, { useEffect } from "react";
import "./securityHighlightContentBlock.scss";
import Image from "../image/image";

const securityHighlightContentBlock = (block) => {
  return (
    <div className={"securityHighlightContentBlock"} id={block.anchorId} name={block.anchorId}>
      <div className={"securityHighlightContentBlock-Wrapped"}>
        <div></div>
        <div className={"securityHighlightContentBlock-Icon"}>
          <Image image={block.image} />
        </div>
        <div className={"securityHighlightContentBlock-Content"}>
          <h2 className={"securityHighlightContentBlock-Header"}>
            {block.heading}
          </h2>
          <div className={"securityHighlightContentBlock-Text"} dangerouslySetInnerHTML={{ __html: block.richText }}>
          </div>
          <div className={"securityHighlightContentBlock-SubText"} dangerouslySetInnerHTML={{ __html: block.secondaryrichtext }}>
          </div>
        </div>
      </div>
    </div>
  )
}

export default securityHighlightContentBlock
