import { gql } from "apollo-boost";
import { idtype, linkTo } from "querypieces";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import {useLocation} from "react-router-dom";

const query = gql`
  query {
    footer: globalSet (handle: "footerLinks") {
      ...on footerLinks_GlobalSet {
        socialLinks {
          ...on socialLinks_item_BlockType {
            ${idtype}
            icon {
              ...on images_Asset {
                url
                title
                altText
              }
            }
            linkTo
          }
        }
        primaryLinks {
          ...on primaryLinks_item_BlockType {
            ${idtype}
            ${linkTo}
          }
        }
        secondaryLinks {
          ...on secondaryLinks_item_BlockType {
            ${idtype}
            ${linkTo}
          }
        }
      }
    }
  }
`;

const useFooter = () => {
  const client = useApolloClient();
  const location = useLocation();
  let uri = location.pathname.replace("/", "");

  if (uri.slice(-1) === '/') {
      uri = uri.substring(0, uri.length - 1);
  }

  let params = {
      client: client,
      notifyOnNetworkStatusChange: true
  };

  if (uri === "fr-ca" || uri === "hi-in") {
      params.context = {
          headers: { "X-GWC-Lang-Header": uri }
      }
  }

  return useQuery(query, params);
};

export default useFooter;
