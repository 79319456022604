import React from "react";
import { bullet } from "components/themed-waves";
import colors from "colors";
import "./pullquote-simple.scss";

const PullquoteSimple = ({ heading, statement, attribution, colorCombo }) => {
  let color =
    colorCombo && colorCombo === "Blue" ? "blue3" : colorCombo ? "teal3" : null;
  return (
    <div className="PullquoteSimple">
      {heading && <h2>{heading}</h2>}
      {statement && (
        <blockquote
          style={{
            color: colors[color] || colors.blue4,
          }}
        >
          {statement}
        </blockquote>
      )}
      {attribution && (
        <p>
          <span
            style={{
              content: `url(data:image/svg+xml;utf8,${encodeURIComponent(
                bullet(colorCombo || colors.teal2)
              )})`,
              marginRight: 10,
            }}
          />
          {attribution}
        </p>
      )}
    </div>
  );
};

export default PullquoteSimple;
