import React from "react";
import ProgramAttributeList from "components/program-attribute-list";
import ArrowLink from "components/arrow-link";
import WaveUnderline from "components/wave-underline";
import "./program-detail.scss";

const ProgramDetail = ({
  heading,
  plainText,
  linkTo,
  programAttributeList
}) => {
  return (
    <div className="ProgramDetail">
      <WaveUnderline className="ProgramDetail-heading">{heading}</WaveUnderline>
      <div className="ProgramDetail-text">{plainText}</div>
      <ArrowLink className="ProgramDetail-link" linkTo={linkTo} />
      <div className="ProgramDetail-attributelist">
        <ProgramAttributeList items={programAttributeList} />
      </div>
    </div>
  );
};

export default ProgramDetail;
