import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { teamMember } from "querypieces";
import { sortAlphaProp} from "helpers";

const query = gql`
  query {
    teams: entries(section: "team") {
      ${teamMember}
    }
    teamTypes: categories(group: "teamMemberTypes") {
      id
      title
    }
  }
`;

const useTeams = () => {
  const { loading, error, data } = useQuery(query);

  return {
    loading,
    error,
    data: data ? selectTeam(data) : data
  };
};

export default useTeams;

const selectTeam = ({ teams, teamTypes }) => {
  const teamMembers = teamTypes.map(({ id, title }) => {
    const membersInTeam = teams
      .filter(d => d.teamType[0].id === id)
    


    return {
      id,
      title,
      members: sortAlphaProp(membersInTeam,"lastName")
    };
  });

  return {
    teamMembers
  };
};
