import React from "react";
import { Waypoint } from "react-waypoint";
import { useSpring, a } from "react-spring";
import { scaleLinear, scaleTime } from "d3-scale";
import "./infographic.scss";

const colors = {
  yellow1: "#fdd946",
  teal: "#0d9c90",
  blue: "#0169e1",
  orange: "#fa7815",
  black: "#003046",
  transparent: "rgba(0,0,0,0)",
  white: "#ffffff",
};

const HEIGHT = 450;
const WIDTH = 1000;
const OFFSET = 25;

const Infographic = ({ backgroundColor, xAxis, yAxis, dataBlocks }) => {
  const labels = dataBlocks.map((data) => data.heading);
  const values = dataBlocks.map((data) => parseInt(data.value));
  const color = colors[backgroundColor];
  const maxVal = Math.max(...values);
  const minVal = Math.min(...values);
  const time = scaleTime()
    .domain([new Date(labels[0]), new Date(labels[labels.length - 1])])
    .range([OFFSET, WIDTH - OFFSET]); //.nice()
  const y = scaleLinear()
    .domain([minVal, maxVal * 1.2])
    .range([HEIGHT - OFFSET * 2, 0]);

  const points = values.map((v, i) => {
    return [time(new Date(labels[i])), y(v)];
  });

  const path = points.map((v, i) => {
    let letter = i % 3 === 0 ? "T" : "Q";
    return i % 2 !== 0 ? `${letter} ${v.join(" ")}` : v.join(" ");
  });

  const [style, set] = useSpring(() => ({
    transform: "scaleY(0)",
    transformOrigin: "bottom",
    opacity: 0,
  }));

  const [label, setLabel] = useSpring(() => ({
    opacity: 0,
  }));

  function enter() {
    set({
      transform: "scaleY(1)",
      opacity: 1,
    });

    setLabel({
      opacity: 1,
      delay: 350,
    });
  }

  return (
    <Waypoint onEnter={enter} topOffset={"-50%"} bottomOffset={"50%"}>
      <div className="Infographic">
        <div className="Infographic-inner">
          <svg viewBox={`0 0 ${WIDTH} ${HEIGHT}`}>
            {/* year labels */}
            <g>
              {labels.map((val, i) => {
                let anchor =
                  i === 0
                    ? "start"
                    : i === values.length - 1
                    ? "end"
                    : "middle";
                return (
                  <a.text
                    style={label}
                    key={val}
                    textAnchor={anchor}
                    className="Infographic-xVals"
                    x={points[i][0]}
                    y={HEIGHT}
                  >
                    {val}
                  </a.text>
                );
              })}
            </g>

            {/* percentage labels */}
            <g>
              {values.map((val, i) => {
                let off =
                  i === 0
                    ? OFFSET / 2
                    : i === values.length - 1
                    ? OFFSET / -2
                    : "0";
                return (
                  <a.text
                    style={label}
                    key={`label_${val}`}
                    textAnchor={"middle"}
                    dx={off}
                    x={points[i][0]}
                    y={points[i][1] - OFFSET * 2}
                    className="Infographic-yVals"
                  >
                    {val}%
                  </a.text>
                );
              })}
            </g>
            <g>
              {values.map((val, i) => {
                let off = i === 0 ? 2 : i === values.length - 1 ? -2 : 0;
                return (
                  <a.line
                    style={label}
                    key={`line_${val}`}
                    stroke={colors.black}
                    strokeWidth={2}
                    x1={points[i][0] + off}
                    x2={points[i][0] + off}
                    y1={points[i][1]}
                    y2={points[i][1] - OFFSET * 1.5}
                  />
                );
              })}
            </g>

            <a.path
              style={style}
              d={`M ${path.join(" ")} L${points[points.length - 1][0]} ${
                HEIGHT - OFFSET
              } L${OFFSET} ${HEIGHT - OFFSET}`}
              fill={color ? color : colors.yellow1}
              strokeLinejoin="round"
              stroke={color ? color : colors.yellow1}
              strokeWidth="1"
            />
          </svg>

          <p className="Infographic-yAxis">{yAxis}</p>
        </div>
      </div>
    </Waypoint>
  );
};

export default Infographic;
