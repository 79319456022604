import { idtype, imageAsset } from "querypieces";

export const privacyCenterOurPrivacyApproachContentBlock = `
    ${idtype}
    heading
    anchorId
`;

export const privacyCenterOurPrivacyApproachContentBlockItem = `
    ${idtype}
                image {
         ${imageAsset}
                }
              richText
          heading
`;