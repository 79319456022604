import React from "react";
import ArrowLink from "components/arrow-link";

const CtaItem = ({ heading, plainText, linkTo, svgIcon }) => {
  return (
    <div className="CtaItems-item">
      <img
        className="CtaItems-item-image lazyload"
        data-src={svgIcon[0].url}
        src={svgIcon[0].optimized.placeholder}
        alt={`icon for ${heading}`}
      />
      <div className="CtaItems-item-heading">{heading}</div>
      <div className="CtaItems-item-text">{plainText}</div>
      {linkTo.url || linkTo.element ? (
        <ArrowLink className="CtaItems-item-link" linkTo={linkTo} />
      ) : (
        <div style={{ paddingBottom: `var(--block-margin)` }} />
      )}
    </div>
  );
};

export default CtaItem;
