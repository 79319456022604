import React from "react";
import { a, useSpring } from "react-spring";
import ArrowWave from "components/arrow-wave";
import useArrowWave from "components/arrow-wave/useArrowWave";
import colors from "colors";
import useTheme from "blocks/theme/useTheme";
import "./arrow-button.scss";

const ArrowButton = ({ children, onClick, className = "" }) => {
  const theme = useTheme();
  const activeColor = colors.teal4;
  const color = theme.accent || colors.teal3;

  const { active, handlers } = useArrowWave();
  const [props, set] = useSpring(() => ({
    color,
  }));
  set({
    color: active ? activeColor : color,
  });
  return (
    <button
      {...handlers}
      className={`ArrowButton ${className}`}
      onClick={onClick}
    >
      <a.div style={props}>
        <span>{children}</span> <ArrowWave active={active} fill={props.color} />
      </a.div>
    </button>
  );
};

export default ArrowButton;
