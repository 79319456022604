import {
  idtype,
  linkTo,
  programAttributeList,
  image169,
  image54,
} from "querypieces";
import programValues from "components/program-values/query";

export default `
  ...on pageBuilder_nationHeader_BlockType {
    ${idtype}
    image1: ${image54}
    label
    heading
    statement
    ${linkTo}
    subheading
    subtext
    ${programAttributeList}
    image2: ${image169}
    ${programValues}
    languages {
      ...on languages_TableRow {
        label
        uri
      }
    }
  }
`;
