import React from "react";
import bullet from "images/wavebullet.svg";
import SVG from "react-inlinesvg";
import "./pullquote.scss";

const Pullquote = ({ statement, attribution }) => {
  return (
    <div className="Pullquote">
      <div className="Pullquote-statement">{statement}</div>
      {attribution && (
        <div className="Pullquote-attribution">
          <SVG src={bullet} /> {attribution}
        </div>
      )}
    </div>
  );
};

export default Pullquote;
