const makeRag = (lines, align) => {
  const lineUnit = 100 / lines;
  const isLeft = align === "left";

  return `polygon(
    ${new Array(lines - 1)
      .fill(null)
      .map((d, i) => {
        const line = i + 1;
        const width = Math.random() * 100;
        return `
          ${isLeft ? 0 : 100}% ${lineUnit * line}%,
          ${isLeft ? 100 - width : width}% ${lineUnit * line}%,
          ${isLeft ? 100 - width : width}% ${lineUnit * (line + 1)}%,
          ${isLeft ? 0 : 100}% ${lineUnit * (line + 1)}%,
        `.trim();
      })
      .join("")
      .trim()}
      ${isLeft ? 0 : 100}% 100%
    )`;
};

// const makeStairs = (lines, align) => {
//   const lineUnit = 100 / lines;
//   const isLeft = align === "left";

//   return `polygon(
//     ${new Array(lines - 1)
//       .fill(null)
//       .map((d, i) => {
//         const line = i + 1;
//         const width = 100 - (100 / lines) * (i + 1);
//         return `
//           ${isLeft ? 0 : 100}% ${lineUnit * line}%,
//           ${isLeft ? 100 - width : width}% ${lineUnit * line}%,
//           ${isLeft ? 100 - width : width}% ${lineUnit * (line + 1)}%,
//           ${isLeft ? 0 : 100}% ${lineUnit * (line + 1)}%,
//         `.trim();
//       })
//       .join("")
//       .trim()}
//       ${isLeft ? 0 : 100}% 100%
//     )`;
// };
const makeStairs = (lines, align) => {
  const isLeft = align === "left";
  return `polygon(
    ${
      isLeft
        ? `
      0% 0%,
      0% 100%,
      100% 100%
    `
        : `
      100% 0%,
      100% 100%,
      0% 100%
    `
    }
  )`;
};
const makeBell = (lines, align) => {
  const isLeft = align === "left";
  return `polygon(
    ${
      isLeft
        ? `
      0% 0%,
      100% 50%,
      0% 100%
    `
        : `
      100% 0%,
      0% 50%,
      100% 100%
    `
    }
  )`;
};

// const makeBell = (lines, align) => {
//   const lineUnit = 100 / lines;
//   const isLeft = align === "left";

//   return `polygon(
//     ${new Array(lines)
//       .fill(null)
//       .map((d, i) => {
//         const line = i;
//         const width = Math.round(Math.abs(50 - (100 / (lines - 1)) * i) * 2);

//         const y = lineUnit * (line + 0.5);
//         const y1 = (y - 0.01).toFixed(2);
//         const y2 = (y + 0.01).toFixed(2);
//         return `
//           ${isLeft ? 0 : width}% ${y1}%,
//           ${isLeft ? 100 - width : width}% ${y1}%,
//           ${isLeft ? 100 - width : width}% ${y2}%,
//           ${isLeft ? 0 : width}% ${y2}%,
//         `.trim();
//       })
//       .join("")
//       .trim()}
//       ${isLeft ? 0 : 100}% 100%
//     )`;
// };
export default {
  stairs: makeStairs,
  bell: makeBell,
  rag: makeRag
};
