import React from "react";
import { useSpring, a, config } from "react-spring";
import PlacesAutocomplete from "react-places-autocomplete";
import searchIconSrc from "./search.svg";
import SVG from "react-inlinesvg";
import "./autocompletequery.scss";

const AutoCompleteQuery = ({
  searchQuery,
  setSearchQuery,
  onAddressChange,
  placeholder
}) => {
  return (
    <PlacesAutocomplete
      value={searchQuery}
      onChange={val => setSearchQuery(val)}
      onSelect={onAddressChange}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div className="PlacesAutocomplete">
            <div className="PlacesAutocomplete-input">
              <input {...getInputProps()} placeholder={placeholder} />
              <SVG src={searchIconSrc} alt="" />
            </div>
            {loading && <Loading className="PlacesAutocomplete-loading" />}
            {suggestions.length > 0 && (
              <div className="PlacesAutocomplete-results">
                <div className="PlacesAutocomplete-results-dropdown">
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "PlacesAutocomplete-results-dropdown-item--active"
                      : "PlacesAutocomplete-results-dropdown-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <div className="PlacesAutocomplete-results-dropdown-item-main">
                          {suggestion.formattedSuggestion.mainText}
                        </div>
                        <div className="PlacesAutocomplete-results-dropdown-item-secondary">
                          {suggestion.formattedSuggestion.secondaryText}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AutoCompleteQuery;

const Loading = ({ ...rest }) => {
  const props = useSpring({
    config: config.stiff,
    from: {
      opacity: 0
    },
    to: {
      opacity: 1
    }
  });
  return (
    <a.div {...rest} style={props}>
      ...
    </a.div>
  );
};
