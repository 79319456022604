import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "fragmentTypes.json";
import fetch from "node-fetch";

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
});

const createClient = (params) => {
    const isClient = typeof window !== "undefined";
    const cache = new InMemoryCache({
        fragmentMatcher,
    })

    if (isClient) {
        cache.restore(window.__APOLLO_STATE__)
    }

    const fetchClt = isClient && typeof window.fetch !== "undefined" ? window.fetch : fetch;

    const httpLink = new HttpLink({
        uri: isClient ?  `/api?pathname=${window.location.pathname}` : `${params && params.baseUrl ? params.baseUrl : "" }/api`,
        credentials: "same-origin",
        fetch: fetchClt
    });

    const errorLink = onError(({ operation, networkError }) => {
        const context = operation.getContext();
        let errText = '';

        if (context && context.response) {
            let suberrContext = '|' + 'ERROR: |' + 'URL: ' + context.response.url + '|' + 'Status: ' + context.response.status + ' - ' + context.response.statusText;
            console.log(suberrContext.replace('|', "\n"));
            errText += suberrContext;
        }

        if (networkError) {
            let suberrNetwork = '|' + 'Body text: ' + networkError.bodyText + '|' + `[Network error]: ${networkError}`;
            if (!networkError.bodyText) {
              suberrNetwork += '|' + 'Seems like it\'s client-side error...';
            }

            console.log(suberrNetwork.replace('|', "\n"));
            errText += suberrNetwork;
        }

        console.log("\n");

        const params = new URLSearchParams();
        params.append('data', errText);

        fetchClt('/report-issue', { method: 'post', body: params });
    });

    const link = errorLink.split((operation) => operation.getContext().version !== '', httpLink);

    return new ApolloClient({
        link,
        headers: {
            "X-Craft-Token": isClient && window.location.search.match(/\btoken=([^&]+)/)
                ? window.location.search.match(/\btoken=([^&]+)/)[1]
                : "",
        },
        cache,
        ssrForceFetchDelay: 100
    })
}

export default createClient