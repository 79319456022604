import { idtype, image32, linkTo } from "querypieces";

export default `
  ...on pageBuilder_waveCta_BlockType {
    ${idtype}
    image: ${image32}
    heading
    plainText
    ${linkTo}
  }
`;
