import React from "react";
import ArrowLink from "components/arrow-link";
import "./program-differences.scss";

const ProgramDifferences = ({ programDifferences, linkTo }) => {
  return (
    <div className="ProgramDifferences">
      <ul className="ProgramDifferences-list">
        {programDifferences.map(({ id, heading, text }) => {
          return (
            <li key={id} className="ProgramDifferencesItem">
              <div className="ProgramDifferencesItem-heading">{heading}</div>
              <div className="ProgramDifferencesItem-text">{text}</div>
            </li>
          );
        })}
      </ul>
      {linkTo.url && (
        <ArrowLink className="ProgramDifferences-link" linkTo={linkTo} />
      )}
    </div>
  );
};

export default ProgramDifferences;
