import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

import "./modal.scss";
import {useApolloClient, useQuery} from "@apollo/react-hooks";
import {useLocation} from "react-router-dom";
import query from "../cookie-modal/query";
import {getLocationLocale} from "../../helpers";
import locales from "locales";

const cookies = new Cookies();

const CookieModal = () => {
    const isClient = typeof window !== "undefined";
    const [show, setShow] = useState(false);

    const client = useApolloClient();

    let params = {
        client: client,
        notifyOnNetworkStatusChange: true
    };

    const locale = getLocationLocale(useLocation());

    if ([locales.FR_CA, locales.HI_IN].indexOf(locale) !== -1) {
        params.context = {
            headers: {"X-GWC-Lang-Header": locale}
        }
    }

    const { data } = useQuery(query, params);

    useEffect(() => {
        if (isClient && !cookies.get("cookie-modal-closed"))  {
            setShow(true);
        }
    }, [isClient]);

    return (
      !!data && (<div className={"CookieModal" + ((!show) ? " hidden" : "")}>
            <div className={"CookieModal-inner"}>
                <div className={"CookieModal-text"} dangerouslySetInnerHTML={{__html: data.cookiesModal.richText}}/>
                <a className={"CookieModal-about-sm"} href={data.cookiesModal.linkTo.url} target={data.cookiesModal.linkTo.target}>{data.cookiesModal.linkTo.customText}</a>
                <button className={"CookieModal-accept"} type="submit" onClick={() => {
                    if (isClient) {
                        setShow(false);
                        cookies.set("cookie-modal-closed", true);
                    }
                }}>{data.cookiesModal.plainText}</button>
            </div>
        </div>)
    );
};

export default CookieModal;
