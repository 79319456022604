import colors from "colors";

export default {
  "Summer Immersion Program": {
    fill: colors.orange3,
    textColor: colors.white
  },
  Club: {
    fill: colors.blue2,
    textColor: colors.white
  },
  "College Loop": {
    fill: colors.yellow3,
    textColor: null
  }
};
