import { idtype, squareImage, linkTo } from "querypieces";

export default `
  ...on pageBuilder_subscribeDownload_BlockType {
    ${idtype}
    label
    heading
    plainText
    ${squareImage}
    subscribeSource
    subscribeTargetAudience
    subscribeTheme
    download: ${linkTo}
    subscribeSuccessHeading
    subscribeSuccessText
    ctaText
  }
`;
