import React, {useEffect, useState} from "react";
import "./dataPrivacyAddendumSection.scss";
import SVG from "react-inlinesvg";
import searchIconSrc from "../../components/clubmap/default/search.svg";
import downloadIconSrc from "./downloadButton.svg";
import Link from "../../components/link";

const dataPrivacyAddendumSection = (block) => {
  return <DataPrivacyAddendumSection block={block}/>
}

const DataPrivacyAddendumSection = ({block}) => {

  const OPTION_IS_STATE_HAS_AGREEMENT = "isenabled";

  const [stateId, setStateId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const data = block.elementsList.filter((item) => item.id === stateId);

    if (data?.length) {
      setSelectedItem(data[0]);
    } else {
      setSelectedItem(null);
    }

  }, [stateId]);

  const selectDictionary = block.elementsList.map((item) => {
    return {id: item.id, value: `${item.itemTitle} (${item.code})`, code: item.code};
  });

  const onStateSelected = (stateId) => {
    setStateId(stateId)
  }

  const downloadItem = block.downloadItem ?? block.download;

  return (
    <div className={"dataPrivacyAddendumSection"} id={block.anchorId} name={block.anchorId}>
      <div className={"dataPrivacyAddendumSection-wrapper"}>
        <div className={"dataPrivacyAddendumSectionHeader"}>
          <h2>{block.heading}</h2>
        </div>
        <div className={"dataPrivacyAddendumSectionContent"} dangerouslySetInnerHTML={{ __html: block.richText }}/>
        <div className={"dataPrivacyAddendumSectionSelect"}>
          <AutocompleteSelect
            placeholder={"Search for your state"}
            dictionary={selectDictionary}
            onItemSelected={onStateSelected}
          />
        </div>

        {(!!selectedItem && selectedItem.options.includes(OPTION_IS_STATE_HAS_AGREEMENT)) && (<DataPrivacyAddendumSectionPositiveResult item={selectedItem}/>)}
        {(!!selectedItem && !selectedItem.options.includes(OPTION_IS_STATE_HAS_AGREEMENT)) && (<DataPrivacyAddendumSectionNegativeResult download={downloadItem} downloadContent={block.secondaryrichtext} emailContent={block.thirdRichText} item={selectedItem}/>)}

      </div>
    </div>
  )
}


export default dataPrivacyAddendumSection

const DataPrivacyAddendumSectionPositiveResult = ({item}) => {
  return (
    <div className={"DataPrivacyAddendumSectionResult"}>
      <h3>{item.itemTitle}</h3>
      <div className={"DataPrivacyAddendumSectionPositiveResultContent"} dangerouslySetInnerHTML={{ __html: item.message }}/>
    </div>
  );
}
const DataPrivacyAddendumSectionNegativeResult = ({item, downloadContent, download, emailContent}) => {

  const formattedDownloadContent = downloadContent.replace('{STATE}', item.itemTitle)
  const formattedEmailContent = emailContent.replace('{STATE}', item.itemTitle)

  return (
    <div className={"DataPrivacyAddendumSectionResult"}>
      <div className={"DataPrivacyAddendumSectionNegativeResultDownloadContent"}>
        <div className={"DataPrivacyAddendumSectionNegativeResultContent"} dangerouslySetInnerHTML={{ __html: formattedDownloadContent }}/>
        {!!download?.length && (
          <div className={"DataPrivacyAddendumSectionNegativeResultContentDownload"}>
            <Link
              url={download[0].url}
              type={"asset"}
              element={download[0]}
              className={`ButtonLink DataPrivacyAddendumSectionNegativeResultButton`}
            >
              <SVG src={downloadIconSrc}/>
              <span>DOWNLOAD DPA</span>
            </Link>
          </div>
        )}
      </div>

      <div className={"DataPrivacyAddendumSectionNegativeResultEmailContent"}>
        <div className={"DataPrivacyAddendumSectionNegativeResultContent"} dangerouslySetInnerHTML={{ __html: formattedEmailContent }}/>
      </div>
    </div>
  );
}

const AutocompleteSelect = ({placeholder, dictionary, onItemSelected}) => {

  const [value, setValue] = useState("");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const filteredDictionary = dictionary.filter((item) => {
      return !!value &&
        (
          (value.trim().length > 0 && item.value.trim().toUpperCase().indexOf(value.trim().toUpperCase()) === 0) ||
          (value.trim().length > 0 && item.code.trim().toUpperCase().indexOf(value.trim().toUpperCase()) === 0)
        );
    });

    setSuggestions(filteredDictionary);
  }, [value]);

  useEffect(() => {
    onItemSelected(selectedItemId);
  }, [selectedItemId]);

  useEffect(() => {
    document.getElementById('autocompleteSelectInput').addEventListener('keydown', function(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        const firstInput = document.getElementById("autocompleteSelect-results")?.querySelector(".AutocompleteSelect-results-dropdown-item a");

        if (firstInput) {
          firstInput.focus();
        }
      }
    });
  }, []);

  const onChange = (event) => {
    setValue(event.target.value);
    setSelectedItemId(null);
  }

  const onItemClick = (id, value) => {
    setSelectedItemId(id);
    setValue(value);
  }

  return (
    <div className="AutocompleteSelect">
      <div className="AutocompleteSelect-input">
        <input id={"autocompleteSelectInput"} placeholder={placeholder} value={value} onChange={onChange}/>
        <SVG src={searchIconSrc} alt="Search"/>
      </div>
      {!selectedItemId && suggestions.length > 0 && (
        <div id={"autocompleteSelect-results"} className="AutocompleteSelect-results">
          <div className="AutocompleteSelect-results-dropdown">
            {suggestions.map(suggestion => {
              return (
                <div key={suggestion.id} className={"AutocompleteSelect-results-dropdown-item"}>
                  <div className="AutocompleteSelect-results-dropdown-item-main"
                       onClick={() => onItemClick(suggestion.id, suggestion.value)}>
                    <a href={"#"} onClick={(event) => {
                      event.preventDefault();
                      onItemClick(suggestion.id, suggestion.value)
                    }
                    }>{suggestion.value}</a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!selectedItemId && (suggestions.length === 0 && value.length !== 0) && (
        <div className="AutocompleteSelect-results">
          <div className="AutocompleteSelect-results-dropdown">
            <div className={"AutocompleteSelect-results-dropdown-item"}>
              <div className="AutocompleteSelect-results-dropdown-item-main">
                Please enter a valid state
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
