import React from "react";
import Subscribe from "components/subscribe";
import useFooter from "./useFooter";
import Link from "components/link";
import "./footer.scss";

const Footer = () => {
  const { data } = useFooter();

  return (
    <footer className="Footer">
      <div className="Footer-inner">
        <Subscribe source="Footer" />
        <div className="Footer-right">
          {data && (
            <>
              <SocialLinks items={data.footer.socialLinks} />
              <FooterLinks items={data.footer.primaryLinks} type="primary" />
              <FooterLinks
                items={data.footer.secondaryLinks}
                type="secondary"
              />
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const SocialLinks = ({ items }) => {
  return (
    <ul className="SocialLinks">
      {items.map(({ id, icon, linkTo }) => {
        const alt = icon[0].altText || icon[0].title;
        return (
          <li key={id}>
            <a href={linkTo} target="_blank" rel="noopener noreferrer">
              <img src={icon[0].url} alt={alt} title={alt} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};

const FooterLinks = ({ items, type = "primary" }) => {
  return (
    <ul className={`FooterLinks FooterLinks--${type}`}>
      {items.map(({ id, linkTo }) => {
        return (
          <li key={id}>
            <Link {...linkTo} />
          </li>
        );
      })}
    </ul>
  );
};
