import React from "react";

const IframePage = ({ entry }) => {
  return (
    <iframe
      title={entry.title}
      src={entry.iframeSrc}
      width="100%"
      height={"100vh"}
    />
  );
};

export default IframePage;
