import React from "react";
import ProgramAttributeList from "components/program-attribute-list";
import Subscribe from "components/subscribe";
import "./program-application-club.scss";

const ProgramApplicationClub = ({
  heading,
  plainText,
  programAttributeList,
  subscribeSource,
  subscribeButtonText,
  subheading,
  subtext
}) => {
  return (
    <div className="ProgramApplicationClub">
      <div className="ProgramApplicationClub-top">
        <div className="ProgramApplicationClub-heading">{heading}</div>
        <div className="ProgramApplicationClub-text">{plainText}</div>
        <Subscribe
          source={subscribeSource}
          buttonText={subscribeButtonText}
          className="ProgramApplicationClub-subscribe"
        />
      </div>
      <div className="ProgramApplicationClub-details">
        <div className="ProgramApplicationClub-details-heading">
          {subheading}
        </div>
        <div className="ProgramApplicationClub-details-text">{subtext}</div>
        <ProgramAttributeList
          items={programAttributeList}
          className="ProgramApplicationClub-details-attributes"
        />
      </div>
    </div>
  );
};

export default ProgramApplicationClub;
