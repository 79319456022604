import React from "react"
import HeadingText from "components/heading-text";
import ArrowLinkBlock from "blocks/arrow-link"
import Image from "blocks/image"
import "./image-link.scss"

const ImageLink = ({
  heading,
  subheading,
  links_children: links,
}) => {
  return <div className="ImageLink-wrapper">
    <HeadingText heading={heading} text={subheading} />
    {links.map(block => {
      return <div key={block.id} className="ImageLink">
        <Image image={block.image} />
        <p className="ImageLink-heading">{block.heading}</p>
        {block.links.map(linkTo => <ArrowLinkBlock key={linkTo.id} {...linkTo} />)}
      </div>
    })}
  </div>
}

export default ImageLink
