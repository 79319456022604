import React from "react";
import useTeams from "./useTeams";
import Loading from "components/loading";
import Error from "components/error";
import WaveUnderline from "components/wave-underline";
import Grid from "components/grid";
import Image from "blocks/image";
import "./teams.scss";

const Teams = ({ children }) => {
  const { loading, error, data } = useTeams();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div className="Teams">
      {children &&
        children.map((block) => {
          return block.typeHandle === "teamType" ? (
            <SelectTeams
              key={block.id}
              teams={data.teamMembers}
              type={block.teamType[0].id}
            />
          ) : (
            <TeamsGroup key={block.id} {...block} />
          );
        })}
    </div>
  );
};

export default Teams;

const SelectTeams = ({ teams, type }) =>
  teams.map((team) => {
    return type === team.id ? (
      <TeamsGroup
        key={team.id}
        heading={team.title}
        teamMember={team.members}
      />
    ) : null;
  });

const TeamsGroup = ({ heading, teamMember }) => {
  return (
    <div className="TeamsGroup">
      <div className="Teams-heading">
        <h2>
          <WaveUnderline>{heading}</WaveUnderline>
        </h2>
      </div>
      <div className="TeamsGroup-list">
        <Grid itemClass="border--teal2">
          {teamMember.map((member) => (
            <TeamsGroupItem key={member.id} {...member} />
          ))}
        </Grid>
      </div>
    </div>
  );
};

const TeamsGroupItem = ({ fullName, attribution, image }) => {
  return (
    <div className="TeamsGroup-list-item">
      <Image image={image} />
      <h3 className="TeamsGroup-list-item-heading">{fullName}</h3>
      <p className="TeamsGroup-list-item-copy">{attribution}</p>
    </div>
  );
};
