import React from "react";
import ArrowLink from "components/arrow-link";
import WaveUnderline from "components/wave-underline";
import Image from "blocks/image";
import "./headingtextlinkimage.scss";
import MultipleLinks from "components/multiple-links";

const HeadingTextLinkImage = ({
  heading,
  subheading,
  plainText,
  linkTo,
  image,
  alignImage,
  links_children: links,
}) => {
  return (
    <div className={`HeadingTextLinkImage ${alignImage}`}>
      <div className="HeadingTextLinkImage-heading">
        {(heading && !subheading) &&
          <WaveUnderline>{heading}</WaveUnderline>
        }
        {(heading && subheading) &&
          <div>
              <span>{heading}</span>
              <WaveUnderline className="subHeader">{subheading}</WaveUnderline>
          </div>
        }
      </div>
      <p className="HeadingTextLinkImage-text">{plainText}</p>
      <div className="HeadingTextLinkImage-link">
        {links.map((link) => {
          switch (link.typeHandle) {
            case "arrowLink":
              return <ArrowLink key={link.id} linkTo={link.linkTo} />;
            case "multipleLinks":
              return <MultipleLinks {...link} key={link.id} />;
            default:
              throw new Error(`unknown block type ${link.typeHandle}`);
          }
        })}
      </div>
      <div className="HeadingTextLinkImage-image">
        <Image image={image} />
      </div>
    </div>
  );
};

export default HeadingTextLinkImage;
