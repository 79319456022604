import React from "react";
import ArrowLink from "components/arrow-link";
import ImpactItems from "blocks/impact-items";
import HeadingText from "components/heading-text";
import "./impact.scss";

const Impact = ({ heading, plainText, impactItems, linkTo }) => {
  return (
    <div className="Impact">
      <HeadingText heading={heading} text={plainText} />
      <ImpactItems impactItems={impactItems} />
      <ArrowLink className="Impact-link" linkTo={linkTo} />
    </div>
  );
};

export default Impact;
