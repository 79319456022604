import React from "react";
import "./loading.scss";

const Loading = () => {
  return (
    <div
      className="Loading"
      style={{
        display: "flex",
        justifyContent: "center",
        padding: "1em",
        animation: "pulse 1s infinite alternate",
      }}
    >
      <img
        src={"/g.png"}
        style={{ width: "50px", height: "100%", margin: "0 auto" }}
        alt="loading"
      />
    </div>
  );
};

export default Loading;
