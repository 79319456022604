import React from "react"
import "./grid.scss"

const Grid = ({children, itemClass="", gridClass = ""}) => {
  const gridClassName = !!gridClass ? gridClass : "Grid";
  return <ul className={gridClassName}>
    {children.map(Comp =>
      <li key={Comp.key} className={`Grid-item ${itemClass}`}>
       {Comp}
     </li>
    )}
  </ul>
}

export default Grid
