import React from "react";
import "./recent-news.scss";
import ArrowLink from "components/arrow-link";
import WaveUnderline from "components/wave-underline";
import useMoreNews from "components/more-news/useMoreNews";
import NewsList from "components/news-list";

const RecentNews = ({ heading, label, linkTo }) => {
  const { data } = useMoreNews();

  return (
    <div className="RecentNews">
      <div className="RecentNews-label">{label}</div>
      <div className="RecentNews-heading">
        <WaveUnderline>{heading}</WaveUnderline>
      </div>
      <div className="RecentNews-items">
        {data && <NewsList items={data.entries} />}
      </div>
      <div className="RecentNews-link">
        <ArrowLink linkTo={linkTo} />
      </div>
    </div>
  );
};

export default RecentNews;
