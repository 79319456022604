import React from "react";
import ProgramValuesItem from "./program-values-item";
import "./program-values.scss";

const ProgramValues = ({ programValues, className = "", style }) => {
  return (
    <div className={`ProgramValues ${className}`} style={style}>
      <div className="ProgramValues-inner">
        {programValues.map(({ id, heading, plainText }) => (
          <ProgramValuesItem key={id} heading={heading} plainText={plainText} />
        ))}
      </div>
    </div>
  );
};

export default ProgramValues;
