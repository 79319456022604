import React from "react";
import WaveUnderline from "components/wave-underline";

import "./heading-text.scss";

const HeadingText = ({ heading, text }) => {
  return (
    <div className="HeadingText">
      <div className="HeadingText-heading">
        {" "}
        <WaveUnderline>{heading}</WaveUnderline>
      </div>
      <div className="HeadingText-text">{text}</div>
    </div>
  );
};

export default HeadingText;
