import React from "react";
import Image from "components/image";
import "./campaign-pullquote.scss";
import squiggleSrc from "images/squiggle.svg";
import Attribution from "components/attribution";
import SVG from "react-inlinesvg";

const CampaignPullquote = ({ plainText, attribution, image }) => {
  return (
    <div className="CampaignPullquote">
      <SVG
        className="CampaignPullquote-bg lazyload"
        data-src={squiggleSrc}
        alt=""
      />
      <div className="CampaignPullquote-inner">
        <Image className="CampaignPullquote-image" {...image[0]} />
        <div className="CampaignPullquote-text">{plainText}</div>
        <div className="CampaignPullquote-attribution">
          <Attribution>{attribution}</Attribution>
        </div>
      </div>
    </div>
  );
};

export default CampaignPullquote;
