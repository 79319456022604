import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import Nav from "components/nav";
import Page from "components/page";
import ClubMap from "components/clubmap";
import CookieModal from "components/cookie-modal";
import TextWaveSandbox from "components/text-wave-sandbox";
import Loading from "components/loading/page-loading";
import Footer from "components/footer";
import ScrollMemory from "react-router-scroll-memory";
import ReactGA from "react-ga";
import TagManager from 'react-gtm-module'
import clipWave from "images/image_clip_wave.svg";
import SVG from "react-inlinesvg";
import WaveUnderline from "components/wave-underline";
import {
  AlertContext,
  useAlertContext,
} from "components/alert-bar/useAlertContext";
import { useEffect } from "react";
import { hotjar } from 'react-hotjar';
import Error from "components/error";
import { ResizeObserver as Polyfill } from "@juggle/resize-observer";
import "lazysizes";
import {PAGE_TYPE, PAGE_TYPEHANDLE} from "../../pageTypes";
import usePageOptions from "../../hooks/usePageOptions";
import {parseUrlForGraphQL} from "../../helpers";

const GOOGLE_ANALYTICS_KEY = "UA-39388126-1";
const GTM_ID = "GTM-PCS7NHG";
//"UA-101196144-3" test key

if (typeof window !== "undefined") {
  window.ResizeObserver = window.ResizeObserver || Polyfill;
}

ReactGA.initialize(GOOGLE_ANALYTICS_KEY, { debug: !!module.hot });
if (typeof window !== "undefined") {
  ReactGA.pageview(window.location.pathname + window.location.search);
}

if (typeof document !== "undefined") {
    hotjar.initialize(971425, 6);
}

if (typeof document !== "undefined") {
  TagManager.initialize({gtmId: GTM_ID});
}

const App = () => {
  const history = useHistory();
  useEffect(() => {
    history.listen((location, action) => {
      ReactGA.pageview(location.pathname + location.search);
      document.activeElement.blur();
    });
  }, [history]);

  const url =  (typeof document !== "undefined") ? document.location.pathname : "";
  const {data} = usePageOptions(parseUrlForGraphQL(url));

  const isPrivacyDataCenter =  data?.entry?.typeHandle === PAGE_TYPEHANDLE.PRIVACY_CENTER;
  const hasBackground = data?.entry?.ismainnavbackgrounded ?? false;
  const isSmoothScrollDisabled = data?.entry?.isSmoothScrollDisabled ?? false;
  const showFooter =  data?.entry?.showFooter ?? true;
  const showCookieModal =   data?.entry?.showCookieModal ?? true;

  return (
    <>
      <ScrollMemory />
      {!isPrivacyDataCenter
        ? <Routes
          isSmoothScrollDisabled={isSmoothScrollDisabled}
          hasBackground={hasBackground}
          showFooter={showFooter}
          showCookieModal={showCookieModal}
        />
        : <PrivacyCenterRoutes/>}
    </>
  );
};

const PrivacyCenterRoutes = () => {
  return (
    <div className={`AppPrivacyCenter`}>
      <SVG src={clipWave} className={"AppWaveSvg"} style={{ position: "absolute" }} />
      <Nav navType={PAGE_TYPE.PRIVACY_CENTER} />
      <Switch>
        <Route path={"/error"} component={Error} />
        <Route path={"/loading"} component={Loading} />
        <Route path={"/clubs-privacy-center"} component={Page} />
      </Switch>
    </div>
  );
}

const Routes = ({hasBackground = false, showFooter= true, showCookieModal= true, isSmoothScrollDisabled = false}) => {
  const alertContext = useAlertContext();
  const isSmoothScrollDisabledClassName = !!isSmoothScrollDisabled ? "SmoothScrollDisabled" : "";
  return (
    <AlertContext.Provider value={alertContext}>
      <div className={`App ${isSmoothScrollDisabledClassName}`}>
        <SVG src={clipWave} className={"AppWaveSvg"} style={{ position: "absolute" }} />
        <Nav navType={PAGE_TYPE.PAGE} hasBackground={hasBackground}/>
        <Switch>
          <Route path={"/error"} component={Error} />
          <Route path={"/clubmap"} component={ClubMap} />
          <Route path={"/loading"} component={Loading} />
          <Route path={"/wave-maker"} component={TextWaveSandbox} />
          <Route
            path={"/text-wave"}
            render={() => (
              <WaveUnderline className="HeadingText-heading">
                This heading here
              </WaveUnderline>
            )}
          />
          <Route path={"/news/:slug"} component={Page} />
          <Route path={"/:parent/:slug"} component={Page} />
          <Route path={"/:slug?"} component={Page} />
        </Switch>
        {showFooter && <Footer />}
        {showCookieModal && <CookieModal />}
      </div>
    </AlertContext.Provider>
  );
};

export default App;
