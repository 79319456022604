import React from "react";
import "./partner-list.scss";
import usePartnerList from "./usePartnerList";
import Loading from "components/loading";
import Error from "components/error";
import WaveUnderline from "components/wave-underline";

const PartnerList = ({ heading }) => {
  const { loading, error, data } = usePartnerList();

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  return (
    <div className="PartnerList">
      <div className="PartnerList-heading">
        <WaveUnderline>{heading}</WaveUnderline>
      </div>
      <ul className="PartnerList-key">
        {data.partnerTypes.map(({ id, title, color }) => (
          <li className="PartnerList-key-item" key={id}>
            <div
              className="PartnerList-key-item-color"
              style={{ backgroundColor: color }}
            />
            {title}
          </li>
        ))}
      </ul>
      <div className="PartnerList-list">
        {data.partnersByFundingLevel.map((fundingLevel) => {
          return <PartnerListGroup key={fundingLevel.id} {...fundingLevel} />;
        })}
      </div>
    </div>
  );
};

export default PartnerList;

const PartnerListGroup = ({ title, partners }) => {
  return (
    <div className="PartnerListGroup">
      <div className="PartnerListGroup-heading">{title}</div>
      <ul className="PartnerListGroup-list">
        {partners.map((partner) => (
          <PartnerListGroupItem key={partner.id} {...partner} />
        ))}
      </ul>
    </div>
  );
};

const PartnerListGroupItem = ({ title, partnerTypes }) => {
  return (
    <li className="PartnerListGroup-list-item">
      <div className="PartnerListGroup-list-item-heading">{title}</div>
      <ul className="PartnerListGroup-list-item-types">
        {partnerTypes.map(({ id, title, color }) => (
          <li
            className="PartnerListGroup-list-item-types-item"
            key={id}
            title={title}
          >
            <div
              className="PartnerListGroup-list-item-types-item-color"
              style={{ backgroundColor: color }}
            />
          </li>
        ))}
      </ul>
    </li>
  );
};
