import React from "react";

const Image = ({
  optimized,
  className = "",
  altText,
  title,
  url,
  __typename,
  ...rest
}) => {
  const { placeholder, srcset, srcsetWebp } = optimized;

  const alt = altText || title;
  return (
    <picture className={className}>
      <source data-srcset={srcsetWebp} sizes="100vw" type="image/webp" />
      <img
        alt={alt}
        src={placeholder}
        data-src={url}
        data-srcset={srcset}
        className={`Image lazyload`}
        {...rest}
      />
    </picture>
  );
};

export default Image;
