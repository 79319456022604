import { idtype } from "querypieces";

export default `
  ...on pageBuilder_gridBlock_BlockType {
    ${idtype}
    backgroundColor
    note
    children {
      ...on pageBuilder_item_BlockType {
        ${idtype}
        heading
        subheading
        plainText
      }
    }
  }
`
