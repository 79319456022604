import React from "react";
export default ({ color = "#43D6B9", className = "", style = {} }) => (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <g transform="matrix(-1 0 0 1 20 -10)" fill="none">
      <circle cx="17" cy="17" r="17" />
      <path stroke={color} strokeWidth="2" d="M19.2 11L13 17.2l6.2 6.3" />
    </g>
  </svg>
);
