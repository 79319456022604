export const sortAlphaProp = (list, prop) => {
  return list.sort((a, b) => {
    const aVal = a[prop].toUpperCase();
    const bVal = b[prop].toUpperCase();
    return bVal > aVal ? -1 : bVal < aVal ? 1 : 0;
  });
};

export const calculateRadDistance = (latFrom, lonFrom, latTo, lonTo) => {
    const radLatFrom = Math.PI * latFrom/180;
    const radLatTo = Math.PI * latTo/180;
    let distance = Math.sin(radLatFrom) * Math.sin(radLatTo) + Math.cos(radLatFrom) * Math.cos(radLatTo) * Math.cos(Math.PI * (lonFrom-lonTo)/180);
    return (Math.acos(distance) * (180/Math.PI)) * 60 * 1.1515 * 1.609344;
};

export const sortDistancesArray = (data, props) => data.sort((a, b) => {
    if (!props || !props.center || !a || !b) return false;

    const elemA = calculateRadDistance(props.center.lat, props.center.lng, a.lat, a.lng);
    const elemB = calculateRadDistance(props.center.lat, props.center.lng, b.lat, b.lng);
    if ( elemA > elemB ) return 1;
    if ( elemA < elemB ) return -1;
    return 0;
});

export const popByRegion = (data, region) => {
    const needed = data.filter((el) => ((el.address.state ? el.address.state.toLowerCase() : '') === region));
    const unneeded = data.filter((el) => ((el.address.state ? el.address.state.toLowerCase() : '') !== region));
    return needed.concat(unneeded);
};

export const getLocationLocale = (location) => {
  let uri = location.pathname.replace("/", "");

  if (uri.slice(-1) === '/') {
    uri = uri.substring(0, uri.length - 1);
  }

  return uri;
};

export const parseUrlForGraphQL = (url) => {
  let result = url.replace("/", "");

  if (result.slice(-1) === '/') {
    result = url.substring(0, url.length - 1);
  }

  return result;
}