import React, { useState } from "react";
import "./nation-header.scss";
import Image from "components/image";
import TextWave from "components/text-wave";
import WaveUnderline from "components/wave-underline";
import ProgramAttributeList from "components/program-attribute-list";
import ProgramValues from "components/program-values";
import ArrowLink from "components/arrow-link";
import { useEffect } from "react";
import Carrot from "components/accordionitem/carrot";
import colors from "colors";
import {useLocation} from "react-router-dom";

const NationHeader = ({
  image1,
  label,
  heading,
  statement,
  linkTo,
  subheading,
  subtext,
  programAttributeList,
  image2,
  programValues,
  languages,
}) => {
  return (
    <div className="NationHeader space-for-nav">
      <div className="NationHeader-inner">
        <Image className="NationHeader-image1" {...image1[0]} />
        <div className="NationHeader-label">
          <div className="NationHeader-label-text">{label}</div>
          {languages && <LanguageSelector items={languages} />}
        </div>
        <h1 className="NationHeader-heading">
          <TextWave text={heading} />
        </h1>
        <div className="NationHeader-statement">{statement}</div>
        <div className="NationHeader-link">
          <ArrowLink linkTo={linkTo} />
        </div>
        <div className="NationHeader-subheading">
          <WaveUnderline>{subheading}</WaveUnderline>
        </div>
        <div className="NationHeader-subtext">{subtext}</div>
        {programAttributeList.length > 0 && (
          <div className="NationHeader-attributes">
            <ProgramAttributeList items={programAttributeList} />
          </div>
        )}
        <Image className="NationHeader-image2" {...image2[0]} />
        <ProgramValues
          className="NationHeader-values"
          programValues={programValues}
        />
      </div>
    </div>
  );
};

export default NationHeader;

const LanguageSelector = ({ items }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const current = items.find(({ uri }) => uri === location.pathname);
  const remaining = items.filter(({ uri }) => uri !== location.pathname);

  useEffect(() => {
    const onClick = () => {
      setOpen(false);
    };

    if (window) {
      window.addEventListener("click", onClick);
      return () => window.removeEventListener("click", onClick);
    }
  }, []);

  if (current) {
    return (
      <div className="LanguageSelector">
        <button
          className="LanguageSelector-toggle"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          {current.label}{" "}
          <Carrot color={colors.blue4} style={{ transform: "rotate(90deg)" }} />
        </button>
        {open && (
          <ul className="LanguageSelector-dropdown">
            {remaining.map(({ label, uri }) => (
              <li key={uri}>
                <a href={uri}>{label}</a>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
  
  return null;
};
