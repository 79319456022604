import { idtype, linkTo, image, buttonLink } from "querypieces";

export default `
  ...on pageBuilder_headingTextLinkImage_BlockType {
    ${idtype}
    heading
    subheading
    plainText
    ${linkTo}
    ${image}
    alignImage
    links_children: children {
      ...on pageBuilder_arrowLink_BlockType {
        ${idtype}
        ${linkTo}
        linkType
        position
      }
      ...on pageBuilder_multipleLinks_BlockType {
        ${idtype}
        ${buttonLink}
        secondaryLinks {
          ...on secondaryLinks_item_BlockType {
            ${idtype}
            ${linkTo}
          }
        }
      }
    }
  }
`;
