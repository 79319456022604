import { idtype, linkTo } from "querypieces";

export default `
  ...on pageBuilder_campaignCta_BlockType {
    ${idtype}
    image: image54 {
      ... on images_Asset {
        optimized: optimized54 {
          placeholder: placeholderImage
          srcset
        }
      }
    }
    heading
    plainText
    ${linkTo}
  }
  ...on pageBuilder_campaign_BlockType {
    ${idtype}
    image {
      ... on images_Asset {
        optimized: square {
          placeholder: placeholderImage
          srcset
        }
      }
    }
    video {
      ...on images_Asset {
        url,
        title
      }
    }
    heading
    plainText
    ${linkTo}
    alignImage
    theme
  }
`;
