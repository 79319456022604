import React from "react";
import { a, useSpring } from "react-spring";
import Link from "components/link";
import ArrowWave from "components/arrow-wave";
import useArrowWave from "components/arrow-wave/useArrowWave";
import colors from "colors";
import useTheme from "blocks/theme/useTheme";
import "./arrow-link.scss";

const ArrowLink = ({
  linkTo,
  className = "",
  colorOverride,
  activeColorOverride,
}) => {
  const theme = useTheme();
  const activeColor = activeColorOverride ? activeColorOverride : colors.teal3;
  const color = colorOverride ? colorOverride : theme.accent || colors.teal3;

  const { active, handlers } = useArrowWave();
  const [props, set] = useSpring(() => ({
    color,
  }));
  set({
    color: active ? activeColor : color,
  });

  if (!linkTo.url) {
    return null;
  }

  return (
    <Link {...linkTo} {...handlers} className={`ArrowLink ${className}`}>
      <a.div style={props}>
        <span>{linkTo.text}</span>{" "}
        <ArrowWave active={active} fill={props.color} />
      </a.div>
    </Link>
  );
};

export default ArrowLink;
