export const idtype = `
  id
  typeHandle
`;

export const link = `
  url
  text
  type
  element {
    id
    uri
    title
  }
`;

export const linkTo = `
linkTo {
  ${link}
}
`;

export const secondaryLink = `
secondaryLink {
  ${link}
}
`;

export const buttonLink = `
buttonLink {
  ${link}
}
`;

export const imageAsset = `
  ... on images_Asset {
    optimized: optimizedImage {
      placeholder: placeholderImage
      srcset
      srcsetWebp
      focalPoint
    }
    altText
    title
    url
    id
  }
`;

export const image = `
  image {
    ${imageAsset}
  }
`;

export const image32 = `
  image32 {
    ...on images_Asset {
      optimized: optimized32 {
        placeholder: placeholderImage
        srcset
        srcsetWebp
      }
      altText
      title
      url
    }
  }
`;
export const image54 = `
  image54 {
    ...on images_Asset {
      optimized: optimized54 {
        placeholder: placeholderImage
        srcset
        srcsetWebp
      }
      altText
      title
      url
    }
  }
`;

export const image169 = `
  image169 {
    ...on images_Asset {
      optimized: optimized169 {
        placeholder: placeholderImage
        srcset
        srcsetWebp
      }
      altText
      title
      url
    }
  }
`;

export const squareImage = `
  image {
    ... on images_Asset {
      optimized: square {
        placeholder: placeholderImage
        srcset
        srcsetWebp
      }
      altText
      title
      url
    }
  }
`;

export const images = `
  images {
    ${imageAsset}
  }
`;

export const svgIcon = `
svgIcon {
  url
  ...on images_Asset {
    optimized: optimizedImage {
      placeholder: placeholderBox(color: "transparent")
    }
  }
}
`;

export const programAttributeList = `
  programAttributeList {
    ...on programAttributeList_programAttribute_BlockType {
      ${idtype}
      heading
      more
    }
  }
`;

export const headingTextItems = `
  headingTextItems {
    ...on headingTextItems_item_BlockType {
      ${idtype}
      heading
      text
    }
  }
`;

export const teamMember = `
  ...on team_team_Entry {
    id
    fullName: title
    firstName
    lastName
    ${image}
    attribution
    teamType {
      id
      title
    }
  }
`;
