import React, { useState, useRef } from "react";
import ArrowLink from "components/arrow-link";
import { Waypoint } from "react-waypoint";
import { useSpring, a } from "react-spring";
import useIsMobile from "hooks/useIsMobile";
import colors from "colors";
import "./page-alert.scss";

const transform = `256px`;

const PageAlert = ({ heading, plainText, svgIcon, linkTo }) => {
  const ref = useRef();
  const [collapse, setCollapse] = useState(false);
  const mobile = useIsMobile();
  let offset =
    ref.current && !mobile ? -ref.current.getBoundingClientRect().height : 0;

  const [style, set] = useSpring(() => ({
    opacity: 0,
    transform: `translate(${collapse ? transform : `0px`}, 100%)`,
    marginTop: offset,
  }));

  return (
    <Waypoint
      onEnter={() => {
        set({
          opacity: 0.9,
          transform: `translate(${collapse ? transform : `0px`}, 0%)`,
          marginTop: offset,
        });
      }}
      onLeave={() => {
        set({
          opacity: 0,
          transform: `translate(${collapse ? transform : `0px`}, 100%)`,
          marginTop: offset,
        });
      }}
      // topOffset={200}
      // bottomOffset={200}
    >
      <a.aside className={"PageAlert"} style={style} ref={ref}>
        <button
          className={`PageAlert-toggle`}
          onClick={() => {
            setCollapse((curr) => !curr);
            set({
              transform: `translate(${!collapse ? transform : `0px`}, 0)`,
            });
          }}
        >
          <span className={collapse ? "switch" : ""} />
        </button>
        <img
          src={svgIcon[0].optimized.placeholder}
          data-src={svgIcon[0].url}
          alt="page alert icon"
          className="PageAlert-icon lazyload"
        />
        <div className="PageAlert-heading">{heading}</div>
        <p className="PageAlert-body">{plainText}</p>
        <div className="PageAlert-link">
          <ArrowLink linkTo={linkTo} colorOverride={colors.white} />
        </div>
      </a.aside>
    </Waypoint>
  );
};

export default PageAlert;
