import { idtype, linkTo, squareImage } from "querypieces";

export default `
  ...on pageBuilder_profileCta_BlockType {
    ${idtype}
    ${squareImage}
    quote
    attribution
    ${linkTo}
  }
`;
