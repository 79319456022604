import React from "react";
import ProgramQuestions from "./program-questions";
import "./program-application-college-loop.scss";

const ProgramApplicationCollegeLoop = ({
  heading,
  plainText,
  programQuestions
}) => {
  return (
    <div className="ProgramApplicationLoop">
      <div className="ProgramApplicationClub-top">
        <div className="ProgramApplicationClub-heading">{heading}</div>
        <div className="ProgramApplicationClub-text">{plainText}</div>
      </div>
      <div className="ProgramApplicationClub-details">
        <ProgramQuestions items={programQuestions} />
      </div>
    </div>
  );
};

export default ProgramApplicationCollegeLoop;
