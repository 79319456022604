import { idtype } from "querypieces";

export default `
  ...on pageBuilder_headingStatementText_BlockType {
    ${idtype}
    heading
    statement
    richText
  }
`;
