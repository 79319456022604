import { gql } from "apollo-boost";
import { idtype } from "querypieces";

export default gql`
  query {
    globalSet (handle: "mainNav") {
      ...on mainNav_GlobalSet {
        id
        navBuilder {
          ...on navBuilder_entry_BlockType {
            ${idtype}
            entry {
              id
              uri
              title
              children {
                id
                uri
                title
              }
            }
          }
          ...on navBuilder_globalDonate_BlockType {
            ${idtype}
          }
        }
      }
    }
  }
`;
