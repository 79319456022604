import React from "react";
import ButtonLink from "components/button-link";
import "./international-links.scss";

const InternationalLinks = ({ items }) => {
  return (
    <div className="InternationalLinks">
      {items.map(({ id, countryName, linkTo }) => {
        return (
          <div key={id} className="InternationalLinks-item">
            <div className="InternationalLinks-item-link">
              <ButtonLink linkTo={linkTo} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InternationalLinks;
