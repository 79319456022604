import React from "react";
import Image from "components/image";
import VideoEmbed from "components/video-embed";
import ButtonLink from "components/button-link";
import ArrowLink from "components/arrow-link";
import "./program-application.scss";
import programApplicationClub from "./program-application-club";
import programApplicationCollegeLoop from "./program-application-college-loop";
import programApplicationSip from "./program-application-sip";

const innerBlocks = {
  programApplicationClub,
  programApplicationCollegeLoop,
  programApplicationSip,
};

const ProgramApplication = ({ photoOrVideo, buttonLink, linkTo, children }) => {
  return (
    <div className="ProgramApplication">
      {photoOrVideo.length > 0 && (
        <ProgramApplicationImage {...photoOrVideo[0]} />
      )}
      <div className="ProgramApplication-body">
        <div className="ProgramApplication-body-inner">
          {children[0] && innerBlocks[children[0].typeHandle](children[0])}
          {(buttonLink.url || linkTo.url) && (
            <div className="ProgramApplication-body-links">
              {buttonLink.url && <ButtonLink linkTo={buttonLink} />}
              {linkTo.url && <ArrowLink linkTo={linkTo} />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgramApplication;

const ProgramApplicationImage = ({ typeHandle, image, youtubeLink }) => {
  return (
    <div className="ProgramApplication-image">
      {typeHandle === "photo" ? (
        <Image {...image[0]} />
      ) : typeHandle === "video" ? (
        <VideoEmbed youtubeLink={youtubeLink} />
      ) : null}
    </div>
  );
};
