import { idtype, imageAsset } from "querypieces";

export const privacyCenterYourDataContentBlock = `
    ${idtype}
    heading
    anchorId
`;

export const privacyCenterYourDataContentBlockItem = `
    ${idtype}
                image {
         ${imageAsset}
                }
              richText
          heading
`;
