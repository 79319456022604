import { idtype } from "querypieces";

export default `
  ...on pageBuilder_infographic_BlockType {
    ${idtype}
    backgroundColor
    xAxis: heading
    yAxis: label
    dataBlocks: children {
      ...on pageBuilder_dataValue_BlockType {
        heading
        value: year
      }
    }
  }
`
