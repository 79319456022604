import React from "react";
import ArrowLink from "components/arrow-link";
import "./ways-to-get-involved.scss";

const WaysToGetInvolved = ({ waysToGetInvolved }) => {
  return (
    <ul className="WaysToGetInvolved">
      {waysToGetInvolved.map(
        ({
          id,
          heading,
          overview,
          commitmentLevel,
          commitmentMore,
          linkTo
        }) => {
          return (
            <li key={id} className="WaysToGetInvolved-item">
              <div className="WaysToGetInvolved-item-heading">{heading}</div>
              <div className="WaysToGetInvolved-item-overview">{overview}</div>
              <div className="WaysToGetInvolved-item-commitmentlevel">
                {commitmentLevel}
              </div>
              <div className="WaysToGetInvolved-item-commitmentmore">
                {commitmentMore}
              </div>
              <div className="WaysToGetInvolved-item-link">
                <ArrowLink linkTo={linkTo} />
              </div>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default WaysToGetInvolved;
