import React from "react";
import ReactPlayer from "react-player"
import ArrowLink from "components/arrow-link";
import WaveUnderline from "components/wave-underline";
import ReactGA from "react-ga";
import WaveBackground from "images/wavebg.svg";
import WaveBackgroundMobile from "images/wavebg_mobile.svg";
import SVG from "react-inlinesvg";
import "./video-waved.scss";

const VideoWaved = ({ heading, plainText, youtubeLink, linkTo }) => {
    return (
        <div className="VideoWaved">
            <div className="VideoWaved-inner">
                <div className="VideoWaved-wave-desktop">
                    <SVG src={WaveBackground} alt="" />
                </div>
                <div className="VideoWaved-inner-inner">
                    <div className="VideoWaved-prevideo">
                        <div className="VideoWaved-wave-mobile">
                            <SVG src={WaveBackgroundMobile} alt="" />
                        </div>

                        <ReactPlayer
                            className="VideoWaved-video"
                            url={youtubeLink}
                            width="100%"
                            height="0"
                            onStart={() => {
                                ReactGA.event({
                                    category: "Video",
                                    action: "play",
                                    label: youtubeLink
                                });
                            }}
                        />
                    </div>
                    <div className="VideoWaved-text">
                        <div className="VideoWaved-text-bg" />
                        {heading &&
                        <div className="VideoWaved-title">
                            <WaveUnderline>{heading}</WaveUnderline>
                        </div>
                        }
                        <p className="VideoWaved-innertext">{plainText}</p>
                        {(linkTo && linkTo.url) && (
                            <ArrowLink className="VideoWaved-link" linkTo={linkTo} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoWaved;
