import React from "react";
import colors from "colors";
import "./dynamic-wave-line.scss";
import useMeasure from "react-use-measure";

const DynamicWaveLine = ({ stroke = colors.blue3, vertical = false }) => {
  const [ref, bounds] = useMeasure();
  const { width: boxWidth, height: boxHeight } = bounds;
  const height = Math.max(boxHeight, 13);
  const width = boxWidth;

  const svgProps = vertical
    ? {
        width,
      }
    : {
        height,
      };

  return (
    <div className="DynamicWaveLine" ref={ref}>
      {width && height ? (
        <svg viewBox={`0 0 ${width} ${height}`} {...svgProps}>
          <path
            fill="none"
            stroke={stroke}
            strokeWidth={2}
            d={makePath(width, height, vertical)}
          />
        </svg>
      ) : null}
    </div>
  );
};

const makePath = (width, height, vertical) => {
  const period = 19;

  if (vertical) {
    const segments = Math.ceil(height / period);
    return `M${width / 2},0 ${new Array(segments)
      .fill(null)
      .map((d, i) => {
        const odd = i % 2 === 1;
        return `C${odd ? 0 : width},${period * (i + 0.25)}
        ${odd ? 0 : width},${period * (i + 0.75)} 
        ${width / 2},${period * (i + 1)} `;
      })
      .join("")}`;
  } else {
    const segments = Math.ceil(width / period);
    return `M0,${height / 2} ${new Array(segments)
      .fill(null)
      .map((d, i) => {
        const odd = i % 2 === 1;
        return `C${period * (i + 0.25)},${odd ? 0 : height} 
        ${period * (i + 0.75)},${odd ? 0 : height} 
        ${period * (i + 1)},${height / 2}`;
      })
      .join("")}`;
  }
};

export default DynamicWaveLine;
