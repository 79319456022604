import { idtype, image } from "querypieces";

export default `
  ...on pageBuilder_annualReports_BlockType {
    ${idtype}
    heading
    children {
      ...on pageBuilder_annualReport_BlockType {
        id
        year
        ${image}
      }
    }
  }
`
