import { gql } from "apollo-boost";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";

const query = gql`
  query {
    links: globalSet(handle: "globalLinks") {
      ... on globalLinks_GlobalSet {
        name
        donateUrl
        donateText
        ctaText
      }
    }
  }
`;


const useGlobals = () => {
    const client = useApolloClient();
    const location = useLocation();
    let uri = location.pathname.replace("/", "");

    if (uri.slice(-1) === '/') {
        uri = uri.substring(0, uri.length - 1);
    }

    let params = {
        client: client,
        notifyOnNetworkStatusChange: true
    };

    if (uri === "fr-ca" || uri === "hi-in") {
        params.context = {
            headers: { "X-GWC-Lang-Header": uri }
        }
    }

    return useQuery(query, params);
};

export default useGlobals;
