import React, { useState } from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import Link from "components/link";
import { useSpring, a } from "react-spring";
import useNav from "./useNav";
import logo from "images/girls_who_code.svg";
import dataPrivacyCenterLogo from "images/girls_who_code.svg";
import SVG from "react-inlinesvg";
import { useEffect, useRef } from "react";
import AlertBar from "components/alert-bar";
import useGlobals from "hooks/useGlobals";
import Subscribe from "components/subscribe";
import MobileNav from "./mobile-nav";
import "./nav.scss";
import {PAGE_TYPE} from "../../pageTypes";

const Nav = ({navType, hasBackground}) => {
  const {
    res,
    active,
    hoverHandlers,
    subscribeActive,
    setSubscribeActive,
    isMobile,
    scrolled,
    setHeight,
    bgProps,
    navRef,
  } = useNav();

  const { loading, error, data } = res;
  const [navHovered, setNavHovered] = useState(false);
  const location = useLocation();
  let uri = location.pathname.replace("/", "");

  if (uri.slice(-1) === '/') {
      uri = uri.substring(0, uri.length - 1);
  }

  let navClass = (uri === "fr-ca") ? 'Nav-inner fr-page' : 'Nav-inner';
  let navBackgroundedClass = (hasBackground || navType === PAGE_TYPE.PRIVACY_CENTER) ? 'Nav-backgrounded' : '';

  return (
    <nav
      ref={navRef}
      className={`Nav ${navBackgroundedClass} ${active ? "Nav--active" : ""} ${
        scrolled || navHovered ? "Nav--scrolled" : ""
      }`}
      onMouseEnter={() => setNavHovered(true)}
      onMouseLeave={() => setNavHovered(false)}
    >
      <AlertBar />
      <div className="Nav-wrapper">
        <a.div className="Nav-openbg" style={bgProps} />
        <div className={navClass}>
          <RouterLink to="/" className="Nav-logo" aria-label="Link to home">
            <SVG src={navType === PAGE_TYPE.PRIVACY_CENTER ? dataPrivacyCenterLogo :logo} />
          </RouterLink>
          {loading || error || isMobile === undefined ? null : isMobile ? (
            <MobileNav
              items={data.globalSet.navBuilder}
              hoverHandlers={hoverHandlers}
              active={active}
              subscribeActive={subscribeActive}
              setSubscribeActive={setSubscribeActive}
              navType={navType}
            />
          ) : (
            <NavDesktop
              items={data.globalSet.navBuilder}
              hoverHandlers={hoverHandlers}
              active={active}
              setHeight={setHeight}
              subscribeActive={subscribeActive}
              setSubscribeActive={setSubscribeActive}
              navType={navType}
            />
          )}
        </div>
      </div>
      <div style={{ position: "absolute", top: "100%" }} />
    </nav>
  );
};

export default Nav;

const NavDesktop = ({
  items,
  hoverHandlers,
  active,
  setHeight,
  subscribeActive,
  setSubscribeActive,
}) => {
  return (
    <>
      <ul
        className="DesktopNav Nav-links"
        style={{ visibility: subscribeActive ? "hidden" : "" }}
      >
        {items.map(({ id, typeHandle, entry }) => {
          const handlers = entry
            ? hoverHandlers(id, entry[0].children.length > 0)
            : {};
          return (
            <li
              key={id}
              className={`Nav-links-item ${
                active && active !== id ? "Nav-links-item--inactive" : ""
              }`}
              {...handlers}
            >
              <NavItem
                key={id}
                entry={entry}
                typeHandle={typeHandle}
                isActive={active === id}
                setHeight={setHeight}
              />
            </li>
          );
        })}
      </ul>
      <NavSubscribeToggle
        active={subscribeActive}
        setActive={setSubscribeActive}
      />

      {subscribeActive && (
        <NavSubscribe
          setHeight={setHeight}
          setSubscribeActive={setSubscribeActive}
        />
      )}
    </>
  );
};

const NavSubscribe = ({ setHeight, setSubscribeActive }) => {
  const ref = useRef();

  const props = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    delay: 200,
  });

  useEffect(() => {
    if (ref.current) {
      const { height } = ref.current.getBoundingClientRect();
      setHeight(height);
    }
  }, [setHeight]);
  return (
    <a.div className="Nav-subscribe" ref={ref} style={props}>
      <Subscribe
        horizontal
        successCallback={() => {
          setTimeout(() => {
            setSubscribeActive(false);
          }, 4000);
        }}
      />
    </a.div>
  );
};

const NavSubscribeToggle = ({ active, setActive }) => {
  const { data } = useGlobals();

  return (
    <button
      className={`Nav-subscribe-toggle ${
        active ? "Nav-subscribe-toggle--active" : ""
      }`}
      onClick={() => setActive(!active)}
    >
        {data ? data.links.ctaText : ''}
    </button>
  );
};

export const NavItem = ({
  typeHandle,
  entry,
  isActive,
  hoverHandlers,
  setHeight,
}) => {
  const { data, loading, error } = useGlobals();
  switch (typeHandle) {
    case "entry":
      return (
        <>
          <RouterLink to={`/${entry[0].uri}`} {...hoverHandlers}>
            {entry[0].title}
          </RouterLink>
          {entry[0].children && (
            <Subnav
              entries={entry[0].children}
              setHeight={setHeight}
              isActive={isActive}
            />
          )}
        </>
      );

    case "globalDonate":
      if (loading || error) {
        return null;
      }
      return <Link url={data.links.donateUrl}>{data.links.donateText}</Link>;

    default:
      return null;
  }
};

const Subnav = ({ entries, setHeight, isActive }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && isActive) {
      const { y, height } = ref.current.getBoundingClientRect();
      setHeight(height - y);
    }
  }, [isActive, setHeight]);

  const [style, set] = useSpring(() => ({
    opacity: 0,
  }));

  useEffect(() => {
    if (isActive) {
      set({
        opacity: 1,
        delay: 200,
      });
    } else {
      const timer = setTimeout(() => {
        if (!isActive) {
          set({
            opacity: 0,
          });
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [set, isActive]);

  return (
    <a.ul
      className="Nav-links-item-subnav"
      ref={ref}
      style={{
        ...style,
        pointerEvents: isActive ? "" : "none",
      }}
    >
      {entries.map(({ id, uri, title }) => {
        return (
          <li key={id} className="Nav-links-item-subnav-item">
            <RouterLink key={id} to={`/${uri}`}>
              {title}
            </RouterLink>
          </li>
        );
      })}
    </a.ul>
  );
};
