import React, {useState} from "react";
import "./quoteContentBlock.scss";
import Image from "../image/image";
import waveImage from "./wave.svg";
import SVG from "react-inlinesvg";

const quoteContentBlock = (block) => {

  return (
    <div className="quoteContentBlock">
      <div className="quoteContentBlock-wrapped">
        <div className={"quoteContentBlockText1"}>
          <div className={"quoteContentBlockTextBold"}>
            <div className={"quoteContentBlockTextQuote"}>
              “
            </div>
            <div>
              {block.plainText}
            </div>
          </div>
          <div className={"quoteContentBlockTextMain"}>{block.quote}</div>
          <div className={"quoteContentBlockTextSign"}>

            <div className={"quoteContentBlockTextSignWave"}>
              <SVG className="lazyload" src={waveImage}/>
            </div>
            <div>
              {block.signature}
            </div>

          </div>
        </div>
        <div className={"quoteContentBlockPhoto"}>
          <Image key={block.image.id} image={block.image} />
        </div>
      </div>
    </div>
  );
};

export default quoteContentBlock
