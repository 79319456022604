import React from "react";
import Link from "components/link";
import query from "./query";
import { useQuery, useApolloClient } from "@apollo/react-hooks";
import "./alert-bar.scss";
import { AlertContext } from "./useAlertContext";
import { useContext } from "react";
import useMeasure from "react-use-measure";
import {useLocation} from "react-router-dom";

const AlertBar = () => {
  const client = useApolloClient();
  const location = useLocation();
  let uri = location.pathname.replace("/", "");

  if (uri.slice(-1) === '/') {
      uri = uri.substring(0, uri.length - 1);
  }

  let params = {
      client: client,
      notifyOnNetworkStatusChange: true
  };

  if (uri === "fr-ca" || uri === "hi-in") {
      params.context = {
          headers: { "X-GWC-Lang-Header": uri }
      }
  }

  const { loading, error, data } = useQuery(query, params);
  const { isShowing } = useContext(AlertContext);

  const [ref, bounds] = useMeasure();

  if (loading || error || !data.alertBar.alert[0] || !isShowing) {
    return null;
  }

  const { heading, shortAlertMessage, linkTo } = data.alertBar.alert[0];

  return (
    <div className="AlertBar" ref={ref}>
      <Link {...linkTo}>
        {bounds && bounds.width < 400 ? shortAlertMessage : heading}
      </Link>
    </div>
  );
};

export default AlertBar;
