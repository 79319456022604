export default {
  white: "#ffffff",

  black1: "#ededed",
  black2: "#cecece",
  black3: "#7a7a78",
  black4: "#1a1918",

  teal1: "#d7f9f4",
  teal2: "#43d6b9",
  teal3: "#0d9c90",
  teal4: "#0c6b68",

  blue1: "#e5f8ff",
  blue2: "#0169e1",
  blue3: "#0d38d3",
  blue4: "#003046",

  red1: "#feefee",
  red2: "#f37c6f",
  red3: "#ea3850",
  red4: "#a31746",

  orange1: "#fff3e8",
  orange2: "#f9b88c",
  orange3: "#fa7815",

  yellow1: "#fff9e2",
  yellow2: "#fce688",
  yellow3: "#fdd946"
};
