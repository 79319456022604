import { gql } from "apollo-boost";
import { sortAlphaProp} from "helpers";
import { useLocation } from "react-router-dom";
import { useQuery, useApolloClient } from "@apollo/react-hooks";

const query = gql`
  query {
    fundingLevels: entries(section: "fundingLevel") {
      ... on fundingLevel_fundingLevel_Entry {
        id
        title
      }
    }
    partners: entries(section: "partners") {
      ... on partners_partners_Entry {
        id
        title
        fundingLevel {
          id
        }
        partnerTypes {
          ... on partnerTypes_Category {
            id
            title
            color
          }
        }
      }
    }
    partnerTypes: categories(group: "partnerTypes") {
      ... on partnerTypes_Category {
        id
        title
        color
      }
    }
  }
`;

const usePartnerList = () => {
  const client = useApolloClient();
  const location = useLocation();
  let uri = location.pathname.replace("/", "");

  if (uri.slice(-1) === '/') {
      uri = uri.substring(0, uri.length - 1);
  }

  let params = {
      client: client,
      notifyOnNetworkStatusChange: true
  };

  if (uri === "fr-ca" || uri === "hi-in") {
      params.context = {
          headers: { "X-GWC-Lang-Header": uri }
      }
  }

  const { loading, error, data } = useQuery(query, params);

  return {
    loading,
    error,
    data: data ? selectPartnerList(data) : data,
  };
};

export default usePartnerList;

const selectPartnerList = ({ fundingLevels, partners, partnerTypes }) => {
  const partnersByFundingLevel = fundingLevels.map(({ id, title }) => {
    const partnersOfType = partners.filter((d) => d.fundingLevel[0].id === id);

    return {
      id,
      title,
      partners: sortAlphaProp(partnersOfType,"title"),
    };
  });

  return {
    partnersByFundingLevel,
    partnerTypes,
  };
};
