import React from "react"
import TextWave from "components/text-wave";
import ButtonLink from "components/button-link";

import "./simple-text-page.scss"

const SimpleTextPage = ({entry}) => {
  const {title,richText,links} = entry;
  return <section className="SimpleTextPage">
    <h1 className="SimpleTextPage-heading"><TextWave text={title} /></h1>
    <div
      className="SimpleTextPage-body"
      dangerouslySetInnerHTML={{ __html: richText }} />
    <div className="SimpleTextPage-links">
      {links.map(({ id, linkTo }) => {
        return <ButtonLink key={id} linkTo={linkTo} />
      })}
    </div>
  </section>
}


export default SimpleTextPage
