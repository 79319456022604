import React from "react";

const Input = ({ label, ...rest }) => {
  return (
    <label className="Input">
      <input placeholder={label} {...rest} />
    </label>
  );
};

export default Input;
