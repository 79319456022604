import React, { useContext } from "react";
import { bullet } from "components/themed-waves";
import ThemeContext from "blocks/theme/theme-context";
import colors from "colors";
import "./program-attribute-list.scss";

const ProgramAttributeList = ({ items, className = "" }) => {
  const theme = useContext(ThemeContext);
  return (
    <ul
      className={`ProgramAttributeList ${className}`}
      style={{
        listStyleImage: `url(data:image/svg+xml;utf8,${encodeURIComponent(
          bullet(theme.accent || colors.teal2)
        )})`,
      }}
    >
      {items.map(({ id, heading, more }) => {
        return (
          <li key={id} className="ProgramAttributeList-item">
            <div className="ProgramAttributeList-item-heading">{heading}</div>
            <div className="ProgramAttributeList-item-more">{more}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default ProgramAttributeList;
