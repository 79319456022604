import React, { useContext } from "react";
import ThemeContext from "blocks/theme/theme-context";

const Bullet = () => {
  const theme = useContext(ThemeContext);

  const stroke = theme.accent || "#43D6B9";
  return (
    <svg width="36" height="7" viewBox="0 0 36 7">
      <path
        d="M0 1c5.883 0 5.883 5 11.767 5 5.883 0 5.883-5 11.767-5 5.883 0 5.883 5 11.765 5"
        fill="none"
        stroke={stroke}
        strokeWidth="2"
      />
    </svg>
  );
};

export default Bullet;
