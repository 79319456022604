import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { image } from "querypieces";

const query = gql`
  {
    entries(section: "news", limit: 3) {
      ... on news_news_Entry {
        id
        uri
        title
        ${image}
      }
    }
  }
`;

const useMoreNews = (props = {}) => {
  const { excludeIds = [] } = props;
  return useQuery(query, {
    variables: {
      id: excludeIds.map((d) => `not ${d}`),
    },
  });
};

export default useMoreNews;
