import React, { useContext } from "react";
import ProgramAttributeList from "components/program-attribute-list";
import ButtonLink from "components/button-link";
import ThemeContext from "blocks/theme/theme-context";
import "./club-application.scss";

const ClubApplication = ({
  heading,
  statement,
  programAttributeList,
  linkTo,
}) => {
  const { foreground, background } = useContext(ThemeContext);
  return (
    <div
      className="ClubApplication"
      style={{ backgroundColor: background, color: foreground }}
    >
      <div className="ClubApplication-inner">
        <div className="ClubApplication-heading">{heading}</div>
        <div className="ClubApplication-text">{statement}</div>
        <div className="ClubApplication-requirements">
          <ProgramAttributeList items={programAttributeList} />
        </div>
        <ButtonLink className="ClubApplication-link" linkTo={linkTo} />
      </div>
    </div>
  );
};

export default ClubApplication;
