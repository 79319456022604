import { idtype, programAttributeList, linkTo } from "querypieces";

export default `
  ...on pageBuilder_programDetail_BlockType {
    ${idtype}
    heading
    plainText
    ${programAttributeList}
    ${linkTo}
  }
`;
