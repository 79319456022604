import React, { useContext } from "react";
import "./ways-to-fundraise.scss";
import IconHeadingSet from "components/icon-heading-set/icon-heading-set";
import Link from "components/link";
import ThemeContext from "blocks/theme/theme-context";
import WaveUnderline from "components/wave-underline";

const WaysToFundraise = ({
  heading,
  caption,
  iconHeadingSet,
  subheading,
  plainText,
  links,
}) => {
  const { background, foreground } = useContext(ThemeContext);
  return (
    <div
      className="WaysToFundraise"
      style={{ backgroundColor: background, color: foreground }}
    >
      <div className="WaysToFundraise-inner">
        <div className="WaysToFundraise-heading">
          <WaveUnderline>{heading}</WaveUnderline>
        </div>
        {caption && (
          <div className="WaysToFundraise-text">{caption}</div>
        )}
        <div className="WaysToFundraise-iconitems">
          <IconHeadingSet iconHeadingSet={iconHeadingSet} />
        </div>
        <div className="WaysToFundraise-subheading">{subheading}</div>
        <div className="WaysToFundraise-text">{plainText}</div>
        <ul className="WaysToFundraise-links">
          {links.map(({ id, linkTo }) => (
            <li key={id}>
              <Link {...linkTo} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WaysToFundraise;
