import React from "react";
import { themes } from "blocks/theme";
import { Waypoint } from "react-waypoint";
import { useSpring, a } from "react-spring";
import "./statistic.scss";

const Statistic = ({ backgroundColor, heading, plainText, note }) => {
  const leave = {
    opacity: 0,
    transform: `translateY(15%)`,
  };

  let bg = themes[backgroundColor]
    ? themes[backgroundColor].background
    : "#ffffff";

  const [first, setFirst] = useSpring(() => leave);
  const [second, setSecond] = useSpring(() => leave);

  const onEnterViewport = () => {
    setFirst({
      opacity: 1,
    });
    setSecond({
      config: { duration: 200 },
      opacity: 1,
      transform: `translateY(0)`,
      delay: 400,
    });
  };

  return (
    <Waypoint onEnter={onEnterViewport} topOffset={"-25%"} bottomOffset={"25%"}>
      <section className="Statistic" style={{ backgroundColor: bg }}>
        <div className="Statistic-wrapper">
          {heading && (
            <a.h3 style={first} className="Statistic-heading">
              {heading}
            </a.h3>
          )}
          {plainText && (
            <a.h2 style={second} className="Statistic-copy">
              {plainText}
            </a.h2>
          )}
          {note && (
            <a.p style={second} className="Statistic-note">
              {note}
            </a.p>
          )}
        </div>
      </section>
    </Waypoint>
  );
};

export default Statistic;
