import { idtype } from "querypieces";

export default `
  ...on pageBuilder_statistic_BlockType {
    ${idtype}
    backgroundColor
    heading
    plainText
    note
  }
`
