import {idtype, linkTo} from "querypieces";

export const privacyCenterAnchorLinks = `
    ${idtype}
`;

export const privacyCenterAnchorLink = `
    ${idtype}
    heading
    anchorId
`;