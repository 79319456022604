import { idtype, imageAsset } from "querypieces";

export default `
    ${idtype}
                image {
         ${imageAsset}
                }
                heading
                secondaryrichtext
                richText
                anchorId  
`;