import React from "react";
import ArrowLink from "components/arrow-link";
import ButtonLink from "components/button-link";
import "./arrow-link.scss";

const ArrowLinkBlock = ({ linkTo, linkType, position }) => {
  return (
    <div className={`ArrowLinkBlock`}>
      <div className={`position--${position}`}>
        {linkType === "button" ? (
          <ButtonLink linkTo={linkTo} />
        ) : (
          <ArrowLink linkTo={linkTo} />
        )}
      </div>
    </div>
  );
};

export default ArrowLinkBlock;
