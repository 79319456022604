import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { image } from "querypieces";
import { useState } from "react";

const query = gql`
  query ($offset: Int, $limit: Int) {
    items: entries(section: "news", limit: $limit, offset: $offset) {
      ... on news_news_Entry {
        id
        uri
        title
        ${image}
      }
    }
  }
`;

const useNewsLanding = () => {
  const LIMIT = 12;
  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: { limit: LIMIT },
    notifyOnNetworkStatusChange: true,
  });
  const [done, setDone] = useState(false);
  const loadMore = () => {
    fetchMore({
      variables: {
        offset: data.items.length,
        limit: LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          setDone(true);
          return prev;
        }
        //todo: refactor to use entry count when Craft 3.5 comes out
        if (fetchMoreResult.items.length < LIMIT) {
          setDone(true);
        }
        return {
          ...prev,
          items: [...prev.items, ...fetchMoreResult.items],
        };
      },
    });
  };

  return {
    loading,
    error,
    data,
    loadMore,
    done,
  };
};

export default useNewsLanding;
