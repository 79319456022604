import { idtype, imageAsset } from "querypieces";

export default `
    ${idtype}
                image {
         ${imageAsset}
                }
                quote
                plainText
                signature      
`;