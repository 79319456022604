import React from "react";
import "./program-questions.scss";

const ProgramQuestions = ({ items }) => {
  return (
    <ul className="ProgramQuestions">
      {items.map(item => (
        <ProgramQuestion key={item.id} {...item} />
      ))}
    </ul>
  );
};
const ProgramQuestion = ({ heading, text }) => {
  return (
    <li className="ProgramQuestions-item">
      <div className="ProgramQuestions-item-heading">{heading}</div>
      <div className="ProgramQuestions-item-text">{text}</div>
    </li>
  );
};

export default ProgramQuestions;
