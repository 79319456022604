import React, { useState, useRef } from "react";
import YouTube from "react-youtube";
import "./video-embed.scss";
import play_bg from "./play_bg.svg";
import play_head from "./play_icon.svg";
import ReactGA from "react-ga";
import SVG from "react-inlinesvg";
const aspectRatio = 56.25;

const VideoEmbed = ({ youtubeLink }) => {
  const videoId = getYouTubeId(youtubeLink);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const hidePlayButton = () => {
    setShowPlayButton(false);
  };
  const firstPlay = useRef(true);
  return (
    <div className="VideoEmbed">
      <div
        className="VideoEmbed-wrapper"
        style={{ paddingBottom: `${aspectRatio}%` }}
      >
        <YouTube
          videoId={videoId}
          onPlay={() => {
            if (firstPlay.current) {
              ReactGA.event({
                category: "Video",
                action: "play",
                label: youtubeLink,
              });
              firstPlay.current = false;
            }
          }}
          onStateChange={() => showPlayButton && hidePlayButton()}
        />
        {showPlayButton && (
          <div className="VideoEmbed-playbutton">
            <SVG src={play_bg} alt="" />
            <SVG src={play_head} class="triangle" alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoEmbed;

function getYouTubeId(url) {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
}
