import { idtype } from "querypieces";

export default blocks => `
  ...on pageBuilder_solidBackground_BlockType {
    ${idtype}
    colorCombo{
      label
      class
    }
    children {
      ${blocks}
    }
  }
`;
