import React from "react";
import Blocks from "blocks";
import "./gradient-worm.scss";
import defaultWorm from "images/get_involved_worm.svg";

import SVG from "react-inlinesvg";
import colors from "colors";

const themes = {
  blue: colors.blue1,
  yellow: colors.yellow1,
  teal: colors.teal1,
};

const GradientWorm = ({ children, worm, wormColor, entry }) => {
  console.log(worm)
  return (
    <div className={`GradientWorm GradientWorm--${wormColor}`}>
      <SVG
        className="GradientWorm-worm"
        src={worm[0] ? worm[0].url : defaultWorm}
        alt=""
        preProcessor={code => code.replace(/stroke=".*?"/g, `stroke="${themes[wormColor]}"`)}
      />
      {/* <img
        className="GradientWorm-worm lazyload"
        data-src={worm[0] ? worm[0].url : defaultWorm}
        alt=""
      /> */}
      <Blocks blocks={children} entry={entry} />
    </div>
  );
};

export default GradientWorm;
