import React from "react";
import ArrowLink from "components/arrow-link";
import HeadingText from "components/heading-text";
import "./heading-text-link.scss";

const HeadingTextLink = ({ label, heading, plainText, linkTo, position }) => {
  return (
    <div className={`HeadingTextLink HeadingTextLink--${position}`}>
      {label ? <p className="HeadingTextLink-label">{label}</p> : <div className="HeadingTextLink-label" />}
      <HeadingText heading={heading} text={plainText} />
      {linkTo.url && (
        <ArrowLink className="HeadingTextLink-link" linkTo={linkTo} />
      )}
    </div>
  );
};

export default HeadingTextLink;
