import React from "react";
import AccordionItem from "components/accordionitem";
import "./browselocales.scss";
import ButtonLink from "components/button-link";

const BrowseLocales = ({ locales, selectLocale, switchToLocations }) => {
  if (!locales) {
    return null;
  }
  return (
    <div className="BrowseLocales">
      <div className="BrowseLocales-heading">Browse by country</div>
      {locales.map(({ countryName, regions, linkTo }) => {
        return (
          <AccordionItem key={countryName} heading={<div>{countryName}</div>}>
            <ul className="BrowseLocales-list">
              {regions.split(",").map((state) => (
                <button
                  className="BrowseLocales-list-item"
                  key={state}
                  onClick={() => {
                    switchToLocations();
                    selectLocale(`${state}, ${countryName}`);
                  }}
                >
                  {state}
                </button>
              ))}
            </ul>
            {linkTo && <ButtonLink linkTo={linkTo} />}
          </AccordionItem>
        );
      })}
    </div>
  );
};

export default BrowseLocales;
