import React, { useRef } from "react";
import { useSpring, useChain, a } from "react-spring";
import Image from "components/image";
import TextWave from "components/text-wave";
import "./image-wave-text.scss";

const ImageWaveText = ({ waveHeaderImages, plainText }) => {
  const main = waveHeaderImages[0].mainImage[0];
  const image2 = waveHeaderImages[0].image2[0];
  const image3 = waveHeaderImages[0].image3[0];

  const statementRef = useRef();
  const statementProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: statementRef,
  });

  const image1Ref = useRef();
  const image1Props = useSpring({
    from: { opacity: 0, transform: `translate(-10%,-10%)` },
    to: { opacity: 1, transform: `translate(0%,0%)` },
    ref: image1Ref,
  });

  const image2Ref = useRef();
  const image2Props = useSpring({
    from: { opacity: 0, transform: `translate(0%,10%)` },
    to: { opacity: 1, transform: `translate(0%,0%)` },
    ref: image2Ref,
  });

  const image3Ref = useRef();
  const image3Props = useSpring({
    from: { opacity: 0, transform: `translate(10%,10%)` },
    to: { opacity: 1, transform: `translate(0%,0%)` },
    ref: image3Ref,
  });

  useChain(
    [statementRef, image1Ref, image2Ref, image3Ref],
    [0.5, 0.75, 1, 1.25]
  );

  return (
    <div className="ImageWaveText space-for-nav">
      <a.div style={statementProps} className="ImageWaveText-text">
        <TextWave text={plainText} align="right" />
      </a.div>
      <a.div style={image1Props} className="ImageWaveText-image1">
        <Image {...main} />
      </a.div>
      <a.div style={image2Props} className="ImageWaveText-image2">
        <Image {...image2} />
      </a.div>
      <a.div style={image3Props} className="ImageWaveText-image3">
        <Image {...image3} />
      </a.div>
    </div>
  );
};

export default ImageWaveText;
