import React, { useState, useRef, useEffect } from "react";
import ArrowLink from "components/arrow-link";
import { Waypoint } from "react-waypoint";
import { useSpring, a } from "react-spring";
import Cookies from "universal-cookie";
import colors from "colors";
import donateIcon from "images/donate-icon.svg";
import SVG from "react-inlinesvg";
import "./location-modal.scss";

const transform = `256px`;

const LocationModal = () => {
  const cookies = new Cookies();
  const ref = useRef();
  const [collapse, setCollapse] = useState(cookies.get("geo-modal-closed"));
  const [style, set] = useSpring(() => ({
    opacity: 0,
    transform: `translate(${collapse ? transform : `0px`}, 100%)`,
  }));

  let [text, setText] = useState("");
  let [title, setTitle] = useState("");
  let [hidden, setHidden] = useState(true);
  let [link, setLink] = useState({ text: "", url: "" });

  useEffect(() => {
    let mounted = true;
    fetch("/geoinfo")
      .then((res) => res.json())
      .then((response) => {
        if (mounted) {
          if (
            response.code &&
            (response.code === "CA" ||
              response.code === "GB" ||
              response.code === "IN")
          ) {
            if (response.code === "CA") {
              setLink({ text: "Take me there", url: "/en-ca" });
            }
            if (response.code === "GB") {
              setLink({ text: "Take me there", url: "/en-uk" });
            }
            if (response.code === "IN") {
              setLink({ text: "Take me there", url: "/en-in" });
            }

            setTitle("It looks like you are in " + response.country);
            setText(
              "Check out our " +
                response.country +
                " Page to learn more about which programs Girls Who Code offers in " +
                response.country +
                "."
            );
            setHidden(false);
          }
        }
      });
    return () => (mounted = false);
  }, []);

  return (
    <Waypoint
      onEnter={() => {
        set({
          opacity: 0.9,
          transform: `translate(${collapse ? transform : `0px`}, 0%)`,
        });
      }}
      onLeave={() => {
        set({
          opacity: 0,
          transform: `translate(${collapse ? transform : `0px`}, 100%)`,
        });
      }}
    >
      <a.aside
        className={"LocationModal" + (hidden ? " hidden" : "")}
        style={style}
        ref={ref}
      >
        <button
          className={`LocationModal-toggle`}
          onClick={() => {
            setCollapse((curr) => !curr);
            set({
              transform: `translate(${!collapse ? transform : `0px`}, 0)`,
            });
            cookies.set("geo-modal-closed", true);
          }}
        >
          <span className={collapse ? "switch" : ""} />
        </button>
        <SVG
          src={donateIcon}
          alt="page alert icon"
          className="LocationModal-icon lazyload"
        />
        <div className="LocationModal-heading">{title}</div>
        <p className="LocationModal-body">{text}</p>
        <div className="LocationModal-link">
          <ArrowLink linkTo={link} activeColorOverride={colors.white} />
        </div>
      </a.aside>
    </Waypoint>
  );
};

export default LocationModal;
