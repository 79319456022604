import React, {useEffect, useRef, useState} from "react";
import "./anchorLinks.scss";
import Grid from "../../components/grid";
import {a} from "react-spring";
import arrowUp from "./caratdropdown.svg"
import SVG from "react-inlinesvg";
import useIsMobile from "../../hooks/useIsMobile";
import TopScrollButton from "../../components/topScrollButton/topScrollButton";

const anchorLinks = (block) => {
  return (
    <>
      <AnchorLinks block={block}/>
      <TopScrollButton/>
    </>
  )
}

const AnchorLinks = ({block, navHeight= 250, stickyTimes = 4000, precision = 200}) => {
  const isMobile = useIsMobile();

  const elementRef = useRef(null);

  const [isVisible, setIsVisible] = useState(true);
  const [selectedElement, setSelectedElement] = useState(false);
  const [height, setHeight] = useState(0);

  let hideTimer = null;

  const trackedItems = [];

  if (typeof document !== "undefined") {
    const divsWithName = document.querySelectorAll('div[name]');

    divsWithName.forEach(div => {
      const rectangle = div.getBoundingClientRect()
      trackedItems.push({
        'name': div.getAttribute('name'),
        'top': Math.floor(rectangle.top + window.scrollY - precision),
        'bottom': Math.ceil(rectangle.bottom +window.scrollY - precision),
      })
    });
  }

  useEffect(() => {
    if (elementRef.current) {
      setHeight(elementRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {

    if (isMobile) {
      setIsVisible(false);
      return;
    }

    const handleScroll = () => {
      if (window.scrollY > navHeight) {
        setIsVisible(true);

        clearTimeout(hideTimer);
        hideTimer = setTimeout(() => {
          setIsVisible(false);
        }, stickyTimes);
      }

      const nestItems = trackedItems.filter((item) => {
        return (item.top) <= window.scrollY  && (item.bottom) >=  window.scrollY;
      });

      if (nestItems?.length) {
        setSelectedElement(nestItems[0]);
      } else {
        setSelectedElement(null);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

  }, [isMobile]);

  const mobileClassName = isMobile ? "anchorLinksContentBlockMobile" : "anchorLinksContentBlockDesktop";
  const isStickyVisible = (!isMobile && isVisible) ? "anchorLinksContentBlockSticky" : "";

  return (
    <nav
      ref={elementRef}
      className={`anchorLinksContentBlock ${mobileClassName} ${isStickyVisible}`}>
      <div className="anchorLinksContentBlock-wrapper">
        <div className={"anchorLinksContentBlock-inner"}>
          {isMobile ?
            (<MobileAnchorLinks block={block}/>) :
            (<DesktopAnchorLinks block={block} selectedElement={selectedElement} scrollPrecision={height}/>)}
        </div>
      </div>
      <div style={{position: "absolute", top: "100%"}}/>
    </nav>
  )
}

const DesktopAnchorLinks = ({block, selectedElement = null, scrollPrecision = 0}) => {
  return <Grid itemClass="limitFive">
    {block.children.map((item) => {
      return <DesktopAnchorLink scrollPrecision={scrollPrecision} isSelected={(selectedElement?.name === item.anchorId)} key={item.id} item={item}/>
    })}
  </Grid>

}

const MobileAnchorLinks = ({block}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onClick = () => {
    setIsOpen(!isOpen);
  }

  return (<>
    <div className={"mobileAnchorLinksBlock"}>
      <a href={"#"} className="mobileAnchorLinksBlockLink" onClick={onClick}>
        <span>CLUBS DATA PRIVACY CENTER</span>
        <div className="mobileAnchorLinksBlockIsOpened">
          {isOpen ?
            (
              <SVG className="mobileAnchorLinksBlockIsOpenedImage" src={arrowUp} alt="Menu is opened"/>
            )
            : (
              <SVG className="mobileAnchorLinksBlockIsOpenedImage closed" src={arrowUp} alt={"Menu is closed"}/>
            )}
        </div>
      </a>
    </div>

    {isOpen && <MobileAnchorLinksPopup items={block.children}/>}

  </>);
}

const MobileAnchorLinksPopup = ({items}) => {
  return <div className="mobileAnchorLinksBlock-Popup">
    <div className="mobileAnchorLinksBlock-Popup-Content">
      {items.map((item) => {
        return <MobileAnchorLink key={item.id} item={item}/>
      })}
    </div>
  </div>
}

const DesktopAnchorLink = ({item, isSelected = false, scrollPrecision = 0}) => {
  const selectedClassName = isSelected ? "desktopAnchorLinkBlockLinkSelected" : "";

  const onclick = (event) => {
    event.preventDefault();

    const element = document.querySelectorAll(`div[name="${item.anchorId}"]`);
    if (element?.length) {
      const rectangle = element[0].getBoundingClientRect()
      window.scrollTo({
        top: rectangle.top + window.scrollY - scrollPrecision,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <a rel="" onClick={onclick} className={`desktopAnchorLinkBlockLink ${selectedClassName}`} href={`#${item.anchorId}`}>{item.heading}</a>
  )
}

const MobileAnchorLink = ({item}) => {
  return (
    <div className="mobileAnchorLinkBlock">
      <a rel="" className="mobileAnchorLinkBlockLink" href={`#${item.anchorId}`}>{item.heading}</a>
    </div>
  )
}

export default anchorLinks
