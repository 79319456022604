import React from "react";

const ProgramValuesItem = ({ heading, plainText }) => {
  return (
    <li className="ProgramValuesItem">
      <div className="ProgramValuesItem-inner">
        <div className="ProgramValuesItem-heading">{heading}</div>
        <div className="ProgramValuesItem-text">{plainText}</div>
      </div>
    </li>
  );
};

export default ProgramValuesItem;
