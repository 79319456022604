import React from "react";
import colors from "colors";

export default ({ fill = colors.blue2 }) => (
  <svg
    className="MapMarkerIcon"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="36"
    viewBox="0 0 24 36"
  >
    <path
      fill={colors.teal2}
      d="M12 36S0 19.16 0 12.343 5.373 0 12 0s12 5.526 12 12.343S12 36 12 36z"
    />
    <circle
      cx="12"
      cy="12.171"
      r="6"
      fill={fill}
      transform="matrix(-1 0 0 1 24 0)"
    />
  </svg>
);
