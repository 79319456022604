import React from "react";
import ArrowLink from "components/arrow-link";
import "./program-application-sip.scss";

const ProgramApplicationSip = ({ heading, plainText, image, linkTo }) => {
  return (
    <div className="ProgramApplicationSip">
      <div className="ProgramApplicationSip-heading">{heading}</div>
      <div className="ProgramApplicationSip-text">{plainText}</div>
      <div className="ProgramApplicationSip-partners">
        <img
          className={"lazyload"}
          src={image[0].optimized.placeholder}
          data-src={image[0].url}
          alt=""
        />
      </div>
      <ArrowLink className="ProgramApplicationSip-link" linkTo={linkTo} />
    </div>
  );
};

export default ProgramApplicationSip;
