import React, { useRef } from "react";
import { useSpring, useChain, a } from "react-spring";
import TextWave from "components/text-wave";
import nl2br from "react-nl2br";
import useTheme from "blocks/theme/useTheme";
import "./heading-statement-text.scss";

const HeadingStatementText = ({ heading, statement, richText, entry }) => {
  const theme = useTheme();

  const headingRef = useRef();
  const headingProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: headingRef,
  });

  const statementRef = useRef();
  const statementProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: statementRef,
  });

  const textRef = useRef();
  const textProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: textRef,
  });

  useChain([headingRef, statementRef, textRef], [0.5, 1, 1.5]);

  if (!statement && !richText) {
    return (
      <div>
        <div className="HeadingOnly">
          {entry.title && (
            <div
              className="HeadingStatementText-label"
              style={{ backgroundColor: theme.accent, color: theme.foreground }}
            >
              {entry.title}
            </div>
          )}
          <a.div style={headingProps} className="HeadingStatementText-heading">
            <TextWave text={heading} />
          </a.div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="HeadingStatementText">
        {entry.title && (
          <div
            className="HeadingStatementText-label"
            style={{ backgroundColor: theme.accent, color: theme.foreground }}
          >
            {entry.title}
          </div>
        )}
        <a.div style={headingProps} className="HeadingStatementText-heading">
          <TextWave text={heading} />
        </a.div>
        <a.div
          style={statementProps}
          className="HeadingStatementText-statement"
        >
          {nl2br(statement)}
        </a.div>
        <a.div
          style={textProps}
          className="HeadingStatementText-text rte"
          dangerouslySetInnerHTML={{ __html: richText }}
        />
      </div>
    </div>
  );
};

export default HeadingStatementText;
