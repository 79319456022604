import React from "react";
import "./impact-items.scss";

const ImpactItems = ({ impactItems }) => {
  return (
    <ul className="Impact-items">
      {impactItems.map(({ id, number, label, explainer }) => {
        return (
          <li key={id} className="Impact-items-item">
            <div className="Impact-items-item-number">{number}</div>
            <div className="Impact-items-item-label">{label}</div>
            <div className="Impact-items-item-explainer">{explainer}</div>
          </li>
        );
      })}
    </ul>
  );
};

export default ImpactItems;
