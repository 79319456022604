import { useState, useEffect } from "react";
import query from "./query";
import ReactGA from "react-ga";
import {useLocation} from "react-router-dom";
import { useQuery, useApolloClient } from "@apollo/react-hooks";

const states = {
  INITIAL: "INTIAL",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  LOADING: "LOADING",
};

const useSubscribe = ({ source, initialAgeGroup, successCallback }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [ageGroup, setAgeGroup] = useState(initialAgeGroup);

  const [response, setResponse] = useState(null);
  const [state, setState] = useState(states.INITIAL);

  useEffect(() => {
    setResponse(null);
  }, [email]);

  const client = useApolloClient();
  const location = useLocation();
  let uri = location.pathname.replace("/", "");

  if (uri.slice(-1) === '/') {
      uri = uri.substring(0, uri.length - 1);
  }

  let params = {
      client: client,
      notifyOnNetworkStatusChange: true
  };

  if (uri === "fr-ca" || uri === "hi-in") {
      params.context = {
          headers: { "X-GWC-Lang-Header": uri }
      }
  }

  const body = JSON.stringify({
    EmailAddress: email,
    IsStudent: ageGroup === "student",
    Source: source,
  });
  const submit = () => {
    setState(states.LOADING);
    fetch(`/addcontact`, {
      method: "POST",
      body,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setResponse(res);
        setState(states.SUCCESS);
        ReactGA.event({
          category: "Subscribe",
          action: "Signed Up",
          label: source ? source : window.location.pathname,
        });
        typeof successCallback === "function" && successCallback();
      })
      .catch((err) => {
        setState(states.ERROR);
        setResponse({
          error: true,
          message: err.message,
        });
      });
  };

  const { data: content, error } = useQuery(query, params);

  return {
    content,
    error,
    email,
    setEmail,
    firstName,
    ageGroup,
    setAgeGroup,
    setFirstName,
    lastName,
    setLastName,
    response,
    submit,
    state,
    states,
  };
};

export default useSubscribe;
