import React, { useRef } from "react";
import "./wave-underline.scss";
import ThemeContext from "blocks/theme/theme-context";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useSpring, a } from "react-spring";
import { Waypoint } from "react-waypoint";
import colors from "colors";

const WaveUnderline = ({ children, className = "", color }) => {
  const theme = useContext(ThemeContext);
  const ref = useRef();
  const [strokeLength, setStrokeLength] = useState(1);

  const onPath = (node) => {
    node && setStrokeLength(node.getTotalLength());
  };

  const [spanWidth, setSpanWidth] = useState(0);
  useEffect(() => {
    if (ref.current) {
      const onResize = () => {
        setSpanWidth(ref.current.getBoundingClientRect().width);
      };
      onResize();
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    }
  });

  const waves = Math.floor(spanWidth / 20);
  const width = waves;
  const freq = 1.5;
  const height = 0.4;
  const amp = height / 2;
  const strokeWidth = 0.1;

  const [props, set] = useSpring(() => ({
    config: {
      duration: 3000,
    },
    stroke: 1,
  }));

  const [active, setActive] = useState(false);

  set({
    stroke: active ? 0 : 1,
  });

  const onEnterViewport = () => {
    setActive(true);
  };
  const onExitViewport = () => {
    setActive(false);
  };

  return (
    <div className={className}>
      <div className="WaveUnderline">
        <span ref={ref}>{children}</span>
        <div className="WaveUnderline-wave" style={{ width: `${spanWidth}px` }}>
          <svg
            onClick={() => setActive(!active)}
            className="WaveUnderline-wave"
            viewBox={`${freq / 4} -${amp * 2} ${width} ${height * 2}`}
          >
            <Waypoint
              onEnter={onEnterViewport}
              onLeave={onExitViewport}
              topOffset={100}
            >
              <a.path
                ref={onPath}
                fill="none"
                stroke={color || theme.accent || colors.teal2}
                style={{ visibility: strokeLength > 1 ? "visible" : "hidden" }}
                strokeWidth={strokeWidth}
                strokeDasharray={`${strokeLength} ${strokeLength}`}
                strokeDashoffset={props.stroke.interpolate(
                  (x) => x * strokeLength
                )}
                d={`M0,0${new Array(waves)
                  .fill(null)
                  .map((d) =>
                    `q${freq / 4},-${amp * 2} ${freq / 2},-${amp}
                    q${freq / 4},${amp * 2} ${freq / 2},${amp}`.trim()
                  )
                  .join("")}`}
              />
            </Waypoint>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default WaveUnderline;
