import React, { useState } from "react";
import AnnualReport from "./annual-report";
import HeadingText from "components/heading-text";
import Grid from "components/grid";
import ArrowLink from "components/arrow-link";

const LIMIT = 3;

const AnnualReports = ({ id, heading, children }) => {
  const [offset, set] = useState(LIMIT);

  return (
    <div className="AnnualReports">
      <HeadingText heading={heading} text={null} />
      <Grid itemClass="third">
        {children.slice(0, offset).map((report) => (
          <AnnualReport key={report.id} {...report} />
        ))}
      </Grid>

      {children.length > offset ? (
        <div className="AnnualReports-LoadMore">
          <LoadMore set={set} />
        </div>
      ) : null}
    </div>
  );
};

const LoadMore = ({ set }) => {
  const linkTo = {
    element: { uri: "#" },
    text: "View Archive",
    onClick: (e) => {
      e.preventDefault();
      set((off) => off + LIMIT);
    },
  };

  return <ArrowLink linkTo={linkTo} />;
};

export default AnnualReports;
