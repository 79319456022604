import React from "react";
import "./radio.scss";

const Radio = ({ label, value, checked, onChange }) => {
  return (
    <label className="Radio">
      <input type="radio" value={value} checked={checked} onChange={onChange} />
      {label}
    </label>
  );
};

export default Radio;
