import React from "react";
import Input from "components/form/input";
import InputDisclaimer from "components/form/input-disclaimer";
import RadioSet from "components/form/radio-set";
import useTheme from "blocks/theme/useTheme";
import colors from "colors";
import check from "./check.svg";
import SVG from "react-inlinesvg";

const ageGroupOptions = (phrases) => [
  { label: phrases.adultText, value: "adult" },
  { label: phrases.studentText, value: "student" },
];

const SubscribeView = ({
  content,
  response,
  state,
  states,
  ageGroup,
  setAgeGroup,
  email,
  setEmail,
  submit,
  buttonText = "signUpNowText",
  className = "",
  horizontal,
}) => {
  const theme = useTheme();
  const phrases = content ? content.globalSet.subscribeForm[0] : null;
  const loading = state === states.LOADING;
  const success = response && response.success;
  return (
    <>
      {!success && phrases && (
        <form
          className={`Subscribe ${className} ${
            horizontal ? "Subscribe--horizontal" : ""
          }`}
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <RadioSet
            className="Subscribe-age"
            name="Age Group"
            options={ageGroupOptions(phrases)}
            legend={phrases.beforeSelectorText}
            value={ageGroup}
            setValue={setAgeGroup}
            required={true}
            color={theme.accent || colors.teal2}
          />
          <div className="Subscribe-input">
            <Input
              type="email"
              label={phrases.fieldText}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ borderBottomColor: theme.accent || colors.teal2 }}
            />
            <InputDisclaimer>{phrases.disclaimerText}</InputDisclaimer>
          </div>
          <button
            className="Subscribe-submit"
            type="submit"
            disabled={loading}
            style={{ borderColor: theme.accent }}
          >
            {loading ? phrases.loadingText : (phrases[buttonText] || buttonText)}
          </button>
        </form>
      )}
      {response && response.success && <Success text={phrases.successText || 'Success'} />}
      {response && response.error && response.message}
    </>
  );
};

export default SubscribeView;

const Success = ({ text }) => {
  return (
    <div className="SubscribeSuccess">
      <SVG src={check} alt="" /> {text}
    </div>
  );
};
