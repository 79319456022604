import React from "react";
import WaveUnderline from "components/wave-underline";
import "./news-stream-header.scss";

const NewsStreamHeader = ({ label, heading }) => {
  return (
    <div className="NewsStreamHeader">
      <div className="NewsStreamHeader-label">{label}</div>
      <div className="NewsStreamHeader-heading">
        <WaveUnderline>{heading}</WaveUnderline>
      </div>
    </div>
  );
};

export default NewsStreamHeader;
