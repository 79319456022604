import { idtype, linkTo, link } from "querypieces";

export default `
  ...on pageBuilder_servicesApply_BlockType {
    ${idtype}
    heading
    plainText
    iconThings {
      ...on iconThings_item_BlockType {
        id
        icon {
          url
          ...on images_Asset {
            optimized: optimizedImage {
              placeholder: placeholderBox(color: "transparent")
            }
          }
        }
        heading
        text
      }
    }
    ${linkTo}
    secondaryLink {
      ${link}
    }
  }
`;
