import { useState } from "react";

const useArrowWave = () => {
  const [active, setActive] = useState(false);

  const onMouseEnter = () => {
    setActive(true);
  };
  const onMouseLeave = () => {
    setActive(false);
  };
  const handlers = {
    onMouseEnter,
    onMouseLeave
  };
  return {
    active,
    handlers
  };
};

export default useArrowWave;
