import {
  idtype,
  linkTo,
  buttonLink,
  secondaryLink,
  programAttributeList,
  svgIcon,
} from "querypieces";
import programValues from "components/program-values/query";
import programDifferences from "components/program-differences/query";

export default `
  ...on pageBuilder_programHeader_BlockType {
    ${idtype}
    programHeaderTheme
    label
    heading
    statement
    image1: image54 {
      ...on images_Asset {
        optimized: optimized54 {
          placeholder: placeholderImage
          srcset
        }
      }
    }
    image2: image169 {
      ...on images_Asset {
        optimized: optimized169 {
          placeholder: placeholderImage
          srcset
        }
      }
    }
    ${svgIcon}
    subheading
    subtext
    ${programAttributeList}
    ${buttonLink}
    ${linkTo}
    ${programValues}
    ${programDifferences}
    ${secondaryLink}
  }
`;
