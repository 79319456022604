import React from "react";
import Link from "components/link";
import Image from "components/image";
import useGlobals from "hooks/useGlobals";
import "./donatecta.scss";
import useTheme from "blocks/theme/useTheme";

const DonateCta = ({ heading, richText, linkTo, image }) => {
  const hasImage = image.length > 0;

  const { loading, data } = useGlobals();

  const theme = useTheme();

  linkTo = linkTo.url
    ? linkTo
    : loading
    ? null
    : {
        url: data.links.donateUrl,
        text: "Donate Now",
      };
  return (
    <div style={{ backgroundColor: theme.background, color: theme.foreground }}>
      <div className={hasImage ? "DonateCtaWithImage" : "DonateCta"}>
        {hasImage && <Image className="DonateCta-image" {...image[0]} />}
        <div className="DonateCta-heading">{heading}</div>
        <div
          className="DonateCta-text"
          dangerouslySetInnerHTML={{ __html: richText }}
        />
        <Link className="DonateCta-link" {...linkTo} />
      </div>
    </div>
  );
};

export default DonateCta;
