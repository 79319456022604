import React from "react";
import SubscribeView from "./subscribe-view";
import useSubscribe from "./useSubscribe";
import "./subscribe.scss";

const Subscribe = ({
  source,
  initialAgeGroup,
  successCallback,
  horizontal,
  className,
}) => {
  const props = useSubscribe({
    source,
    initialAgeGroup,
    successCallback,
  });

  source = source || "No source defined";
  return (
    <SubscribeView {...props} horizontal={horizontal} className={className} />
  );
};

export default Subscribe;
