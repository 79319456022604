import { idtype, linkTo, svgIcon } from "querypieces";

export default `
...on pageBuilder_ctaItems_BlockType {
    ${idtype}
    children {
      ...on pageBuilder_ctaItem_BlockType {
        ${idtype}
        ${svgIcon}
        heading
        plainText
        ${linkTo}
      }
    }
  }
`;
