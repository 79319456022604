import { linkTo } from "querypieces";
import { gql } from "apollo-boost";

export default gql`
  {
    alertBar: globalSet(handle: "alertBar") {
      ... on alertBar_GlobalSet {
        name
        alert {
          ... on alerts_alert_Entry {
            heading
            shortAlertMessage
            ${linkTo}
          }
        }
      }
    }
  }
`;
