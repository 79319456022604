import { idtype, linkTo } from "querypieces";

export default `
  ...on pageBuilder_waysToGetInvolved_BlockType {
    ${idtype}
    waysToGetInvolved {
      ...on waysToGetInvolved_item_BlockType {
        id
        heading
        overview
        commitmentLevel
        commitmentMore
        ${linkTo}
      }
    }
  }
`;
