import React, { useEffect, useRef } from "react";
import BrowseLocales from "./browselocales";

import "react-tabs/style/react-tabs.scss";
import SidebarList from "./sidebarlist";
import { ClubMetaDetails } from "./sidebarlist";
import ArrowBackButton from "components/arrow-back-button";
import { sortDistancesArray } from "helpers";

const extractLocations = (items) => {
  return items.reduce((result, item) => {
    return [...result, ...item.points];
  }, []);
};
const Sidebar = ({
  allMarkers,
  selectLocale,
  unZoom,
  markers,
  zoomMarker,
  currentTab,
  switchToLocations,
  setSearchQuery,
  switchToLocales,
  mapAddress,
  activeMarker,
  mapProps,
  setActiveMarker,
  setHoveredMarker,
  locales,
  noContactMessage,
  noLocationsInView,
}) => {
  const ref = useRef();
  let locations = sortDistancesArray(extractLocations(markers), mapProps);

  useEffect(() => {
    if (ref.current && currentTab === 1) {
      ref.current.scrollTop = 0;

      let mapAddressLower = mapAddress ? mapAddress.toLowerCase() : '';

      if (markers.length === 0) {
        if (mapAddressLower.includes("québec") || mapAddressLower.includes("quebec")) {
          unZoom(6);
        } else {
          unZoom();
        }
      }

      setSearchQuery(mapAddress ? mapAddress.trim() : '');
    }
  }, [markers, currentTab, mapAddress]);

  return (
    <div className="ClubMap-sidebar" ref={ref}>
      {currentTab === 0 ? (
        <BrowseLocales
          locales={locales}
          selectLocale={selectLocale}
          switchToLocations={switchToLocations}
        />
      ) : activeMarker ? (
        <MarkerDetail
          {...allMarkers.find((d) => d.id === activeMarker)}
          clearActiveMarker={() => setActiveMarker(null)}
          noContactMessage={noContactMessage}
        />
      ) : (
        <SidebarList
          switchToLocales={switchToLocales}
          items={locations}
          mapAddress={mapAddress}
          zoomMarker={zoomMarker}
          mapProps={mapProps}
          activeMarker={activeMarker}
          setActiveMarker={setActiveMarker}
          setHoveredMarker={setHoveredMarker}
          noLocationsInView={noLocationsInView}
        />
      )}
    </div>
  );
};

export default Sidebar;

export const MarkerDetail = ({ clearActiveMarker, ...props }) => {
  return (
    <div className="Sidebar-markerdetail">
      <ArrowBackButton onClick={() => clearActiveMarker()}>
        Back
      </ArrowBackButton>
      <ClubMetaDetails {...props} />
    </div>
  );
};
