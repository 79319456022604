import React from "react";
import Bullet from "components/bullet";
import "./attribution.scss";

const Attribution = ({ children }) => {
  return (
    <div className="Attribution">
      <Bullet />
      {children}
    </div>
  );
};

export default Attribution;
