import { idtype } from "querypieces";
import profileCta from "blocks/profile-cta/query";
import arrowLink from "blocks/arrow-link/query";
import subscribeDownload from "blocks/subscribe-download/query";
import campaignPullquote from "blocks/campaign-pullquote/query";

export default `
  ...on pageBuilder_campaignHeader_BlockType {
    ${idtype}
    heading
    plainText
    video {
      ...on images_Asset {
        url
      }
    }
    image {
      ...on images_Asset {
        widescreen {
          placeholder: placeholderImage
          srcset
          src
        }
        square {
          placeholder: placeholderImage
          srcset
          src
        }
      }
    }
    year
    children {
      ${profileCta}
      ${arrowLink}
      ${subscribeDownload}
      ${campaignPullquote}
    }
  }
`;
