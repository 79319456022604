import { idtype, linkTo } from "querypieces";

export default `
  ...on pageBuilder_headingTextLink_BlockType {
    ${idtype}
    heading
    label
    plainText
    ${linkTo}
    position
  }
`;
