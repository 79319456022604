import { linkTo } from "querypieces";

export default `
  iconHeadingSet {
    ...on iconHeadingSet_item_BlockType {
      id
      icon {
        title
        url
      }
      heading,
      ${linkTo}
    }
  }
`;
