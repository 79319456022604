import React from "react";
import ProfileCtaWithImage from "./profile-cta-with-image";
import ProfileCtaNoImage from "./profile-cta-no-image";

const ProfileCta = props => {
  const hasImage = props.image.length > 0;

  return hasImage ? (
    <ProfileCtaWithImage {...props} />
  ) : (
    <ProfileCtaNoImage {...props} />
  );
};

export default ProfileCta;
